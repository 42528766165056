var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',[_c('div',{staticClass:"popup-container"},[_c('button',{staticClass:"close-button",on:{"click":_vm.closePopup}},[_vm._v("×")]),_c('el-form',{ref:"form",attrs:{"model":_vm.formData}},[_c('el-form-item',{attrs:{"label":"姓名","prop":"name","rules":{ required: true, message: '请输入姓名', trigger: 'blur' }}},[_c('el-input',{model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('el-form-item',{attrs:{"label":"地区","prop":"edu_areaName","rules":{ required: true, message: '请选择地区', trigger: 'blur' }}},[_c('el-cascader',{attrs:{"options":_vm.eduarea},on:{"change":_vm.handleTeacherSelectChangeeduarea}})],1),_c('el-form-item',{attrs:{"label":"意向项目","prop":"project","rules":{
          required: true,
          message: '请输入意向项目',
          trigger: ['blur', 'change'],
        }}},[_c('el-input',{attrs:{"placeholder":"如：自考本科"},model:{value:(_vm.formData.project),callback:function ($$v) {_vm.$set(_vm.formData, "project", $$v)},expression:"formData.project"}})],1),_c('el-form-item',{attrs:{"label":"性别","prop":"sex","rules":{
          required: true,
          message: '请输入性别',
          trigger: ['blur', 'change'],
        }}},[_c('el-input',{attrs:{"placeholder":"如：男"},model:{value:(_vm.formData.sex),callback:function ($$v) {_vm.$set(_vm.formData, "sex", $$v)},expression:"formData.sex"}})],1),_c('el-form-item',{attrs:{"label":"年龄","prop":"age","rules":{
          required: true,
          message: '请输入您的年龄',
          trigger: ['blur', 'change'],
        }}},[_c('el-input',{attrs:{"placeholder":"如：18"},model:{value:(_vm.formData.age),callback:function ($$v) {_vm.$set(_vm.formData, "age", $$v)},expression:"formData.age"}})],1),_c('el-form-item',{attrs:{"label":"民族","prop":"nation","rules":{
          required: true,
          message: '请输入您的民族',
          trigger: ['blur', 'change'],
        }}},[_c('el-input',{attrs:{"placeholder":"如：汉"},model:{value:(_vm.formData.nation),callback:function ($$v) {_vm.$set(_vm.formData, "nation", $$v)},expression:"formData.nation"}})],1),_c('el-form-item',{attrs:{"label":"手机号","prop":"phone","rules":{
          required: true,
          pattern: /^1[3-9]\d{9}$/,
          message: '请输入正确的手机号码',
          trigger: ['blur', 'change'],
        }}},[_c('el-input',{attrs:{"placeholder":"如：13812345678"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-button',{attrs:{"disabled":!_vm.isPhoneValid || _vm.isCounting},on:{"click":_vm.sendVerificationCode}},[_vm._v(" "+_vm._s(_vm.countdownText)+" ")])]},proxy:true}]),model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('el-form-item',{attrs:{"label":"验证码","prop":"code","rules":{
          required: true,
          validator: _vm.validateCode,
          trigger: ['blur'],
        }}},[_c('el-input',{attrs:{"placeholder":"请输入6位验证码","maxlength":"6"},on:{"blur":_vm.handleCodeBlur},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})],1),_c('el-form-item',{attrs:{"label":"可用的优惠卷"}},[(_vm.flage)?_c('div',{staticClass:"coupon-list"},[_vm._l((_vm.couponse.data.filter(
              (coupon) => coupon.preferential.specifictid === this.formData.edu_schoolID
            )),function(couponse,index){return _c('div',{key:index,staticClass:"coupon-card"},[_c('div',{staticClass:"logo-container"},[_c('img',{staticClass:"logo-image",attrs:{"src":require("@/assets/images/logo1.jpg"),"alt":"Your Logo"}})]),_c('div',{staticClass:"coupon-header"},[_c('h3',[_vm._v(_vm._s(couponse.preferential.name))]),_c('span',{staticClass:"coupon-expire"},[_vm._v("有效期至 "+_vm._s(couponse.preferential.outtime))])]),_c('div',{staticClass:"coupon-body"},[_c('p',[_vm._v(_vm._s(couponse.preferential.threshold))])]),_c('div',{staticClass:"coupon-footer"},[_c('span',{staticClass:"coupon-discount"},[_vm._v(_vm._s(couponse.preferential.reduction)+"% OFF")]),_c('el-badge',{staticClass:"item",attrs:{"value":`${couponse.countCa}`,"max":99}},[(!_vm.formData.preferentials.split(',').includes(couponse.preferentialid.toString()))?_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.subsss(couponse)}}},[_vm._v(" 使用该优惠卷 ")]):_vm._e(),(_vm.formData.preferentials.split(',').includes(couponse.preferentialid.toString()))?_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.exitOdEr(couponse)}}},[_vm._v(" 取消使用 ")]):_vm._e()])],1)])}),_c('br'),_c('el-pagination',{attrs:{"current-page":_vm.couponse.current_page,"page-sizes":[2, 5, 10, 20],"page-size":_vm.couponse.per_page,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.couponse.total},on:{"size-change":_vm.handleSizeChanges,"current-change":_vm.handleCurrentChanges}})],2):_vm._e()]),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("提交报名")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }