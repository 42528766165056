<template>
  <div>
    <!-- 隐藏侧边栏的按钮 -->
    <el-button id="toggle-sidebar" @click="toggleSidebar" icon="el-icon-menu" circle title="隐藏侧边栏"></el-button>

    <div id="right-bar" :class="{ hidden: isSidebarHidden }">
      <!-- 其他侧边栏内容保持不变 -->
      <el-button id="hidgo" type="primary" class="hidgo hidden" @click="minimize()" icon="el-icon-s-comment" circle></el-button>
      <div class="bar-list person">
        <router-link class="item" to="/person">
          <span>个人中心</span>
        </router-link>
      </div>
      <div class="bar-list cv">
        <router-link class="item" to="/person_cv">
          <span>我的简历</span>
        </router-link>
      </div>
      <div class="bar-list about">
        <router-link class="item" to="/about">
          <span>关于我们</span>
        </router-link>
      </div>
      <div class="bar-list online">
        <span class="item hover-text" style="font-size: 13px; user-select: none;" @click="togglePopup()">在线<br>客服</span>
        <div id="popup" class="popup hidden">
          <!-- 弹窗内容保持不变 -->
        </div>
      </div>
      <div class="bar-list feedback">
        <router-link class="item" to="/feedback">
          <span>意见反馈</span>
        </router-link>
      </div>
      <div style="align-items: center">
        <el-button @click="Gotovideoconference" icon="el-icon-phone-outline" title="视频会议"></el-button>
      </div>
      <div style="align-items: center">
        <el-button @click="loginOut" icon="el-icon-switch-button" title="登出"></el-button>
      </div>
      <el-backtop class="go-top"></el-backtop>
    </div>
  </div>
</template>
 <script>
 import Api from "@/Api.js";
 import axios from "axios";
 import { connectWebsocket, closeWebsocket, sendMsg } from "@/plugins/websocket.js";
 export default {
     data() {
         return {
           roomchat:[],
           usersname:"",
           Chatteacher:null, //聊天的老师
           room:null,  //房间信息
           UserInfo:null,
           mess:null,
           chatroom:null,  //房间历史
           duiname:null,
           Indatetime:null,
           intervalId:null,
           isSidebarHidden: true, // 控制侧边栏是否隐藏
         }
     },
   created() {

   },
   methods: {
       Wssendmess(){
       Api.Roomchats.add({
         id:-1,
         room_id:this.chatroom.id,
         user_id:this.UserInfo.id,
         user_name:this.chatroom.username,
         inuser_id:this.Chatteacher.id,
         message:this.mess,
         sent_at:this.getmYdateS(),
         isactive:"正常"
       }).then(res=>{
         this.mess=null;
         this.receive();
       })
     },
       receive(){
       if (this.chatroom!=null){
         Api.Chatrooms.receivemessages({
           room_id:this.chatroom.id
         }).then(res=>{
           // 如果新数据长度更长，说明有新数据
           if (res.data.data.length > this.roomchat.length) {
             this.roomchat = this.roomchat.concat(res.data.data);
           } else {
             this.roomchat = Object.freeze(this.roomchat);
           }
         })
       }
     },
       loginOut() {
         let that = this;
         Api.User.UserLogout().then((res) => {
           if (res.data.code == 1) {
             localStorage.removeItem('userToken');
             localStorage.removeItem('loginStatus');
             window.location.reload();
           }
         })
       },
       minimize(){
         var popup = document.getElementById('popup');
         let hidgo= document.getElementById('hidgo');
         if (popup.style.display === 'none') {
           popup.style.display = 'block';
           hidgo.style.display= 'none';
         } else {
           popup.style.display = 'none';
           hidgo.style.display= 'block';
         }
       },
       togglePopup() {
         var popup = document.getElementById('popup');
         if (popup.style.display === 'none') {
           popup.style.display = 'block';
           let newValues=this.$store.getters.myState;
           Api.Config.indexwx({
             areaid:null
           }).then((res) => {
             this.UserInfo=res.data.data.userinfo;
             if (this.UserInfo.id!=null){
               Api.Roomchats.getuserids({
                 areacity:newValues[1],
                 areaID:newValues[0],
                 judge:newValues[3],
               }).then((res)=>{
                 this.Chatteacher=res.data.data;
                 this.duiname=this.Chatteacher.name;
                 Api.Roomchats.UpdateEducational({
                   teacherid:this.Chatteacher.teacherid,
                   name:this.Chatteacher.name,
                   isreceiving:1,
                   lastreceptiontime:this.Chatteacher.lastreceptiontime,
                   currentsessionstarttime:this.Chatteacher.currentsessionstarttime,
                   totalreceptioncount:this.Chatteacher.totalreceptioncount+1,
                   status:this.Chatteacher.status,
                 }).then(res=>{
                   Api.Roomchats.getbyEducationalID({
                     teacherid:this.Chatteacher.teacherid,
                   }).then(res=>{
                     this.Chatteacher.isreceiving=res.data.result.isreceiving;
                     this.Chatteacher.totalreceptioncount=res.data.result.totalreceptioncount;
                     this.Chatteacher.status=res.data.result.status;
                   })
                 })
                 Api.Chatrooms.add({
                   id:-1,
                   name:this.getmYdate()+this.generateRoomNumber(),
                   username:this.UserInfo.username,
                   type:"在线客服",
                   createdat:this.getmYdateS(),
                   updatedat:this.getmYdateS(),
                   isactive:"正常"
                 }).then(res=>{
                   this.room=res.data.result;
                   Api.Roomchats.add({
                     id:-1,
                     room_id:this.room.id,
                     user_id:this.UserInfo.id,
                     user_name:this.UserInfo.username,
                     inuser_id:this.Chatteacher.id,
                     message:"您向"+this.Chatteacher.name+"打了声招呼!",
                     sent_at:this.getmYdateS(),
                     isactive:"正常"
                   }).then((res)=>{
                     this.$notify({
                       title: '通知信息',
                       message: '成功创建房间',
                       offset: 100
                     });
                     Api.Chatrooms.indexALL({
                       id:this.UserInfo.id
                     }).then(res=>{
                       this.chatroom=res.data.data[0];
                       this.roomchat=res.data.roomchat;
                       this.startReceiving();
                     });
                   });
                 })
               })
             }
           })
         } else {
           popup.style.display = 'none';
           if (this.Chatteacher!=null){
             Api.Roomchats.UpdateEducational({
               teacherid:this.Chatteacher.teacherid,
               name:this.Chatteacher.name,
               isreceiving:0,
               lastreceptiontime:this.Chatteacher.lastreceptiontime,
               currentsessionstarttime:this.Chatteacher.currentsessionstarttime,
               totalreceptioncount:this.Chatteacher.totalreceptioncount,
               status:this.Chatteacher.status,
             }).then(res=>{
               Api.Roomchats.getbyEducationalID({
                 teacherid:this.Chatteacher.teacherid,
               }).then(res=>{
                 this.Chatteacher.isreceiving=res.data.result.isreceiving;
                 this.Chatteacher.totalreceptioncount=res.data.result.totalreceptioncount;
                 this.Chatteacher.status=res.data.result.status;
               })
             })
           }
           setTimeout(() => {
             clearInterval(this.intervalId);
           },1000);
         }
       },
       getmYdateS(){
         // 创建一个Date对象，它默认是当前时间
         let today = new Date();

         // 获取年份、月份、日、小时、分钟和秒
         let year = today.getFullYear();
         let month = today.getMonth() + 1; // 月份是从0开始的，所以需要+1
         let day = today.getDate();
         let hour = today.getHours();
         let minute = today.getMinutes();
         let second = today.getSeconds();

         // 为了格式统一，通常月份、日、小时、分钟和秒我们会使用两位数的形式
         // 如果小于10，则在前面补0
         month = month < 10 ? '0' + month : month;
         day = day < 10 ? '0' + day : day;
         hour = hour < 10 ? '0' + hour : hour;
         minute = minute < 10 ? '0' + minute : minute;
         second = second < 10 ? '0' + second : second;

         // 拼接年月日时分秒
         return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
       },
       getmYdate(){
// 创建一个Date对象，它默认是当前时间
         let today = new Date();

// 获取年份
         let year = today.getFullYear();

// 获取月份，因为月份是从0开始的，所以需要+1
         let month = today.getMonth() + 1;

// 获取日份
         let day = today.getDate();

          // 为了格式统一，通常月份和日份我们会使用两位数的形式
          // 如果月份或日份小于10，则在前面补0
         month = month < 10 ? '0' + month : month;
         day = day < 10 ? '0' + day : day;

        // 拼接年月日
         return year+month+day;
       },
       generateRoomNumber() {
   // 生成一个0到99999999之间的随机数
   let randomNum = Math.floor(Math.random() * 100000000);

   // 确保是8位数，如果小于10000000，则在前面补0
   // 注意：在大多数情况下，Math.floor(Math.random() * 100000000)已经保证了是8位数
   // 但为了严谨性，我们还是加上这个检查
   let roomNumber = randomNum.toString().padStart(8, '0');

   return roomNumber;
 },
       maximizePopup() {
         var popup = document.getElementById('popup');
         popup.classList.toggle('maximized');
       },
       closePopup() {
         this.destroyed();
         var popup = document.getElementById('popup');
         popup.style.display = 'none';
         hidgo.style.display= 'none';
         Api.Roomchats.UpdateEducational({
           teacherid:this.Chatteacher.teacherid,
           name:this.Chatteacher.name,
           isreceiving:0,
           lastreceptiontime:this.Chatteacher.lastreceptiontime,
           currentsessionstarttime:this.Chatteacher.currentsessionstarttime,
           totalreceptioncount:this.Chatteacher.totalreceptioncount,
           status:this.Chatteacher.status,
         }).then(res=>{
         })
         setTimeout(() => {
           clearInterval(this.intervalId);
           console.log('半小时定时任务结束');
         },1000);
       },
       destroyed () {
         if (this.Chatteacher!=null){
           Api.Roomchats.UpdateEducational({
             teacherid:this.Chatteacher.teacherid,
             name:this.Chatteacher.name,
             isreceiving:0,
             lastreceptiontime:this.Chatteacher.lastreceptiontime,
             currentsessionstarttime:this.Chatteacher.currentsessionstarttime,
             totalreceptioncount:this.Chatteacher.totalreceptioncount,
             status:this.Chatteacher.status,
           }).then(res=>{
             Api.Roomchats.getbyEducationalID({
               teacherid:this.Chatteacher.teacherid,
             }).then(res=>{
               this.Chatteacher.isreceiving=res.data.result.isreceiving;
               this.Chatteacher.totalreceptioncount=res.data.result.totalreceptioncount;
               this.Chatteacher.status=res.data.result.status;
             })
           })
         }
       },
       startReceiving() {
         // 获取当前时间的时间戳
         const startTime = Date.now();

         // 设置定时任务，每2秒执行一次
          this.intervalId = setInterval(() => {
           this.receive(); // 调用this.receive()方法

           // 如果执行时间超过半小时，清除定时器
           if ((Date.now() - startTime) > 30 * 60 * 1000) {
             clearInterval(this.intervalId);
             console.log('半小时时间到，定时任务已停止');
           }
         }, 2000); // 2000毫秒即2秒

         // 设置半小时后停止定时任务
         setTimeout(() => {
           clearInterval(this.intervalId);
           console.log('半小时定时任务结束');
         }, 30 * 60 * 1000); // 30分钟等于30 * 60 * 1000毫秒
       },
       Gotovideoconference(){
         this.$router.push("/videos");
       },
       toggleSidebar() {
         // 切换侧边栏的显示状态
         this.isSidebarHidden = !this.isSidebarHidden;
       },
     }
 }
 </script>

 <style scoped lang="less">
 /* scoped 让style样式 局部生效*/
 #right-bar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 50px;
    height: 100%;
    background: #fff;
    box-shadow: -1px 0px 10px 0px rgba(215,215,215,0.44);
    display: flex;
    flex-direction: column;
    justify-content: center;


    .bar-list{
        position: relative;
        border-bottom: 1px #EAEAEA solid;
    }

    .bar-list .item{
        //display: block;
        overflow: hidden;
        height: 100%;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        padding-top: 5px;
    }

    .bar-list .item span{
        font-size: 13px;
        color: #666;
        line-height: 16px;
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
    }

    .bar-list .item::before{
        margin: 5px 0;
        display: block;
        content: '';
        width: 20px;
        height: 20px;
    }

    .bar-list .item:hover{
        background: #D11A2D;
        border-bottom-color: transparent;
    }

    .bar-list .item:hover span{
        color: #ffffff;
    }

    // 个人中心
    .bar-list.person .item::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat 0 -21px;
    }

    .bar-list.person .item:hover::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat -21px -21px;
    }

    // 我的简历
    .bar-list.cv .item::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat 0 -84px;
    }

    .bar-list.cv .item:hover::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat -21px -84px;
    }

    // 关于我们
    .bar-list.about .item::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat 0 -63px;
    }

    .bar-list.about .item:hover::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat -21px -63px;
    }

    // 在线客服
    .bar-list.online .item::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat 0 -42px;
    }

    .bar-list.online .item:hover::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat -21px -42px;
    }

    // 意见反馈
    .bar-list.feedback .item::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat 0 0;
    }

    .bar-list.feedback .item:hover::before{
        background: url(../assets/icon/right-bar-ico.png) no-repeat -21px 0;
    }



    // 返回顶部
    .go-top{
        position: absolute;
        bottom: 30px!important;
        right: 0!important;
        box-shadow:none!important;
        padding-top: 12px;
        width: 100%;
        height: 40px;
        border-radius: 0!important;
        text-align: center;
        cursor: pointer;
        background-color: transparent;
        background-image: url(../assets/icon/back-top.png);
        background-position: 0 0;
    }

    .go-top:hover{
        background-color: #D11A2D;
        background-image: url(../assets/icon/back-top.png);
        background-position: -50px 0;
    }

    :deep(.go-top .el-icon-caret-top){
        display: none;
    }



   .hidden {
     display: none;
   }

   .popup {
     position: absolute;
     left: -590px;
     top: -250px;
     width: 600px;
     height: 600px;
     background-color: transparent;
     z-index: 1000;
     overflow-y: auto;
     padding: 20px;
     transition: width 0.3s ease; /* 添加过渡效果 */
   }

   .popup.maximized {
     position: fixed; /* 固定位置以适应全屏 */
     top: 0;
     left: 0;
     width: 100%; /* 宽度填充整个屏幕 */
     height: 100%; /* 高度填充整个屏幕 */
     z-index: 9999; /* 确保元素在最上层 */
     .dlog_header{
       width: 700px;
     }
     .mess{
       width: 900px;
       height: 900px;
     }
     .dlog_content{
       width: 700px;
       height: 570px;
     }
     .dlog_footer{
       width: 700px;
       height: 480px;
     }
   }

   .popup-controls {
     position: absolute;
     top: 10px;
     right: 10px;
   }

   .popup-controls button {
     margin-left: 10px;
   }
   .hidgo{
     position: absolute;
     left: -20px;
     top: 620px;
   }
}
 .mess{
   border-radius: 5px;
   background-clip: padding-box;
   margin:20px auto;
   width: 500px;
   height: 500px;
   border: 1px #8a8282;
   box-shadow: 0 0 10px #9b9393;
   background-color: white;
   display: flex;
 }

 .mess_dialog{
   width: 400px;
   height: 400px;
 }
 .mess_dialog_false{
   width: 400px;
   height: 400px;
   text-align: center;
   line-height: 400px;
 }
 .dlog_header{
   width: 400px;
   height: 50px;
   border-bottom: 1px solid #8a8282;
   display: flex;
   align-items: center;
 }
 .dlog_content{
   width: 430px;
   height: 270px;
   border-bottom: 1px solid #8a8282;
   overflow-y: scroll;
 }
 .dlog_footer{
   width: 400px;
   height: 180px;
 }
 .content_other{
   width: 400px;
 }
 .content_me {
   width: 400px;
   text-align: right;
 }
 .hover-text:hover {
   /* 鼠标划过时的样式 */
   cursor: pointer; /* 例如，将鼠标样式改为手指形状，表示可点击 */
   /* 你也可以根据需要改为其他样式，如 cursor: text; （文本样式）、cursor: wait; （等待样式）等 */
 }
 //隐藏侧边栏
 #right-bar {
   transition: all 0.3s ease; /* 添加过渡效果 */
 }

 #right-bar.hidden {
   transform: translateX(100%); /* 将侧边栏向右移动自身宽度 */
 }

 #toggle-sidebar {
   position: fixed;
   top: 80px;
   right: 20px;
   opacity: 0.5;
   z-index: 10;
 }
 </style>