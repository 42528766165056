<template>
  <main @click="handleOutsideClick" class="page">
    <div class="layout section-select">
      <div class="select-ul clearfix">
        <div class="title fl">证书类型：</div>
        <div class="content fl">
          <el-select v-model="value" placeholder="请选择">
            <el-option-group
                v-for="group in options"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
      </div>

      <div class="select-ul clearfix">
        <div class="title fl">地区名称：</div>
        <div class="content fl">
          <!-- Province Selector -->
          <el-select v-model="selectedProvince" placeholder="请选择省份">
            <el-option
                v-for="provinceItem in provinces"
                :key="provinceItem.province"
                :label="provinceItem.province"
                :value="provinceItem.province">
            </el-option>
          </el-select>

          <!-- 城市选择器 -->
          <el-select v-model="selectedCity" placeholder="请选择城市" :disabled="!selectedProvince || selectedProvince === '全部'">
            <template v-if="selectedProvince && selectedProvince !== '全部'">
              <el-option
                  v-for="cityItem in getCityOptions(selectedProvince)"
                  :key="cityItem.city"
                  :label="cityItem.city"
                  :value="cityItem.city">
              </el-option>
            </template>
          </el-select>

          <!-- 区域选择器 -->
          <el-select v-model="selectedArea" placeholder="请选择地区" :disabled="!selectedCity">
            <template v-if="selectedCity">
              <el-option
                  v-for="areaItem in getAreaOptions(selectedCity)"
                  :key="areaItem.area"
                  :label="areaItem.area"
                  :value="areaItem.area">
              </el-option>
            </template>
          </el-select>
          <el-button type="primary" @click="this.init" icon="el-icon-search">搜索</el-button>
        </div>
      </div>

    </div>

    <div class="layout select-cont">

      <div class="item" v-for="item in dataList.data">
        <router-link :to="'/school?id='+item.id" class="img-box">
         <div v-for="(itmeImg1,index1) in Imagearraylist" >
           <img v-for="(itmeImg,index) in itmeImg1" :key="index" :src="`https://admin.zhongguojiaoyunet.com//${itmeImg[index]}`" class="img"
                @click.prevent="toggleImageGallery(itmeImg)"
                alt="">
         </div>
        </router-link>
        <div class="cont-box">
          <div class="title">
            <router-link :to="'/school?id='+item.id" class="tit">{{ item.name }}</router-link>
            <span class="address">{{item.province_name}} - {{item.city_name}}</span>
          </div>
          <div class="tag">
            <el-tag type="danger" effect="plain" class="label" v-for="type in item.typesList">{{type.name}}</el-tag>
          </div>
          <div class="desc">{{ item.content }}</div>
          <div class="desc" @click="getbydataUrl(item.Friendship)" >证书来源地:  {{ item.Friendship }}</div>
        </div>
      </div>

    </div>

    <!-- 图片画廊模态框，根据isGalleryOpen控制显示 -->
    <!-- 图片画廊模态框 -->
    <div v-if="isGalleryOpen" class="image-gallery modal">
      <div class="gallery-grid">
        <img
            v-for="(galleryImg, index) in currentGallery"
            :key="index"
            :src="`http://oss.zhongguojiaoyunet.com/${galleryImg}`"
            class="gallery-image"
            @click="setCurrentImage(index)"
        />
      </div>
      <button @click="closeImageGallery" class="close-button">关闭</button>
    </div>

    <div class="layout page-block">
      <el-pagination background @current-change="handleCurrentChange" :page-size="dataList.per_page" layout="prev, pager, next, jumper" :total="dataList.total">
      </el-pagination>
    </div>

    <!--立即报名-->
    <div class="layout">
      <a class="wsc-poster">
        <img class="poster-img" @click="xianshi()" src="@/assets/images/center_advertising.png" alt="">
      </a>

      <!-- 引入弹出框组件，并通过v-if控制显示 -->
      <occPJOsignup ref="occPJOsignup"   class="popup-container"  v-if="showsignup" :visible="showsignup" @close="showsignup = false">
      </occPJOsignup>
    </div>
  </main>
</template>
<script>
import Api from "@/Api.js";
import index from "vuex";
import occPJOsignup from "@/views/ModuleS/occPJOsignup.vue";

export default {
  components: {occPJOsignup},
  computed: {
    index() {
      return index
    }
  },

  data() {
    return {
      showsignup: false,
      selectedProvince: '',
      selectedCity: '',
      selectedArea: '',
      Imagearraylist:[],
      dataList:'',
      isGalleryOpen: false, // 控制图片画廊是否显示
      currentImageIndex: null, // 当前选中的图片索引
      itemImg1: [], // 假设这是你的图片数据
      options: [{
        label: '热门',
        options: [{
          value: '1',
          label: '教资'
        }]
      }, {
        label: '证件名',
        options: [{
          value: '1',
          label: '教资'
        }]
      }],
      provinces: [
        {
          "province": "全部",
          "citys": [
            {
              "city": "全部",
              "areas": [
                {
                  "area": "全部"
                }
              ]
            }
          ]
        },
       {
            "province": "北京市",
            "citys": [
              {
                "city": "北京市",
                "areas": [
                  {
                    "area": "北京市"
                  }
                ]
              }
            ]
          },
          {
            province: "天津市",
            citys: [
              {
                "city": "天津市",
                "areas": [
                  {
                    "area": "天津市"
                  }
                ]
              }
            ]
          },
          {
            province: "河北省",
            citys: [
              {
                city: "石家庄市",
                areas: [
                  {
                    "area": "井陉县"
                  },
                  {
                    "area": "正定县"
                  },
                  {
                    "area": "行唐县"
                  },
                  {
                    "area": "灵寿县"
                  },
                  {
                    "area": "高邑县"
                  },
                  {
                    "area": "深泽县"
                  },
                  {
                    "area": "赞皇县"
                  },
                  {
                    "area": "无极县"
                  },
                  {
                    "area": "平山县"
                  },
                  {
                    "area": "元氏县"
                  },
                  {
                    "area": "赵县"
                  },
                  {
                    "area": "辛集市"
                  },
                  {
                    "area": "晋州市"
                  },
                  {
                    "area": "新乐市"
                  },
                  {
                    "area": "石家庄市"
                  }
                ]
              },
              {
                "city": "唐山市",
                "areas": [
                  {
                    "area": "滦南县"
                  },
                  {
                    "area": "乐亭县"
                  },
                  {
                    "area": "迁西县"
                  },
                  {
                    "area": "玉田县"
                  },
                  {
                    "area": "遵化市"
                  },
                  {
                    "area": "迁安市"
                  },
                  {
                    "area": "滦州市"
                  },
                  {
                    "area": "唐山市"
                  }
                ]
              },
              {
                "city": "秦皇岛市",
                "areas": [
                  {
                    "area": "青龙满族自治县"
                  },
                  {
                    "area": "昌黎县"
                  },
                  {
                    "area": "卢龙县"
                  },
                  {
                    "area": "秦皇岛市"
                  }
                ]
              },
              {
                "city": "邯郸市",
                "areas": [
                  {
                    "area": "临漳县"
                  },
                  {
                    "area": "成安县"
                  },
                  {
                    "area": "大名县"
                  },
                  {
                    "area": "涉县"
                  },
                  {
                    "area": "磁县"
                  },
                  {
                    "area": "邱县"
                  },
                  {
                    "area": "鸡泽县"
                  },
                  {
                    "area": "广平县"
                  },
                  {
                    "area": "馆陶县"
                  },
                  {
                    "area": "魏县"
                  },
                  {
                    "area": "曲周县"
                  },
                  {
                    "area": "武安市"
                  },
                  {
                    "area": "邯郸市"
                  }
                ]
              },
              {
                "city": "邢台市",
                "areas": [
                  {
                    "area": "临城县"
                  },
                  {
                    "area": "内丘县"
                  },
                  {
                    "area": "柏乡县"
                  },
                  {
                    "area": "隆尧县"
                  },
                  {
                    "area": "宁晋县"
                  },
                  {
                    "area": "巨鹿县"
                  },
                  {
                    "area": "新河县"
                  },
                  {
                    "area": "广宗县"
                  },
                  {
                    "area": "平乡县"
                  },
                  {
                    "area": "威县"
                  },
                  {
                    "area": "清河县"
                  },
                  {
                    "area": "临西县"
                  },
                  {
                    "area": "南宫市"
                  },
                  {
                    "area": "沙河市"
                  },
                  {
                    "area": "邢台市"
                  }
                ]
              },
              {
                "city": "保定市",
                "areas": [
                  {
                    "area": "涞水县"
                  },
                  {
                    "area": "阜平县"
                  },
                  {
                    "area": "定兴县"
                  },
                  {
                    "area": "唐县"
                  },
                  {
                    "area": "高阳县"
                  },
                  {
                    "area": "容城县"
                  },
                  {
                    "area": "涞源县"
                  },
                  {
                    "area": "望都县"
                  },
                  {
                    "area": "安新县"
                  },
                  {
                    "area": "易县"
                  },
                  {
                    "area": "曲阳县"
                  },
                  {
                    "area": "蠡县"
                  },
                  {
                    "area": "顺平县"
                  },
                  {
                    "area": "博野县"
                  },
                  {
                    "area": "雄县"
                  },
                  {
                    "area": "涿州市"
                  },
                  {
                    "area": "定州市"
                  },
                  {
                    "area": "安国市"
                  },
                  {
                    "area": "高碑店市"
                  },
                  {
                    "area": "保定市"
                  }
                ]
              },
              {
                "city": "张家口市",
                "areas": [
                  {
                    "area": "张北县"
                  },
                  {
                    "area": "康保县"
                  },
                  {
                    "area": "沽源县"
                  },
                  {
                    "area": "尚义县"
                  },
                  {
                    "area": "蔚县"
                  },
                  {
                    "area": "阳原县"
                  },
                  {
                    "area": "怀安县"
                  },
                  {
                    "area": "怀来县"
                  },
                  {
                    "area": "涿鹿县"
                  },
                  {
                    "area": "赤城县"
                  },
                  {
                    "area": "张家口市"
                  }
                ]
              },
              {
                "city": "承德市",
                "areas": [
                  {
                    "area": "承德县"
                  },
                  {
                    "area": "兴隆县"
                  },
                  {
                    "area": "滦平县"
                  },
                  {
                    "area": "隆化县"
                  },
                  {
                    "area": "丰宁满族自治县"
                  },
                  {
                    "area": "宽城满族自治县"
                  },
                  {
                    "area": "围场满族蒙古族自治县"
                  },
                  {
                    "area": "平泉市"
                  },
                  {
                    "area": "承德市"
                  }
                ]
              },
              {
                "city": "沧州市",
                "areas": [
                  {
                    "area": "沧县"
                  },
                  {
                    "area": "青县"
                  },
                  {
                    "area": "东光县"
                  },
                  {
                    "area": "海兴县"
                  },
                  {
                    "area": "盐山县"
                  },
                  {
                    "area": "肃宁县"
                  },
                  {
                    "area": "南皮县"
                  },
                  {
                    "area": "吴桥县"
                  },
                  {
                    "area": "献县"
                  },
                  {
                    "area": "孟村回族自治县"
                  },
                  {
                    "area": "泊头市"
                  },
                  {
                    "area": "任丘市"
                  },
                  {
                    "area": "黄骅市"
                  },
                  {
                    "area": "河间市"
                  },
                  {
                    "area": "沧州市"
                  }
                ]
              },
              {
                "city": "廊坊市",
                "areas": [
                  {
                    "area": "固安县"
                  },
                  {
                    "area": "永清县"
                  },
                  {
                    "area": "香河县"
                  },
                  {
                    "area": "大城县"
                  },
                  {
                    "area": "文安县"
                  },
                  {
                    "area": "大厂回族自治县"
                  },
                  {
                    "area": "霸州市"
                  },
                  {
                    "area": "三河市"
                  },
                  {
                    "area": "廊坊市"
                  }
                ]
              },
              {
                "city": "衡水市",
                "areas": [
                  {
                    "area": "枣强县"
                  },
                  {
                    "area": "武邑县"
                  },
                  {
                    "area": "武强县"
                  },
                  {
                    "area": "饶阳县"
                  },
                  {
                    "area": "安平县"
                  },
                  {
                    "area": "故城县"
                  },
                  {
                    "area": "景县"
                  },
                  {
                    "area": "阜城县"
                  },
                  {
                    "area": "深州市"
                  },
                  {
                    "area": "衡水市"
                  }
                ]
              }
            ]
          },
          {
            "province": "山西省",
            "citys": [
              {
                "city": "太原市",
                "areas": [
                  {
                    "area": "清徐县"
                  },
                  {
                    "area": "阳曲县"
                  },
                  {
                    "area": "娄烦县"
                  },
                  {
                    "area": "古交市"
                  },
                  {
                    "area": "太原市"
                  }
                ]
              },
              {
                "city": "大同市",
                "areas": [
                  {
                    "area": "阳高县"
                  },
                  {
                    "area": "天镇县"
                  },
                  {
                    "area": "广灵县"
                  },
                  {
                    "area": "灵丘县"
                  },
                  {
                    "area": "浑源县"
                  },
                  {
                    "area": "左云县"
                  },
                  {
                    "area": "大同市"
                  }
                ]
              },
              {
                "city": "阳泉市",
                "areas": [
                  {
                    "area": "平定县"
                  },
                  {
                    "area": "盂县"
                  },
                  {
                    "area": "阳泉市"
                  }
                ]
              },
              {
                "city": "长治市",
                "areas": [
                  {
                    "area": "襄垣县"
                  },
                  {
                    "area": "平顺县"
                  },
                  {
                    "area": "黎城县"
                  },
                  {
                    "area": "壶关县"
                  },
                  {
                    "area": "长子县"
                  },
                  {
                    "area": "武乡县"
                  },
                  {
                    "area": "沁县"
                  },
                  {
                    "area": "沁源县"
                  },
                  {
                    "area": "长治市"
                  }
                ]
              },
              {
                "city": "晋城市",
                "areas": [
                  {
                    "area": "沁水县"
                  },
                  {
                    "area": "阳城县"
                  },
                  {
                    "area": "陵川县"
                  },
                  {
                    "area": "泽州县"
                  },
                  {
                    "area": "高平市"
                  },
                  {
                    "area": "晋城市"
                  }
                ]
              },
              {
                "city": "朔州市",
                "areas": [
                  {
                    "area": "山阴县"
                  },
                  {
                    "area": "应县"
                  },
                  {
                    "area": "右玉县"
                  },
                  {
                    "area": "怀仁市"
                  },
                  {
                    "area": "朔州市"
                  }
                ]
              },
              {
                "city": "晋中市",
                "areas": [
                  {
                    "area": "榆社县"
                  },
                  {
                    "area": "左权县"
                  },
                  {
                    "area": "和顺县"
                  },
                  {
                    "area": "昔阳县"
                  },
                  {
                    "area": "寿阳县"
                  },
                  {
                    "area": "祁县"
                  },
                  {
                    "area": "平遥县"
                  },
                  {
                    "area": "灵石县"
                  },
                  {
                    "area": "介休市"
                  },
                  {
                    "area": "晋中市"
                  }
                ]
              },
              {
                "city": "运城市",
                "areas": [
                  {
                    "area": "临猗县"
                  },
                  {
                    "area": "万荣县"
                  },
                  {
                    "area": "闻喜县"
                  },
                  {
                    "area": "稷山县"
                  },
                  {
                    "area": "新绛县"
                  },
                  {
                    "area": "绛县"
                  },
                  {
                    "area": "垣曲县"
                  },
                  {
                    "area": "夏县"
                  },
                  {
                    "area": "平陆县"
                  },
                  {
                    "area": "芮城县"
                  },
                  {
                    "area": "永济市"
                  },
                  {
                    "area": "河津市"
                  },
                  {
                    "area": "运城市"
                  }
                ]
              },
              {
                "city": "忻州市",
                "areas": [
                  {
                    "area": "定襄县"
                  },
                  {
                    "area": "五台县"
                  },
                  {
                    "area": "代县"
                  },
                  {
                    "area": "繁峙县"
                  },
                  {
                    "area": "宁武县"
                  },
                  {
                    "area": "静乐县"
                  },
                  {
                    "area": "神池县"
                  },
                  {
                    "area": "五寨县"
                  },
                  {
                    "area": "岢岚县"
                  },
                  {
                    "area": "河曲县"
                  },
                  {
                    "area": "保德县"
                  },
                  {
                    "area": "偏关县"
                  },
                  {
                    "area": "原平市"
                  },
                  {
                    "area": "忻州市"
                  }
                ]
              },
              {
                "city": "临汾市",
                "areas": [
                  {
                    "area": "曲沃县"
                  },
                  {
                    "area": "翼城县"
                  },
                  {
                    "area": "襄汾县"
                  },
                  {
                    "area": "洪洞县"
                  },
                  {
                    "area": "古县"
                  },
                  {
                    "area": "安泽县"
                  },
                  {
                    "area": "浮山县"
                  },
                  {
                    "area": "吉县"
                  },
                  {
                    "area": "乡宁县"
                  },
                  {
                    "area": "大宁县"
                  },
                  {
                    "area": "隰县"
                  },
                  {
                    "area": "永和县"
                  },
                  {
                    "area": "蒲县"
                  },
                  {
                    "area": "汾西县"
                  },
                  {
                    "area": "侯马市"
                  },
                  {
                    "area": "霍州市"
                  },
                  {
                    "area": "临汾市"
                  }
                ]
              },
              {
                "city": "吕梁市",
                "areas": [
                  {
                    "area": "文水县"
                  },
                  {
                    "area": "交城县"
                  },
                  {
                    "area": "兴县"
                  },
                  {
                    "area": "临县"
                  },
                  {
                    "area": "柳林县"
                  },
                  {
                    "area": "石楼县"
                  },
                  {
                    "area": "岚县"
                  },
                  {
                    "area": "方山县"
                  },
                  {
                    "area": "中阳县"
                  },
                  {
                    "area": "交口县"
                  },
                  {
                    "area": "孝义市"
                  },
                  {
                    "area": "汾阳市"
                  },
                  {
                    "area": "吕梁市"
                  }
                ]
              }
            ]
          },
          {
            "province": "内蒙古自治区",
            "citys": [
              {
                "city": "呼和浩特市",
                "areas": [
                  {
                    "area": "土默特左旗"
                  },
                  {
                    "area": "托克托县"
                  },
                  {
                    "area": "和林格尔县"
                  },
                  {
                    "area": "清水河县"
                  },
                  {
                    "area": "武川县"
                  },
                  {
                    "area": "呼和浩特市"
                  }
                ]
              },
              {
                "city": "包头市",
                "areas": [
                  {
                    "area": "土默特右旗"
                  },
                  {
                    "area": "固阳县"
                  },
                  {
                    "area": "达尔罕茂明安联合旗"
                  },
                  {
                    "area": "包头市"
                  }
                ]
              },
              {
                "city": "乌海市",
                "areas": [
                  {
                    "area": "乌海市"
                  }
                ]
              },
              {
                "city": "赤峰市",
                "areas": [
                  {
                    "area": "阿鲁科尔沁旗"
                  },
                  {
                    "area": "巴林左旗"
                  },
                  {
                    "area": "巴林右旗"
                  },
                  {
                    "area": "林西县"
                  },
                  {
                    "area": "克什克腾旗"
                  },
                  {
                    "area": "翁牛特旗"
                  },
                  {
                    "area": "喀喇沁旗"
                  },
                  {
                    "area": "宁城县"
                  },
                  {
                    "area": "敖汉旗"
                  },
                  {
                    "area": "赤峰市"
                  }
                ]
              },
              {
                "city": "通辽市",
                "areas": [
                  {
                    "area": "科尔沁左翼中旗"
                  },
                  {
                    "area": "科尔沁左翼后旗"
                  },
                  {
                    "area": "开鲁县"
                  },
                  {
                    "area": "库伦旗"
                  },
                  {
                    "area": "奈曼旗"
                  },
                  {
                    "area": "扎鲁特旗"
                  },
                  {
                    "area": "霍林郭勒市"
                  },
                  {
                    "area": "通辽市"
                  }
                ]
              },
              {
                "city": "鄂尔多斯市",
                "areas": [
                  {
                    "area": "达拉特旗"
                  },
                  {
                    "area": "准格尔旗"
                  },
                  {
                    "area": "鄂托克前旗"
                  },
                  {
                    "area": "鄂托克旗"
                  },
                  {
                    "area": "杭锦旗"
                  },
                  {
                    "area": "乌审旗"
                  },
                  {
                    "area": "伊金霍洛旗"
                  },
                  {
                    "area": "鄂尔多斯市"
                  }
                ]
              },
              {
                "city": "呼伦贝尔市",
                "areas": [
                  {
                    "area": "阿荣旗"
                  },
                  {
                    "area": "莫力达瓦达斡尔族自治旗"
                  },
                  {
                    "area": "鄂伦春自治旗"
                  },
                  {
                    "area": "鄂温克族自治旗"
                  },
                  {
                    "area": "陈巴尔虎旗"
                  },
                  {
                    "area": "新巴尔虎左旗"
                  },
                  {
                    "area": "新巴尔虎右旗"
                  },
                  {
                    "area": "满洲里市"
                  },
                  {
                    "area": "牙克石市"
                  },
                  {
                    "area": "扎兰屯市"
                  },
                  {
                    "area": "额尔古纳市"
                  },
                  {
                    "area": "根河市"
                  },
                  {
                    "area": "呼伦贝尔市"
                  }
                ]
              },
              {
                "city": "巴彦淖尔市",
                "areas": [
                  {
                    "area": "五原县"
                  },
                  {
                    "area": "磴口县"
                  },
                  {
                    "area": "乌拉特前旗"
                  },
                  {
                    "area": "乌拉特中旗"
                  },
                  {
                    "area": "乌拉特后旗"
                  },
                  {
                    "area": "杭锦后旗"
                  },
                  {
                    "area": "巴彦淖尔市"
                  }
                ]
              },
              {
                "city": "乌兰察布市",
                "areas": [
                  {
                    "area": "卓资县"
                  },
                  {
                    "area": "化德县"
                  },
                  {
                    "area": "商都县"
                  },
                  {
                    "area": "兴和县"
                  },
                  {
                    "area": "凉城县"
                  },
                  {
                    "area": "察哈尔右翼前旗"
                  },
                  {
                    "area": "察哈尔右翼中旗"
                  },
                  {
                    "area": "察哈尔右翼后旗"
                  },
                  {
                    "area": "四子王旗"
                  },
                  {
                    "area": "丰镇市"
                  },
                  {
                    "area": "乌兰察布市"
                  }
                ]
              },
              {
                "city": "兴安盟",
                "areas": [
                  {
                    "area": "乌兰浩特市"
                  },
                  {
                    "area": "阿尔山市"
                  },
                  {
                    "area": "科尔沁右翼前旗"
                  },
                  {
                    "area": "科尔沁右翼中旗"
                  },
                  {
                    "area": "扎赉特旗"
                  },
                  {
                    "area": "突泉县"
                  }
                ]
              },
              {
                "city": "锡林郭勒盟",
                "areas": [
                  {
                    "area": "二连浩特市"
                  },
                  {
                    "area": "锡林浩特市"
                  },
                  {
                    "area": "阿巴嘎旗"
                  },
                  {
                    "area": "苏尼特左旗"
                  },
                  {
                    "area": "苏尼特右旗"
                  },
                  {
                    "area": "东乌珠穆沁旗"
                  },
                  {
                    "area": "西乌珠穆沁旗"
                  },
                  {
                    "area": "太仆寺旗"
                  },
                  {
                    "area": "镶黄旗"
                  },
                  {
                    "area": "正镶白旗"
                  },
                  {
                    "area": "正蓝旗"
                  },
                  {
                    "area": "多伦县"
                  }
                ]
              },
              {
                "city": "阿拉善盟",
                "areas": [
                  {
                    "area": "阿拉善左旗"
                  },
                  {
                    "area": "阿拉善右旗"
                  },
                  {
                    "area": "额济纳旗"
                  }
                ]
              }
            ]
          },
          {
            "province": "辽宁省",
            "citys": [
              {
                "city": "沈阳市",
                "areas": [
                  {
                    "area": "康平县"
                  },
                  {
                    "area": "法库县"
                  },
                  {
                    "area": "新民市"
                  },
                  {
                    "area": "沈阳市"
                  }
                ]
              },
              {
                "city": "大连市",
                "areas": [
                  {
                    "area": "长海县"
                  },
                  {
                    "area": "瓦房店市"
                  },
                  {
                    "area": "庄河市"
                  },
                  {
                    "area": "大连市"
                  }
                ]
              },
              {
                "city": "鞍山市",
                "areas": [
                  {
                    "area": "台安县"
                  },
                  {
                    "area": "岫岩满族自治县"
                  },
                  {
                    "area": "海城市"
                  },
                  {
                    "area": "鞍山市"
                  }
                ]
              },
              {
                "city": "抚顺市",
                "areas": [
                  {
                    "area": "抚顺县"
                  },
                  {
                    "area": "新宾满族自治县"
                  },
                  {
                    "area": "清原满族自治县"
                  },
                  {
                    "area": "抚顺市"
                  }
                ]
              },
              {
                "city": "本溪市",
                "areas": [
                  {
                    "area": "本溪满族自治县"
                  },
                  {
                    "area": "桓仁满族自治县"
                  },
                  {
                    "area": "本溪市"
                  }
                ]
              },
              {
                "city": "丹东市",
                "areas": [
                  {
                    "area": "宽甸满族自治县"
                  },
                  {
                    "area": "东港市"
                  },
                  {
                    "area": "凤城市"
                  },
                  {
                    "area": "丹东市"
                  }
                ]
              },
              {
                "city": "锦州市",
                "areas": [
                  {
                    "area": "黑山县"
                  },
                  {
                    "area": "义县"
                  },
                  {
                    "area": "凌海市"
                  },
                  {
                    "area": "北镇市"
                  },
                  {
                    "area": "锦州市"
                  }
                ]
              },
              {
                "city": "营口市",
                "areas": [
                  {
                    "area": "盖州市"
                  },
                  {
                    "area": "大石桥市"
                  },
                  {
                    "area": "营口市"
                  }
                ]
              },
              {
                "city": "阜新市",
                "areas": [
                  {
                    "area": "阜新蒙古族自治县"
                  },
                  {
                    "area": "彰武县"
                  },
                  {
                    "area": "阜新市"
                  }
                ]
              },
              {
                "city": "辽阳市",
                "areas": [
                  {
                    "area": "辽阳县"
                  },
                  {
                    "area": "灯塔市"
                  },
                  {
                    "area": "辽阳市"
                  }
                ]
              },
              {
                "city": "盘锦市",
                "areas": [
                  {
                    "area": "盘山县"
                  },
                  {
                    "area": "盘锦市"
                  }
                ]
              },
              {
                "city": "铁岭市",
                "areas": [
                  {
                    "area": "铁岭县"
                  },
                  {
                    "area": "西丰县"
                  },
                  {
                    "area": "昌图县"
                  },
                  {
                    "area": "调兵山市"
                  },
                  {
                    "area": "开原市"
                  },
                  {
                    "area": "铁岭市"
                  }
                ]
              },
              {
                "city": "朝阳市",
                "areas": [
                  {
                    "area": "朝阳县"
                  },
                  {
                    "area": "建平县"
                  },
                  {
                    "area": "喀喇沁左翼蒙古族自治县"
                  },
                  {
                    "area": "北票市"
                  },
                  {
                    "area": "凌源市"
                  },
                  {
                    "area": "朝阳市"
                  }
                ]
              },
              {
                "city": "葫芦岛市",
                "areas": [
                  {
                    "area": "绥中县"
                  },
                  {
                    "area": "建昌县"
                  },
                  {
                    "area": "兴城市"
                  },
                  {
                    "area": "葫芦岛市"
                  }
                ]
              }
            ]
          },
          {
            "province": "吉林省",
            "citys": [
              {
                "city": "长春市",
                "areas": [
                  {
                    "area": "农安县"
                  },
                  {
                    "area": "榆树市"
                  },
                  {
                    "area": "德惠市"
                  },
                  {
                    "area": "公主岭市"
                  },
                  {
                    "area": "长春市"
                  }
                ]
              },
              {
                "city": "吉林市",
                "areas": [
                  {
                    "area": "永吉县"
                  },
                  {
                    "area": "蛟河市"
                  },
                  {
                    "area": "桦甸市"
                  },
                  {
                    "area": "舒兰市"
                  },
                  {
                    "area": "磐石市"
                  },
                  {
                    "area": "吉林市"
                  }
                ]
              },
              {
                "city": "四平市",
                "areas": [
                  {
                    "area": "梨树县"
                  },
                  {
                    "area": "伊通满族自治县"
                  },
                  {
                    "area": "双辽市"
                  },
                  {
                    "area": "四平市"
                  }
                ]
              },
              {
                "city": "辽源市",
                "areas": [
                  {
                    "area": "东丰县"
                  },
                  {
                    "area": "东辽县"
                  },
                  {
                    "area": "辽源市"
                  }
                ]
              },
              {
                "city": "通化市",
                "areas": [
                  {
                    "area": "通化县"
                  },
                  {
                    "area": "辉南县"
                  },
                  {
                    "area": "柳河县"
                  },
                  {
                    "area": "梅河口市"
                  },
                  {
                    "area": "集安市"
                  },
                  {
                    "area": "通化市"
                  }
                ]
              },
              {
                "city": "白山市",
                "areas": [
                  {
                    "area": "抚松县"
                  },
                  {
                    "area": "靖宇县"
                  },
                  {
                    "area": "长白朝鲜族自治县"
                  },
                  {
                    "area": "临江市"
                  },
                  {
                    "area": "白山市"
                  }
                ]
              },
              {
                "city": "松原市",
                "areas": [
                  {
                    "area": "前郭尔罗斯蒙古族自治县"
                  },
                  {
                    "area": "长岭县"
                  },
                  {
                    "area": "乾安县"
                  },
                  {
                    "area": "扶余市"
                  },
                  {
                    "area": "松原市"
                  }
                ]
              },
              {
                "city": "白城市",
                "areas": [
                  {
                    "area": "镇赉县"
                  },
                  {
                    "area": "通榆县"
                  },
                  {
                    "area": "洮南市"
                  },
                  {
                    "area": "大安市"
                  },
                  {
                    "area": "白城市"
                  }
                ]
              },
              {
                "city": "延边朝鲜族自治州",
                "areas": [
                  {
                    "area": "延吉市"
                  },
                  {
                    "area": "图们市"
                  },
                  {
                    "area": "敦化市"
                  },
                  {
                    "area": "珲春市"
                  },
                  {
                    "area": "龙井市"
                  },
                  {
                    "area": "和龙市"
                  },
                  {
                    "area": "汪清县"
                  },
                  {
                    "area": "安图县"
                  }
                ]
              }
            ]
          },
          {
            "province": "黑龙江省",
            "citys": [
              {
                "city": "哈尔滨市",
                "areas": [
                  {
                    "area": "依兰县"
                  },
                  {
                    "area": "方正县"
                  },
                  {
                    "area": "宾县"
                  },
                  {
                    "area": "巴彦县"
                  },
                  {
                    "area": "木兰县"
                  },
                  {
                    "area": "通河县"
                  },
                  {
                    "area": "延寿县"
                  },
                  {
                    "area": "尚志市"
                  },
                  {
                    "area": "五常市"
                  },
                  {
                    "area": "哈尔滨市"
                  }
                ]
              },
              {
                "city": "齐齐哈尔市",
                "areas": [
                  {
                    "area": "龙江县"
                  },
                  {
                    "area": "依安县"
                  },
                  {
                    "area": "泰来县"
                  },
                  {
                    "area": "甘南县"
                  },
                  {
                    "area": "富裕县"
                  },
                  {
                    "area": "克山县"
                  },
                  {
                    "area": "克东县"
                  },
                  {
                    "area": "拜泉县"
                  },
                  {
                    "area": "讷河市"
                  },
                  {
                    "area": "齐齐哈尔市"
                  }
                ]
              },
              {
                "city": "鸡西市",
                "areas": [
                  {
                    "area": "鸡东县"
                  },
                  {
                    "area": "虎林市"
                  },
                  {
                    "area": "密山市"
                  },
                  {
                    "area": "鸡西市"
                  }
                ]
              },
              {
                "city": "鹤岗市",
                "areas": [
                  {
                    "area": "萝北县"
                  },
                  {
                    "area": "绥滨县"
                  },
                  {
                    "area": "鹤岗市"
                  }
                ]
              },
              {
                "city": "双鸭山市",
                "areas": [
                  {
                    "area": "集贤县"
                  },
                  {
                    "area": "友谊县"
                  },
                  {
                    "area": "宝清县"
                  },
                  {
                    "area": "饶河县"
                  },
                  {
                    "area": "双鸭山市"
                  }
                ]
              },
              {
                "city": "大庆市",
                "areas": [
                  {
                    "area": "肇州县"
                  },
                  {
                    "area": "肇源县"
                  },
                  {
                    "area": "林甸县"
                  },
                  {
                    "area": "杜尔伯特蒙古族自治县"
                  },
                  {
                    "area": "大庆市"
                  }
                ]
              },
              {
                "city": "伊春市",
                "areas": [
                  {
                    "area": "嘉荫县"
                  },
                  {
                    "area": "汤旺县"
                  },
                  {
                    "area": "丰林县"
                  },
                  {
                    "area": "大箐山县"
                  },
                  {
                    "area": "南岔县"
                  },
                  {
                    "area": "铁力市"
                  },
                  {
                    "area": "伊春市"
                  }
                ]
              },
              {
                "city": "佳木斯市",
                "areas": [
                  {
                    "area": "桦南县"
                  },
                  {
                    "area": "桦川县"
                  },
                  {
                    "area": "汤原县"
                  },
                  {
                    "area": "同江市"
                  },
                  {
                    "area": "富锦市"
                  },
                  {
                    "area": "抚远市"
                  },
                  {
                    "area": "佳木斯市"
                  }
                ]
              },
              {
                "city": "七台河市",
                "areas": [
                  {
                    "area": "勃利县"
                  },
                  {
                    "area": "七台河市"
                  }
                ]
              },
              {
                "city": "牡丹江市",
                "areas": [
                  {
                    "area": "林口县"
                  },
                  {
                    "area": "绥芬河市"
                  },
                  {
                    "area": "海林市"
                  },
                  {
                    "area": "宁安市"
                  },
                  {
                    "area": "穆棱市"
                  },
                  {
                    "area": "东宁市"
                  },
                  {
                    "area": "牡丹江市"
                  }
                ]
              },
              {
                "city": "黑河市",
                "areas": [
                  {
                    "area": "逊克县"
                  },
                  {
                    "area": "孙吴县"
                  },
                  {
                    "area": "北安市"
                  },
                  {
                    "area": "五大连池市"
                  },
                  {
                    "area": "嫩江市"
                  },
                  {
                    "area": "黑河市"
                  }
                ]
              },
              {
                "city": "绥化市",
                "areas": [
                  {
                    "area": "望奎县"
                  },
                  {
                    "area": "兰西县"
                  },
                  {
                    "area": "青冈县"
                  },
                  {
                    "area": "庆安县"
                  },
                  {
                    "area": "明水县"
                  },
                  {
                    "area": "绥棱县"
                  },
                  {
                    "area": "安达市"
                  },
                  {
                    "area": "肇东市"
                  },
                  {
                    "area": "海伦市"
                  },
                  {
                    "area": "绥化市"
                  }
                ]
              },
              {
                "city": "大兴安岭地区",
                "areas": [
                  {
                    "area": "漠河市"
                  },
                  {
                    "area": "呼玛县"
                  },
                  {
                    "area": "塔河县"
                  }
                ]
              }
            ]
          },
          {
            "province": "上海市",
            "citys": [
              {
                "city": "上海市",
                "areas": [
                  {
                    "area": "上海市"
                  }
                ]
              }
            ]
          },
          {
            "province": "江苏省",
            "citys": [
              {
                "city": "南京市",
                "areas": [
                  {
                    "area": "南京市"
                  }
                ]
              },
              {
                "city": "无锡市",
                "areas": [
                  {
                    "area": "江阴市"
                  },
                  {
                    "area": "宜兴市"
                  },
                  {
                    "area": "无锡市"
                  }
                ]
              },
              {
                "city": "徐州市",
                "areas": [
                  {
                    "area": "丰县"
                  },
                  {
                    "area": "沛县"
                  },
                  {
                    "area": "睢宁县"
                  },
                  {
                    "area": "新沂市"
                  },
                  {
                    "area": "邳州市"
                  },
                  {
                    "area": "徐州市"
                  }
                ]
              },
              {
                "city": "常州市",
                "areas": [
                  {
                    "area": "溧阳市"
                  },
                  {
                    "area": "常州市"
                  }
                ]
              },
              {
                "city": "苏州市",
                "areas": [
                  {
                    "area": "常熟市"
                  },
                  {
                    "area": "张家港市"
                  },
                  {
                    "area": "昆山市"
                  },
                  {
                    "area": "太仓市"
                  },
                  {
                    "area": "苏州市"
                  }
                ]
              },
              {
                "city": "南通市",
                "areas": [
                  {
                    "area": "如东县"
                  },
                  {
                    "area": "启东市"
                  },
                  {
                    "area": "如皋市"
                  },
                  {
                    "area": "海安市"
                  },
                  {
                    "area": "南通市"
                  }
                ]
              },
              {
                "city": "连云港市",
                "areas": [
                  {
                    "area": "东海县"
                  },
                  {
                    "area": "灌云县"
                  },
                  {
                    "area": "灌南县"
                  },
                  {
                    "area": "连云港市"
                  }
                ]
              },
              {
                "city": "淮安市",
                "areas": [
                  {
                    "area": "涟水县"
                  },
                  {
                    "area": "盱眙县"
                  },
                  {
                    "area": "金湖县"
                  },
                  {
                    "area": "淮安市"
                  }
                ]
              },
              {
                "city": "盐城市",
                "areas": [
                  {
                    "area": "响水县"
                  },
                  {
                    "area": "滨海县"
                  },
                  {
                    "area": "阜宁县"
                  },
                  {
                    "area": "射阳县"
                  },
                  {
                    "area": "建湖县"
                  },
                  {
                    "area": "东台市"
                  },
                  {
                    "area": "盐城市"
                  }
                ]
              },
              {
                "city": "扬州市",
                "areas": [
                  {
                    "area": "宝应县"
                  },
                  {
                    "area": "仪征市"
                  },
                  {
                    "area": "高邮市"
                  },
                  {
                    "area": "扬州市"
                  }
                ]
              },
              {
                "city": "镇江市",
                "areas": [
                  {
                    "area": "丹阳市"
                  },
                  {
                    "area": "扬中市"
                  },
                  {
                    "area": "句容市"
                  },
                  {
                    "area": "镇江市"
                  }
                ]
              },
              {
                "city": "泰州市",
                "areas": [
                  {
                    "area": "兴化市"
                  },
                  {
                    "area": "靖江市"
                  },
                  {
                    "area": "泰兴市"
                  },
                  {
                    "area": "泰州市"
                  }
                ]
              },
              {
                "city": "宿迁市",
                "areas": [
                  {
                    "area": "沭阳县"
                  },
                  {
                    "area": "泗阳县"
                  },
                  {
                    "area": "泗洪县"
                  },
                  {
                    "area": "宿迁市"
                  }
                ]
              }
            ]
          },
          {
            "province": "浙江省",
            "citys": [
              {
                "city": "杭州市",
                "areas": [
                  {
                    "area": "桐庐县"
                  },
                  {
                    "area": "淳安县"
                  },
                  {
                    "area": "建德市"
                  },
                  {
                    "area": "杭州市"
                  }
                ]
              },
              {
                "city": "宁波市",
                "areas": [
                  {
                    "area": "象山县"
                  },
                  {
                    "area": "宁海县"
                  },
                  {
                    "area": "余姚市"
                  },
                  {
                    "area": "慈溪市"
                  },
                  {
                    "area": "宁波市"
                  }
                ]
              },
              {
                "city": "温州市",
                "areas": [
                  {
                    "area": "永嘉县"
                  },
                  {
                    "area": "平阳县"
                  },
                  {
                    "area": "苍南县"
                  },
                  {
                    "area": "文成县"
                  },
                  {
                    "area": "泰顺县"
                  },
                  {
                    "area": "瑞安市"
                  },
                  {
                    "area": "乐清市"
                  },
                  {
                    "area": "龙港市"
                  },
                  {
                    "area": "温州市"
                  }
                ]
              },
              {
                "city": "嘉兴市",
                "areas": [
                  {
                    "area": "嘉善县"
                  },
                  {
                    "area": "海盐县"
                  },
                  {
                    "area": "海宁市"
                  },
                  {
                    "area": "平湖市"
                  },
                  {
                    "area": "桐乡市"
                  },
                  {
                    "area": "嘉兴市"
                  }
                ]
              },
              {
                "city": "湖州市",
                "areas": [
                  {
                    "area": "德清县"
                  },
                  {
                    "area": "长兴县"
                  },
                  {
                    "area": "安吉县"
                  },
                  {
                    "area": "湖州市"
                  }
                ]
              },
              {
                "city": "绍兴市",
                "areas": [
                  {
                    "area": "新昌县"
                  },
                  {
                    "area": "诸暨市"
                  },
                  {
                    "area": "嵊州市"
                  },
                  {
                    "area": "绍兴市"
                  }
                ]
              },
              {
                "city": "金华市",
                "areas": [
                  {
                    "area": "武义县"
                  },
                  {
                    "area": "浦江县"
                  },
                  {
                    "area": "磐安县"
                  },
                  {
                    "area": "兰溪市"
                  },
                  {
                    "area": "义乌市"
                  },
                  {
                    "area": "东阳市"
                  },
                  {
                    "area": "永康市"
                  },
                  {
                    "area": "金华市"
                  }
                ]
              },
              {
                "city": "衢州市",
                "areas": [
                  {
                    "area": "常山县"
                  },
                  {
                    "area": "开化县"
                  },
                  {
                    "area": "龙游县"
                  },
                  {
                    "area": "江山市"
                  },
                  {
                    "area": "衢州市"
                  }
                ]
              },
              {
                "city": "舟山市",
                "areas": [
                  {
                    "area": "岱山县"
                  },
                  {
                    "area": "嵊泗县"
                  },
                  {
                    "area": "舟山市"
                  }
                ]
              },
              {
                "city": "台州市",
                "areas": [
                  {
                    "area": "三门县"
                  },
                  {
                    "area": "天台县"
                  },
                  {
                    "area": "仙居县"
                  },
                  {
                    "area": "温岭市"
                  },
                  {
                    "area": "临海市"
                  },
                  {
                    "area": "玉环市"
                  },
                  {
                    "area": "台州市"
                  }
                ]
              },
              {
                "city": "丽水市",
                "areas": [
                  {
                    "area": "青田县"
                  },
                  {
                    "area": "缙云县"
                  },
                  {
                    "area": "遂昌县"
                  },
                  {
                    "area": "松阳县"
                  },
                  {
                    "area": "云和县"
                  },
                  {
                    "area": "庆元县"
                  },
                  {
                    "area": "景宁畲族自治县"
                  },
                  {
                    "area": "龙泉市"
                  },
                  {
                    "area": "丽水市"
                  }
                ]
              }
            ]
          },
          {
            "province": "安徽省",
            "citys": [
              {
                "city": "合肥市",
                "areas": [
                  {
                    "area": "长丰县"
                  },
                  {
                    "area": "肥东县"
                  },
                  {
                    "area": "肥西县"
                  },
                  {
                    "area": "庐江县"
                  },
                  {
                    "area": "巢湖市"
                  },
                  {
                    "area": "合肥市"
                  }
                ]
              },
              {
                "city": "芜湖市",
                "areas": [
                  {
                    "area": "南陵县"
                  },
                  {
                    "area": "无为市"
                  },
                  {
                    "area": "芜湖市"
                  }
                ]
              },
              {
                "city": "蚌埠市",
                "areas": [
                  {
                    "area": "怀远县"
                  },
                  {
                    "area": "五河县"
                  },
                  {
                    "area": "固镇县"
                  },
                  {
                    "area": "蚌埠市"
                  }
                ]
              },
              {
                "city": "淮南市",
                "areas": [
                  {
                    "area": "凤台县"
                  },
                  {
                    "area": "寿县"
                  },
                  {
                    "area": "淮南市"
                  }
                ]
              },
              {
                "city": "马鞍山市",
                "areas": [
                  {
                    "area": "当涂县"
                  },
                  {
                    "area": "含山县"
                  },
                  {
                    "area": "和县"
                  },
                  {
                    "area": "马鞍山市"
                  }
                ]
              },
              {
                "city": "淮北市",
                "areas": [
                  {
                    "area": "濉溪县"
                  },
                  {
                    "area": "淮北市"
                  }
                ]
              },
              {
                "city": "铜陵市",
                "areas": [
                  {
                    "area": "枞阳县"
                  },
                  {
                    "area": "铜陵市"
                  }
                ]
              },
              {
                "city": "安庆市",
                "areas": [
                  {
                    "area": "怀宁县"
                  },
                  {
                    "area": "太湖县"
                  },
                  {
                    "area": "宿松县"
                  },
                  {
                    "area": "望江县"
                  },
                  {
                    "area": "岳西县"
                  },
                  {
                    "area": "桐城市"
                  },
                  {
                    "area": "潜山市"
                  },
                  {
                    "area": "安庆市"
                  }
                ]
              },
              {
                "city": "黄山市",
                "areas": [
                  {
                    "area": "歙县"
                  },
                  {
                    "area": "休宁县"
                  },
                  {
                    "area": "黟县"
                  },
                  {
                    "area": "祁门县"
                  },
                  {
                    "area": "黄山市"
                  }
                ]
              },
              {
                "city": "滁州市",
                "areas": [
                  {
                    "area": "来安县"
                  },
                  {
                    "area": "全椒县"
                  },
                  {
                    "area": "定远县"
                  },
                  {
                    "area": "凤阳县"
                  },
                  {
                    "area": "天长市"
                  },
                  {
                    "area": "明光市"
                  },
                  {
                    "area": "滁州市"
                  }
                ]
              },
              {
                "city": "阜阳市",
                "areas": [
                  {
                    "area": "临泉县"
                  },
                  {
                    "area": "太和县"
                  },
                  {
                    "area": "阜南县"
                  },
                  {
                    "area": "颍上县"
                  },
                  {
                    "area": "界首市"
                  },
                  {
                    "area": "阜阳市"
                  }
                ]
              },
              {
                "city": "宿州市",
                "areas": [
                  {
                    "area": "砀山县"
                  },
                  {
                    "area": "萧县"
                  },
                  {
                    "area": "灵璧县"
                  },
                  {
                    "area": "泗县"
                  },
                  {
                    "area": "宿州市"
                  }
                ]
              },
              {
                "city": "六安市",
                "areas": [
                  {
                    "area": "霍邱县"
                  },
                  {
                    "area": "舒城县"
                  },
                  {
                    "area": "金寨县"
                  },
                  {
                    "area": "霍山县"
                  },
                  {
                    "area": "六安市"
                  }
                ]
              },
              {
                "city": "亳州市",
                "areas": [
                  {
                    "area": "涡阳县"
                  },
                  {
                    "area": "蒙城县"
                  },
                  {
                    "area": "利辛县"
                  },
                  {
                    "area": "亳州市"
                  }
                ]
              },
              {
                "city": "池州市",
                "areas": [
                  {
                    "area": "东至县"
                  },
                  {
                    "area": "石台县"
                  },
                  {
                    "area": "青阳县"
                  },
                  {
                    "area": "池州市"
                  }
                ]
              },
              {
                "city": "宣城市",
                "areas": [
                  {
                    "area": "郎溪县"
                  },
                  {
                    "area": "泾县"
                  },
                  {
                    "area": "绩溪县"
                  },
                  {
                    "area": "旌德县"
                  },
                  {
                    "area": "宁国市"
                  },
                  {
                    "area": "广德市"
                  },
                  {
                    "area": "宣城市"
                  }
                ]
              }
            ]
          },
          {
            "province": "福建省",
            "citys": [
              {
                "city": "福州市",
                "areas": [
                  {
                    "area": "闽侯县"
                  },
                  {
                    "area": "连江县"
                  },
                  {
                    "area": "罗源县"
                  },
                  {
                    "area": "闽清县"
                  },
                  {
                    "area": "永泰县"
                  },
                  {
                    "area": "平潭县"
                  },
                  {
                    "area": "福清市"
                  },
                  {
                    "area": "福州市"
                  }
                ]
              },
              {
                "city": "厦门市",
                "areas": [
                  {
                    "area": "厦门市"
                  }
                ]
              },
              {
                "city": "莆田市",
                "areas": [
                  {
                    "area": "仙游县"
                  },
                  {
                    "area": "莆田市"
                  }
                ]
              },
              {
                "city": "三明市",
                "areas": [
                  {
                    "area": "明溪县"
                  },
                  {
                    "area": "清流县"
                  },
                  {
                    "area": "宁化县"
                  },
                  {
                    "area": "大田县"
                  },
                  {
                    "area": "尤溪县"
                  },
                  {
                    "area": "将乐县"
                  },
                  {
                    "area": "泰宁县"
                  },
                  {
                    "area": "建宁县"
                  },
                  {
                    "area": "永安市"
                  },
                  {
                    "area": "三明市"
                  }
                ]
              },
              {
                "city": "泉州市",
                "areas": [
                  {
                    "area": "惠安县"
                  },
                  {
                    "area": "安溪县"
                  },
                  {
                    "area": "永春县"
                  },
                  {
                    "area": "德化县"
                  },
                  {
                    "area": "金门县"
                  },
                  {
                    "area": "石狮市"
                  },
                  {
                    "area": "晋江市"
                  },
                  {
                    "area": "南安市"
                  },
                  {
                    "area": "泉州市"
                  }
                ]
              },
              {
                "city": "漳州市",
                "areas": [
                  {
                    "area": "云霄县"
                  },
                  {
                    "area": "漳浦县"
                  },
                  {
                    "area": "诏安县"
                  },
                  {
                    "area": "东山县"
                  },
                  {
                    "area": "南靖县"
                  },
                  {
                    "area": "平和县"
                  },
                  {
                    "area": "华安县"
                  },
                  {
                    "area": "漳州市"
                  }
                ]
              },
              {
                "city": "南平市",
                "areas": [
                  {
                    "area": "顺昌县"
                  },
                  {
                    "area": "浦城县"
                  },
                  {
                    "area": "光泽县"
                  },
                  {
                    "area": "松溪县"
                  },
                  {
                    "area": "政和县"
                  },
                  {
                    "area": "邵武市"
                  },
                  {
                    "area": "武夷山市"
                  },
                  {
                    "area": "建瓯市"
                  },
                  {
                    "area": "南平市"
                  }
                ]
              },
              {
                "city": "龙岩市",
                "areas": [
                  {
                    "area": "长汀县"
                  },
                  {
                    "area": "上杭县"
                  },
                  {
                    "area": "武平县"
                  },
                  {
                    "area": "连城县"
                  },
                  {
                    "area": "漳平市"
                  },
                  {
                    "area": "龙岩市"
                  }
                ]
              },
              {
                "city": "宁德市",
                "areas": [
                  {
                    "area": "霞浦县"
                  },
                  {
                    "area": "古田县"
                  },
                  {
                    "area": "屏南县"
                  },
                  {
                    "area": "寿宁县"
                  },
                  {
                    "area": "周宁县"
                  },
                  {
                    "area": "柘荣县"
                  },
                  {
                    "area": "福安市"
                  },
                  {
                    "area": "福鼎市"
                  },
                  {
                    "area": "宁德市"
                  }
                ]
              }
            ]
          },
          {
            "province": "江西省",
            "citys": [
              {
                "city": "南昌市",
                "areas": [
                  {
                    "area": "南昌县"
                  },
                  {
                    "area": "安义县"
                  },
                  {
                    "area": "进贤县"
                  },
                  {
                    "area": "南昌市"
                  }
                ]
              },
              {
                "city": "景德镇市",
                "areas": [
                  {
                    "area": "浮梁县"
                  },
                  {
                    "area": "乐平市"
                  },
                  {
                    "area": "景德镇市"
                  }
                ]
              },
              {
                "city": "萍乡市",
                "areas": [
                  {
                    "area": "莲花县"
                  },
                  {
                    "area": "上栗县"
                  },
                  {
                    "area": "芦溪县"
                  },
                  {
                    "area": "萍乡市"
                  }
                ]
              },
              {
                "city": "九江市",
                "areas": [
                  {
                    "area": "武宁县"
                  },
                  {
                    "area": "修水县"
                  },
                  {
                    "area": "永修县"
                  },
                  {
                    "area": "德安县"
                  },
                  {
                    "area": "都昌县"
                  },
                  {
                    "area": "湖口县"
                  },
                  {
                    "area": "彭泽县"
                  },
                  {
                    "area": "瑞昌市"
                  },
                  {
                    "area": "共青城市"
                  },
                  {
                    "area": "庐山市"
                  },
                  {
                    "area": "九江市"
                  }
                ]
              },
              {
                "city": "新余市",
                "areas": [
                  {
                    "area": "分宜县"
                  },
                  {
                    "area": "新余市"
                  }
                ]
              },
              {
                "city": "鹰潭市",
                "areas": [
                  {
                    "area": "贵溪市"
                  },
                  {
                    "area": "鹰潭市"
                  }
                ]
              },
              {
                "city": "赣州市",
                "areas": [
                  {
                    "area": "信丰县"
                  },
                  {
                    "area": "大余县"
                  },
                  {
                    "area": "上犹县"
                  },
                  {
                    "area": "崇义县"
                  },
                  {
                    "area": "安远县"
                  },
                  {
                    "area": "定南县"
                  },
                  {
                    "area": "全南县"
                  },
                  {
                    "area": "宁都县"
                  },
                  {
                    "area": "于都县"
                  },
                  {
                    "area": "兴国县"
                  },
                  {
                    "area": "会昌县"
                  },
                  {
                    "area": "寻乌县"
                  },
                  {
                    "area": "石城县"
                  },
                  {
                    "area": "瑞金市"
                  },
                  {
                    "area": "龙南市"
                  },
                  {
                    "area": "赣州市"
                  }
                ]
              },
              {
                "city": "吉安市",
                "areas": [
                  {
                    "area": "吉安县"
                  },
                  {
                    "area": "吉水县"
                  },
                  {
                    "area": "峡江县"
                  },
                  {
                    "area": "新干县"
                  },
                  {
                    "area": "永丰县"
                  },
                  {
                    "area": "泰和县"
                  },
                  {
                    "area": "遂川县"
                  },
                  {
                    "area": "万安县"
                  },
                  {
                    "area": "安福县"
                  },
                  {
                    "area": "永新县"
                  },
                  {
                    "area": "井冈山市"
                  },
                  {
                    "area": "吉安市"
                  }
                ]
              },
              {
                "city": "宜春市",
                "areas": [
                  {
                    "area": "奉新县"
                  },
                  {
                    "area": "万载县"
                  },
                  {
                    "area": "上高县"
                  },
                  {
                    "area": "宜丰县"
                  },
                  {
                    "area": "靖安县"
                  },
                  {
                    "area": "铜鼓县"
                  },
                  {
                    "area": "丰城市"
                  },
                  {
                    "area": "樟树市"
                  },
                  {
                    "area": "高安市"
                  },
                  {
                    "area": "宜春市"
                  }
                ]
              },
              {
                "city": "抚州市",
                "areas": [
                  {
                    "area": "南城县"
                  },
                  {
                    "area": "黎川县"
                  },
                  {
                    "area": "南丰县"
                  },
                  {
                    "area": "崇仁县"
                  },
                  {
                    "area": "乐安县"
                  },
                  {
                    "area": "宜黄县"
                  },
                  {
                    "area": "金溪县"
                  },
                  {
                    "area": "资溪县"
                  },
                  {
                    "area": "广昌县"
                  },
                  {
                    "area": "抚州市"
                  }
                ]
              },
              {
                "city": "上饶市",
                "areas": [
                  {
                    "area": "玉山县"
                  },
                  {
                    "area": "铅山县"
                  },
                  {
                    "area": "横峰县"
                  },
                  {
                    "area": "弋阳县"
                  },
                  {
                    "area": "余干县"
                  },
                  {
                    "area": "鄱阳县"
                  },
                  {
                    "area": "万年县"
                  },
                  {
                    "area": "婺源县"
                  },
                  {
                    "area": "德兴市"
                  },
                  {
                    "area": "上饶市"
                  }
                ]
              }
            ]
          },
          {
            "province": "山东省",
            "citys": [
              {
                "city": "济南市",
                "areas": [
                  {
                    "area": "平阴县"
                  },
                  {
                    "area": "商河县"
                  },
                  {
                    "area": "济南市"
                  }
                ]
              },
              {
                "city": "青岛市",
                "areas": [
                  {
                    "area": "胶州市"
                  },
                  {
                    "area": "平度市"
                  },
                  {
                    "area": "莱西市"
                  },
                  {
                    "area": "青岛市"
                  }
                ]
              },
              {
                "city": "淄博市",
                "areas": [
                  {
                    "area": "桓台县"
                  },
                  {
                    "area": "高青县"
                  },
                  {
                    "area": "沂源县"
                  },
                  {
                    "area": "淄博市"
                  }
                ]
              },
              {
                "city": "枣庄市",
                "areas": [
                  {
                    "area": "滕州市"
                  },
                  {
                    "area": "枣庄市"
                  }
                ]
              },
              {
                "city": "东营市",
                "areas": [
                  {
                    "area": "利津县"
                  },
                  {
                    "area": "广饶县"
                  },
                  {
                    "area": "东营市"
                  }
                ]
              },
              {
                "city": "烟台市",
                "areas": [
                  {
                    "area": "龙口市"
                  },
                  {
                    "area": "莱阳市"
                  },
                  {
                    "area": "莱州市"
                  },
                  {
                    "area": "招远市"
                  },
                  {
                    "area": "栖霞市"
                  },
                  {
                    "area": "海阳市"
                  },
                  {
                    "area": "烟台市"
                  }
                ]
              },
              {
                "city": "潍坊市",
                "areas": [
                  {
                    "area": "临朐县"
                  },
                  {
                    "area": "昌乐县"
                  },
                  {
                    "area": "青州市"
                  },
                  {
                    "area": "诸城市"
                  },
                  {
                    "area": "寿光市"
                  },
                  {
                    "area": "安丘市"
                  },
                  {
                    "area": "高密市"
                  },
                  {
                    "area": "昌邑市"
                  },
                  {
                    "area": "潍坊市"
                  }
                ]
              },
              {
                "city": "济宁市",
                "areas": [
                  {
                    "area": "微山县"
                  },
                  {
                    "area": "鱼台县"
                  },
                  {
                    "area": "金乡县"
                  },
                  {
                    "area": "嘉祥县"
                  },
                  {
                    "area": "汶上县"
                  },
                  {
                    "area": "泗水县"
                  },
                  {
                    "area": "梁山县"
                  },
                  {
                    "area": "曲阜市"
                  },
                  {
                    "area": "邹城市"
                  },
                  {
                    "area": "济宁市"
                  }
                ]
              },
              {
                "city": "泰安市",
                "areas": [
                  {
                    "area": "宁阳县"
                  },
                  {
                    "area": "东平县"
                  },
                  {
                    "area": "新泰市"
                  },
                  {
                    "area": "肥城市"
                  },
                  {
                    "area": "泰安市"
                  }
                ]
              },
              {
                "city": "威海市",
                "areas": [
                  {
                    "area": "荣成市"
                  },
                  {
                    "area": "乳山市"
                  },
                  {
                    "area": "威海市"
                  }
                ]
              },
              {
                "city": "日照市",
                "areas": [
                  {
                    "area": "五莲县"
                  },
                  {
                    "area": "莒县"
                  },
                  {
                    "area": "日照市"
                  }
                ]
              },
              {
                "city": "临沂市",
                "areas": [
                  {
                    "area": "沂南县"
                  },
                  {
                    "area": "郯城县"
                  },
                  {
                    "area": "沂水县"
                  },
                  {
                    "area": "兰陵县"
                  },
                  {
                    "area": "费县"
                  },
                  {
                    "area": "平邑县"
                  },
                  {
                    "area": "莒南县"
                  },
                  {
                    "area": "蒙阴县"
                  },
                  {
                    "area": "临沭县"
                  },
                  {
                    "area": "临沂市"
                  }
                ]
              },
              {
                "city": "德州市",
                "areas": [
                  {
                    "area": "宁津县"
                  },
                  {
                    "area": "庆云县"
                  },
                  {
                    "area": "临邑县"
                  },
                  {
                    "area": "齐河县"
                  },
                  {
                    "area": "平原县"
                  },
                  {
                    "area": "夏津县"
                  },
                  {
                    "area": "武城县"
                  },
                  {
                    "area": "乐陵市"
                  },
                  {
                    "area": "禹城市"
                  },
                  {
                    "area": "德州市"
                  }
                ]
              },
              {
                "city": "聊城市",
                "areas": [
                  {
                    "area": "阳谷县"
                  },
                  {
                    "area": "莘县"
                  },
                  {
                    "area": "东阿县"
                  },
                  {
                    "area": "冠县"
                  },
                  {
                    "area": "高唐县"
                  },
                  {
                    "area": "临清市"
                  },
                  {
                    "area": "聊城市"
                  }
                ]
              },
              {
                "city": "滨州市",
                "areas": [
                  {
                    "area": "惠民县"
                  },
                  {
                    "area": "阳信县"
                  },
                  {
                    "area": "无棣县"
                  },
                  {
                    "area": "博兴县"
                  },
                  {
                    "area": "邹平市"
                  },
                  {
                    "area": "滨州市"
                  }
                ]
              },
              {
                "city": "菏泽市",
                "areas": [
                  {
                    "area": "曹县"
                  },
                  {
                    "area": "单县"
                  },
                  {
                    "area": "成武县"
                  },
                  {
                    "area": "巨野县"
                  },
                  {
                    "area": "郓城县"
                  },
                  {
                    "area": "鄄城县"
                  },
                  {
                    "area": "东明县"
                  },
                  {
                    "area": "菏泽市"
                  }
                ]
              }
            ]
          },
          {
            "province": "河南省",
            "citys": [
              {
                "city": "郑州市",
                "areas": [
                  {
                    "area": "中牟县"
                  },
                  {
                    "area": "巩义市"
                  },
                  {
                    "area": "荥阳市"
                  },
                  {
                    "area": "新密市"
                  },
                  {
                    "area": "新郑市"
                  },
                  {
                    "area": "登封市"
                  },
                  {
                    "area": "郑州市"
                  }
                ]
              },
              {
                "city": "开封市",
                "areas": [
                  {
                    "area": "杞县"
                  },
                  {
                    "area": "通许县"
                  },
                  {
                    "area": "尉氏县"
                  },
                  {
                    "area": "兰考县"
                  },
                  {
                    "area": "开封市"
                  }
                ]
              },
              {
                "city": "洛阳市",
                "areas": [
                  {
                    "area": "新安县"
                  },
                  {
                    "area": "栾川县"
                  },
                  {
                    "area": "嵩县"
                  },
                  {
                    "area": "汝阳县"
                  },
                  {
                    "area": "宜阳县"
                  },
                  {
                    "area": "洛宁县"
                  },
                  {
                    "area": "伊川县"
                  },
                  {
                    "area": "洛阳市"
                  }
                ]
              },
              {
                "city": "平顶山市",
                "areas": [
                  {
                    "area": "宝丰县"
                  },
                  {
                    "area": "叶县"
                  },
                  {
                    "area": "鲁山县"
                  },
                  {
                    "area": "郏县"
                  },
                  {
                    "area": "舞钢市"
                  },
                  {
                    "area": "汝州市"
                  },
                  {
                    "area": "平顶山市"
                  }
                ]
              },
              {
                "city": "安阳市",
                "areas": [
                  {
                    "area": "安阳县"
                  },
                  {
                    "area": "汤阴县"
                  },
                  {
                    "area": "滑县"
                  },
                  {
                    "area": "内黄县"
                  },
                  {
                    "area": "林州市"
                  },
                  {
                    "area": "安阳市"
                  }
                ]
              },
              {
                "city": "鹤壁市",
                "areas": [
                  {
                    "area": "浚县"
                  },
                  {
                    "area": "淇县"
                  },
                  {
                    "area": "鹤壁市"
                  }
                ]
              },
              {
                "city": "新乡市",
                "areas": [
                  {
                    "area": "新乡县"
                  },
                  {
                    "area": "获嘉县"
                  },
                  {
                    "area": "原阳县"
                  },
                  {
                    "area": "延津县"
                  },
                  {
                    "area": "封丘县"
                  },
                  {
                    "area": "卫辉市"
                  },
                  {
                    "area": "辉县市"
                  },
                  {
                    "area": "长垣市"
                  },
                  {
                    "area": "新乡市"
                  }
                ]
              },
              {
                "city": "焦作市",
                "areas": [
                  {
                    "area": "修武县"
                  },
                  {
                    "area": "博爱县"
                  },
                  {
                    "area": "武陟县"
                  },
                  {
                    "area": "温县"
                  },
                  {
                    "area": "沁阳市"
                  },
                  {
                    "area": "孟州市"
                  },
                  {
                    "area": "焦作市"
                  }
                ]
              },
              {
                "city": "濮阳市",
                "areas": [
                  {
                    "area": "清丰县"
                  },
                  {
                    "area": "南乐县"
                  },
                  {
                    "area": "范县"
                  },
                  {
                    "area": "台前县"
                  },
                  {
                    "area": "濮阳县"
                  },
                  {
                    "area": "濮阳市"
                  }
                ]
              },
              {
                "city": "许昌市",
                "areas": [
                  {
                    "area": "鄢陵县"
                  },
                  {
                    "area": "襄城县"
                  },
                  {
                    "area": "禹州市"
                  },
                  {
                    "area": "长葛市"
                  },
                  {
                    "area": "许昌市"
                  }
                ]
              },
              {
                "city": "漯河市",
                "areas": [
                  {
                    "area": "舞阳县"
                  },
                  {
                    "area": "临颍县"
                  },
                  {
                    "area": "漯河市"
                  }
                ]
              },
              {
                "city": "三门峡市",
                "areas": [
                  {
                    "area": "渑池县"
                  },
                  {
                    "area": "卢氏县"
                  },
                  {
                    "area": "义马市"
                  },
                  {
                    "area": "灵宝市"
                  },
                  {
                    "area": "三门峡市"
                  }
                ]
              },
              {
                "city": "南阳市",
                "areas": [
                  {
                    "area": "南召县"
                  },
                  {
                    "area": "方城县"
                  },
                  {
                    "area": "西峡县"
                  },
                  {
                    "area": "镇平县"
                  },
                  {
                    "area": "内乡县"
                  },
                  {
                    "area": "淅川县"
                  },
                  {
                    "area": "社旗县"
                  },
                  {
                    "area": "唐河县"
                  },
                  {
                    "area": "新野县"
                  },
                  {
                    "area": "桐柏县"
                  },
                  {
                    "area": "邓州市"
                  },
                  {
                    "area": "南阳市"
                  }
                ]
              },
              {
                "city": "商丘市",
                "areas": [
                  {
                    "area": "民权县"
                  },
                  {
                    "area": "睢县"
                  },
                  {
                    "area": "宁陵县"
                  },
                  {
                    "area": "柘城县"
                  },
                  {
                    "area": "虞城县"
                  },
                  {
                    "area": "夏邑县"
                  },
                  {
                    "area": "永城市"
                  },
                  {
                    "area": "商丘市"
                  }
                ]
              },
              {
                "city": "信阳市",
                "areas": [
                  {
                    "area": "罗山县"
                  },
                  {
                    "area": "光山县"
                  },
                  {
                    "area": "新县"
                  },
                  {
                    "area": "商城县"
                  },
                  {
                    "area": "固始县"
                  },
                  {
                    "area": "潢川县"
                  },
                  {
                    "area": "淮滨县"
                  },
                  {
                    "area": "息县"
                  },
                  {
                    "area": "信阳市"
                  }
                ]
              },
              {
                "city": "周口市",
                "areas": [
                  {
                    "area": "扶沟县"
                  },
                  {
                    "area": "西华县"
                  },
                  {
                    "area": "商水县"
                  },
                  {
                    "area": "沈丘县"
                  },
                  {
                    "area": "郸城县"
                  },
                  {
                    "area": "太康县"
                  },
                  {
                    "area": "鹿邑县"
                  },
                  {
                    "area": "项城市"
                  },
                  {
                    "area": "周口市"
                  }
                ]
              },
              {
                "city": "驻马店市",
                "areas": [
                  {
                    "area": "西平县"
                  },
                  {
                    "area": "上蔡县"
                  },
                  {
                    "area": "平舆县"
                  },
                  {
                    "area": "正阳县"
                  },
                  {
                    "area": "确山县"
                  },
                  {
                    "area": "泌阳县"
                  },
                  {
                    "area": "汝南县"
                  },
                  {
                    "area": "遂平县"
                  },
                  {
                    "area": "新蔡县"
                  },
                  {
                    "area": "济源市"
                  },
                  {
                    "area": "驻马店市"
                  }
                ]
              }
            ]
          },
          {
            "province": "湖北省",
            "citys": [
              {
                "city": "武汉市",
                "areas": [
                  {
                    "area": "武汉市"
                  }
                ]
              },
              {
                "city": "黄石市",
                "areas": [
                  {
                    "area": "阳新县"
                  },
                  {
                    "area": "大冶市"
                  },
                  {
                    "area": "黄石市"
                  }
                ]
              },
              {
                "city": "十堰市",
                "areas": [
                  {
                    "area": "郧西县"
                  },
                  {
                    "area": "竹山县"
                  },
                  {
                    "area": "竹溪县"
                  },
                  {
                    "area": "房县"
                  },
                  {
                    "area": "丹江口市"
                  },
                  {
                    "area": "十堰市"
                  }
                ]
              },
              {
                "city": "宜昌市",
                "areas": [
                  {
                    "area": "远安县"
                  },
                  {
                    "area": "兴山县"
                  },
                  {
                    "area": "秭归县"
                  },
                  {
                    "area": "长阳土家族自治县"
                  },
                  {
                    "area": "五峰土家族自治县"
                  },
                  {
                    "area": "宜都市"
                  },
                  {
                    "area": "当阳市"
                  },
                  {
                    "area": "枝江市"
                  },
                  {
                    "area": "宜昌市"
                  }
                ]
              },
              {
                "city": "襄阳市",
                "areas": [
                  {
                    "area": "南漳县"
                  },
                  {
                    "area": "谷城县"
                  },
                  {
                    "area": "保康县"
                  },
                  {
                    "area": "老河口市"
                  },
                  {
                    "area": "枣阳市"
                  },
                  {
                    "area": "宜城市"
                  },
                  {
                    "area": "襄阳市"
                  }
                ]
              },
              {
                "city": "鄂州市",
                "areas": [
                  {
                    "area": "鄂州市"
                  }
                ]
              },
              {
                "city": "荆门市",
                "areas": [
                  {
                    "area": "沙洋县"
                  },
                  {
                    "area": "钟祥市"
                  },
                  {
                    "area": "京山市"
                  },
                  {
                    "area": "荆门市"
                  }
                ]
              },
              {
                "city": "孝感市",
                "areas": [
                  {
                    "area": "孝昌县"
                  },
                  {
                    "area": "大悟县"
                  },
                  {
                    "area": "云梦县"
                  },
                  {
                    "area": "应城市"
                  },
                  {
                    "area": "安陆市"
                  },
                  {
                    "area": "汉川市"
                  },
                  {
                    "area": "孝感市"
                  }
                ]
              },
              {
                "city": "荆州市",
                "areas": [
                  {
                    "area": "公安县"
                  },
                  {
                    "area": "江陵县"
                  },
                  {
                    "area": "石首市"
                  },
                  {
                    "area": "洪湖市"
                  },
                  {
                    "area": "松滋市"
                  },
                  {
                    "area": "监利市"
                  },
                  {
                    "area": "荆州市"
                  }
                ]
              },
              {
                "city": "黄冈市",
                "areas": [
                  {
                    "area": "团风县"
                  },
                  {
                    "area": "红安县"
                  },
                  {
                    "area": "罗田县"
                  },
                  {
                    "area": "英山县"
                  },
                  {
                    "area": "浠水县"
                  },
                  {
                    "area": "蕲春县"
                  },
                  {
                    "area": "黄梅县"
                  },
                  {
                    "area": "麻城市"
                  },
                  {
                    "area": "武穴市"
                  },
                  {
                    "area": "黄冈市"
                  }
                ]
              },
              {
                "city": "咸宁市",
                "areas": [
                  {
                    "area": "嘉鱼县"
                  },
                  {
                    "area": "通城县"
                  },
                  {
                    "area": "崇阳县"
                  },
                  {
                    "area": "通山县"
                  },
                  {
                    "area": "赤壁市"
                  },
                  {
                    "area": "咸宁市"
                  }
                ]
              },
              {
                "city": "随州市",
                "areas": [
                  {
                    "area": "随县"
                  },
                  {
                    "area": "广水市"
                  },
                  {
                    "area": "随州市"
                  }
                ]
              },
              {
                "city": "恩施土家族苗族自治州",
                "areas": [
                  {
                    "area": "恩施市"
                  },
                  {
                    "area": "利川市"
                  },
                  {
                    "area": "建始县"
                  },
                  {
                    "area": "巴东县"
                  },
                  {
                    "area": "宣恩县"
                  },
                  {
                    "area": "咸丰县"
                  },
                  {
                    "area": "来凤县"
                  },
                  {
                    "area": "鹤峰县"
                  },
                  {
                    "area": "仙桃市"
                  },
                  {
                    "area": "潜江市"
                  },
                  {
                    "area": "天门市"
                  }
                ]
              }
            ]
          },
          {
            "province": "湖南省",
            "citys": [
              {
                "city": "长沙市",
                "areas": [
                  {
                    "area": "长沙县"
                  },
                  {
                    "area": "浏阳市"
                  },
                  {
                    "area": "宁乡市"
                  },
                  {
                    "area": "长沙市"
                  }
                ]
              },
              {
                "city": "株洲市",
                "areas": [
                  {
                    "area": "攸县"
                  },
                  {
                    "area": "茶陵县"
                  },
                  {
                    "area": "炎陵县"
                  },
                  {
                    "area": "醴陵市"
                  },
                  {
                    "area": "株洲市"
                  }
                ]
              },
              {
                "city": "湘潭市",
                "areas": [
                  {
                    "area": "湘潭县"
                  },
                  {
                    "area": "湘乡市"
                  },
                  {
                    "area": "韶山市"
                  },
                  {
                    "area": "湘潭市"
                  }
                ]
              },
              {
                "city": "衡阳市",
                "areas": [
                  {
                    "area": "衡阳县"
                  },
                  {
                    "area": "衡南县"
                  },
                  {
                    "area": "衡山县"
                  },
                  {
                    "area": "衡东县"
                  },
                  {
                    "area": "祁东县"
                  },
                  {
                    "area": "耒阳市"
                  },
                  {
                    "area": "常宁市"
                  },
                  {
                    "area": "衡阳市"
                  }
                ]
              },
              {
                "city": "邵阳市",
                "areas": [
                  {
                    "area": "新邵县"
                  },
                  {
                    "area": "邵阳县"
                  },
                  {
                    "area": "隆回县"
                  },
                  {
                    "area": "洞口县"
                  },
                  {
                    "area": "绥宁县"
                  },
                  {
                    "area": "新宁县"
                  },
                  {
                    "area": "城步苗族自治县"
                  },
                  {
                    "area": "武冈市"
                  },
                  {
                    "area": "邵东市"
                  },
                  {
                    "area": "邵阳市"
                  }
                ]
              },
              {
                "city": "岳阳市",
                "areas": [
                  {
                    "area": "岳阳县"
                  },
                  {
                    "area": "华容县"
                  },
                  {
                    "area": "湘阴县"
                  },
                  {
                    "area": "平江县"
                  },
                  {
                    "area": "汨罗市"
                  },
                  {
                    "area": "临湘市"
                  },
                  {
                    "area": "岳阳市"
                  }
                ]
              },
              {
                "city": "常德市",
                "areas": [
                  {
                    "area": "安乡县"
                  },
                  {
                    "area": "汉寿县"
                  },
                  {
                    "area": "澧县"
                  },
                  {
                    "area": "临澧县"
                  },
                  {
                    "area": "桃源县"
                  },
                  {
                    "area": "石门县"
                  },
                  {
                    "area": "津市市"
                  },
                  {
                    "area": "常德市"
                  }
                ]
              },
              {
                "city": "张家界市",
                "areas": [
                  {
                    "area": "慈利县"
                  },
                  {
                    "area": "桑植县"
                  },
                  {
                    "area": "张家界市"
                  }
                ]
              },
              {
                "city": "益阳市",
                "areas": [
                  {
                    "area": "南县"
                  },
                  {
                    "area": "桃江县"
                  },
                  {
                    "area": "安化县"
                  },
                  {
                    "area": "沅江市"
                  },
                  {
                    "area": "益阳市"
                  }
                ]
              },
              {
                "city": "郴州市",
                "areas": [
                  {
                    "area": "桂阳县"
                  },
                  {
                    "area": "宜章县"
                  },
                  {
                    "area": "永兴县"
                  },
                  {
                    "area": "嘉禾县"
                  },
                  {
                    "area": "临武县"
                  },
                  {
                    "area": "汝城县"
                  },
                  {
                    "area": "桂东县"
                  },
                  {
                    "area": "安仁县"
                  },
                  {
                    "area": "资兴市"
                  },
                  {
                    "area": "郴州市"
                  }
                ]
              },
              {
                "city": "永州市",
                "areas": [
                  {
                    "area": "东安县"
                  },
                  {
                    "area": "双牌县"
                  },
                  {
                    "area": "道县"
                  },
                  {
                    "area": "江永县"
                  },
                  {
                    "area": "宁远县"
                  },
                  {
                    "area": "蓝山县"
                  },
                  {
                    "area": "新田县"
                  },
                  {
                    "area": "江华瑶族自治县"
                  },
                  {
                    "area": "祁阳市"
                  },
                  {
                    "area": "永州市"
                  }
                ]
              },
              {
                "city": "怀化市",
                "areas": [
                  {
                    "area": "中方县"
                  },
                  {
                    "area": "沅陵县"
                  },
                  {
                    "area": "辰溪县"
                  },
                  {
                    "area": "溆浦县"
                  },
                  {
                    "area": "会同县"
                  },
                  {
                    "area": "麻阳苗族自治县"
                  },
                  {
                    "area": "新晃侗族自治县"
                  },
                  {
                    "area": "芷江侗族自治县"
                  },
                  {
                    "area": "靖州苗族侗族自治县"
                  },
                  {
                    "area": "通道侗族自治县"
                  },
                  {
                    "area": "洪江市"
                  },
                  {
                    "area": "怀化市"
                  }
                ]
              },
              {
                "city": "娄底市",
                "areas": [
                  {
                    "area": "双峰县"
                  },
                  {
                    "area": "新化县"
                  },
                  {
                    "area": "冷水江市"
                  },
                  {
                    "area": "涟源市"
                  },
                  {
                    "area": "娄底市"
                  }
                ]
              },
              {
                "city": "湘西土家族苗族自治州",
                "areas": [
                  {
                    "area": "吉首市"
                  },
                  {
                    "area": "泸溪县"
                  },
                  {
                    "area": "凤凰县"
                  },
                  {
                    "area": "花垣县"
                  },
                  {
                    "area": "保靖县"
                  },
                  {
                    "area": "古丈县"
                  },
                  {
                    "area": "永顺县"
                  },
                  {
                    "area": "龙山县"
                  }
                ]
              }
            ]
          },
          {
            "province": "广东省",
            "citys": [
              {
                "city": "广州市",
                "areas": [
                  {
                    "area": "广州市"
                  }
                ]
              },
              {
                "city": "韶关市",
                "areas": [
                  {
                    "area": "始兴县"
                  },
                  {
                    "area": "仁化县"
                  },
                  {
                    "area": "翁源县"
                  },
                  {
                    "area": "乳源瑶族自治县"
                  },
                  {
                    "area": "新丰县"
                  },
                  {
                    "area": "乐昌市"
                  },
                  {
                    "area": "南雄市"
                  },
                  {
                    "area": "韶关市"
                  }
                ]
              },
              {
                "city": "深圳市",
                "areas": [
                  {
                    "area": "深圳市"
                  }
                ]
              },
              {
                "city": "珠海市",
                "areas": [
                  {
                    "area": "珠海市"
                  }
                ]
              },
              {
                "city": "汕头市",
                "areas": [
                  {
                    "area": "南澳县"
                  },
                  {
                    "area": "汕头市"
                  }
                ]
              },
              {
                "city": "佛山市",
                "areas": [
                  {
                    "area": "佛山市"
                  }
                ]
              },
              {
                "city": "江门市",
                "areas": [
                  {
                    "area": "台山市"
                  },
                  {
                    "area": "开平市"
                  },
                  {
                    "area": "鹤山市"
                  },
                  {
                    "area": "恩平市"
                  },
                  {
                    "area": "江门市"
                  }
                ]
              },
              {
                "city": "湛江市",
                "areas": [
                  {
                    "area": "遂溪县"
                  },
                  {
                    "area": "徐闻县"
                  },
                  {
                    "area": "廉江市"
                  },
                  {
                    "area": "雷州市"
                  },
                  {
                    "area": "吴川市"
                  },
                  {
                    "area": "湛江市"
                  }
                ]
              },
              {
                "city": "茂名市",
                "areas": [
                  {
                    "area": "高州市"
                  },
                  {
                    "area": "化州市"
                  },
                  {
                    "area": "信宜市"
                  },
                  {
                    "area": "茂名市"
                  }
                ]
              },
              {
                "city": "肇庆市",
                "areas": [
                  {
                    "area": "广宁县"
                  },
                  {
                    "area": "怀集县"
                  },
                  {
                    "area": "封开县"
                  },
                  {
                    "area": "德庆县"
                  },
                  {
                    "area": "四会市"
                  },
                  {
                    "area": "肇庆市"
                  }
                ]
              },
              {
                "city": "惠州市",
                "areas": [
                  {
                    "area": "博罗县"
                  },
                  {
                    "area": "惠东县"
                  },
                  {
                    "area": "龙门县"
                  },
                  {
                    "area": "惠州市"
                  }
                ]
              },
              {
                "city": "梅州市",
                "areas": [
                  {
                    "area": "大埔县"
                  },
                  {
                    "area": "丰顺县"
                  },
                  {
                    "area": "五华县"
                  },
                  {
                    "area": "平远县"
                  },
                  {
                    "area": "蕉岭县"
                  },
                  {
                    "area": "兴宁市"
                  },
                  {
                    "area": "梅州市"
                  }
                ]
              },
              {
                "city": "汕尾市",
                "areas": [
                  {
                    "area": "海丰县"
                  },
                  {
                    "area": "陆河县"
                  },
                  {
                    "area": "陆丰市"
                  },
                  {
                    "area": "汕尾市"
                  }
                ]
              },
              {
                "city": "河源市",
                "areas": [
                  {
                    "area": "紫金县"
                  },
                  {
                    "area": "龙川县"
                  },
                  {
                    "area": "连平县"
                  },
                  {
                    "area": "和平县"
                  },
                  {
                    "area": "东源县"
                  },
                  {
                    "area": "河源市"
                  }
                ]
              },
              {
                "city": "阳江市",
                "areas": [
                  {
                    "area": "阳西县"
                  },
                  {
                    "area": "阳春市"
                  },
                  {
                    "area": "阳江市"
                  }
                ]
              },
              {
                "city": "清远市",
                "areas": [
                  {
                    "area": "佛冈县"
                  },
                  {
                    "area": "阳山县"
                  },
                  {
                    "area": "连山壮族瑶族自治县"
                  },
                  {
                    "area": "连南瑶族自治县"
                  },
                  {
                    "area": "英德市"
                  },
                  {
                    "area": "连州市"
                  },
                  {
                    "area": "清远市"
                  }
                ]
              },
              {
                "city": "潮州市",
                "areas": [
                  {
                    "area": "饶平县"
                  },
                  {
                    "area": "潮州市"
                  }
                ]
              },
              {
                "city": "揭阳市",
                "areas": [
                  {
                    "area": "揭西县"
                  },
                  {
                    "area": "惠来县"
                  },
                  {
                    "area": "普宁市"
                  },
                  {
                    "area": "揭阳市"
                  }
                ]
              },
              {
                "city": "云浮市",
                "areas": [
                  {
                    "area": "新兴县"
                  },
                  {
                    "area": "郁南县"
                  },
                  {
                    "area": "罗定市"
                  },
                  {
                    "area": "云浮市"
                  }
                ]
              }
            ]
          },
          {
            "province": "广西壮族自治区",
            "citys": [
              {
                "city": "南宁市",
                "areas": [
                  {
                    "area": "隆安县"
                  },
                  {
                    "area": "马山县"
                  },
                  {
                    "area": "上林县"
                  },
                  {
                    "area": "宾阳县"
                  },
                  {
                    "area": "横州市"
                  },
                  {
                    "area": "南宁市"
                  }
                ]
              },
              {
                "city": "柳州市",
                "areas": [
                  {
                    "area": "柳城县"
                  },
                  {
                    "area": "鹿寨县"
                  },
                  {
                    "area": "融安县"
                  },
                  {
                    "area": "融水苗族自治县"
                  },
                  {
                    "area": "三江侗族自治县"
                  },
                  {
                    "area": "柳州市"
                  }
                ]
              },
              {
                "city": "桂林市",
                "areas": [
                  {
                    "area": "阳朔县"
                  },
                  {
                    "area": "灵川县"
                  },
                  {
                    "area": "全州县"
                  },
                  {
                    "area": "兴安县"
                  },
                  {
                    "area": "永福县"
                  },
                  {
                    "area": "灌阳县"
                  },
                  {
                    "area": "龙胜各族自治县"
                  },
                  {
                    "area": "资源县"
                  },
                  {
                    "area": "平乐县"
                  },
                  {
                    "area": "恭城瑶族自治县"
                  },
                  {
                    "area": "荔浦市"
                  },
                  {
                    "area": "桂林市"
                  }
                ]
              },
              {
                "city": "梧州市",
                "areas": [
                  {
                    "area": "苍梧县"
                  },
                  {
                    "area": "藤县"
                  },
                  {
                    "area": "蒙山县"
                  },
                  {
                    "area": "岑溪市"
                  },
                  {
                    "area": "梧州市"
                  }
                ]
              },
              {
                "city": "北海市",
                "areas": [
                  {
                    "area": "合浦县"
                  },
                  {
                    "area": "北海市"
                  }
                ]
              },
              {
                "city": "防城港市",
                "areas": [
                  {
                    "area": "上思县"
                  },
                  {
                    "area": "东兴市"
                  },
                  {
                    "area": "防城港市"
                  }
                ]
              },
              {
                "city": "钦州市",
                "areas": [
                  {
                    "area": "灵山县"
                  },
                  {
                    "area": "浦北县"
                  },
                  {
                    "area": "钦州市"
                  }
                ]
              },
              {
                "city": "贵港市",
                "areas": [
                  {
                    "area": "平南县"
                  },
                  {
                    "area": "桂平市"
                  },
                  {
                    "area": "贵港市"
                  }
                ]
              },
              {
                "city": "玉林市",
                "areas": [
                  {
                    "area": "容县"
                  },
                  {
                    "area": "陆川县"
                  },
                  {
                    "area": "博白县"
                  },
                  {
                    "area": "兴业县"
                  },
                  {
                    "area": "北流市"
                  },
                  {
                    "area": "玉林市"
                  }
                ]
              },
              {
                "city": "百色市",
                "areas": [
                  {
                    "area": "田东县"
                  },
                  {
                    "area": "德保县"
                  },
                  {
                    "area": "那坡县"
                  },
                  {
                    "area": "凌云县"
                  },
                  {
                    "area": "乐业县"
                  },
                  {
                    "area": "田林县"
                  },
                  {
                    "area": "西林县"
                  },
                  {
                    "area": "隆林各族自治县"
                  },
                  {
                    "area": "靖西市"
                  },
                  {
                    "area": "平果市"
                  },
                  {
                    "area": "百色市"
                  }
                ]
              },
              {
                "city": "贺州市",
                "areas": [
                  {
                    "area": "昭平县"
                  },
                  {
                    "area": "钟山县"
                  },
                  {
                    "area": "富川瑶族自治县"
                  },
                  {
                    "area": "贺州市"
                  }
                ]
              },
              {
                "city": "河池市",
                "areas": [
                  {
                    "area": "南丹县"
                  },
                  {
                    "area": "天峨县"
                  },
                  {
                    "area": "凤山县"
                  },
                  {
                    "area": "东兰县"
                  },
                  {
                    "area": "罗城仫佬族自治县"
                  },
                  {
                    "area": "环江毛南族自治县"
                  },
                  {
                    "area": "巴马瑶族自治县"
                  },
                  {
                    "area": "都安瑶族自治县"
                  },
                  {
                    "area": "大化瑶族自治县"
                  },
                  {
                    "area": "河池市"
                  }
                ]
              },
              {
                "city": "来宾市",
                "areas": [
                  {
                    "area": "忻城县"
                  },
                  {
                    "area": "象州县"
                  },
                  {
                    "area": "武宣县"
                  },
                  {
                    "area": "金秀瑶族自治县"
                  },
                  {
                    "area": "合山市"
                  },
                  {
                    "area": "来宾市"
                  }
                ]
              },
              {
                "city": "崇左市",
                "areas": [
                  {
                    "area": "扶绥县"
                  },
                  {
                    "area": "宁明县"
                  },
                  {
                    "area": "龙州县"
                  },
                  {
                    "area": "大新县"
                  },
                  {
                    "area": "天等县"
                  },
                  {
                    "area": "凭祥市"
                  },
                  {
                    "area": "崇左市"
                  }
                ]
              }
            ]
          },
          {
            "province": "海南省",
            "citys": [
              {
                "city": "海口市",
                "areas": [
                  {
                    "area": "海口市"
                  }
                ]
              },
              {
                "city": "三亚市",
                "areas": [
                  {
                    "area": "三亚市"
                  }
                ]
              },
              {
                "city": "儋州市",
                "areas": [
                  {
                    "area": "五指山市"
                  },
                  {
                    "area": "琼海市"
                  },
                  {
                    "area": "文昌市"
                  },
                  {
                    "area": "万宁市"
                  },
                  {
                    "area": "东方市"
                  },
                  {
                    "area": "定安县"
                  },
                  {
                    "area": "屯昌县"
                  },
                  {
                    "area": "澄迈县"
                  },
                  {
                    "area": "临高县"
                  },
                  {
                    "area": "白沙黎族自治县"
                  },
                  {
                    "area": "昌江黎族自治县"
                  },
                  {
                    "area": "乐东黎族自治县"
                  },
                  {
                    "area": "陵水黎族自治县"
                  },
                  {
                    "area": "保亭黎族苗族自治县"
                  },
                  {
                    "area": "琼中黎族苗族自治县"
                  },
                  {
                    "area": "儋州市"
                  }
                ]
              }
            ]
          },
          {
            "province": "重庆市",
            "citys": [
              {
                "city": "重庆市",
                "areas": [
                  {
                    "area": "城口县"
                  },
                  {
                    "area": "丰都县"
                  },
                  {
                    "area": "垫江县"
                  },
                  {
                    "area": "忠县"
                  },
                  {
                    "area": "云阳县"
                  },
                  {
                    "area": "奉节县"
                  },
                  {
                    "area": "巫山县"
                  },
                  {
                    "area": "巫溪县"
                  },
                  {
                    "area": "石柱土家族自治县"
                  },
                  {
                    "area": "秀山土家族苗族自治县"
                  },
                  {
                    "area": "酉阳土家族苗族自治县"
                  },
                  {
                    "area": "彭水苗族土家族自治县"
                  },
                  {
                    "area": "重庆市"
                  }
                ]
              }
            ]
          },
          {
            "province": "四川省",
            "citys": [
              {
                "city": "成都市",
                "areas": [
                  {
                    "area": "金堂县"
                  },
                  {
                    "area": "大邑县"
                  },
                  {
                    "area": "蒲江县"
                  },
                  {
                    "area": "都江堰市"
                  },
                  {
                    "area": "彭州市"
                  },
                  {
                    "area": "邛崃市"
                  },
                  {
                    "area": "崇州市"
                  },
                  {
                    "area": "简阳市"
                  },
                  {
                    "area": "成都市"
                  }
                ]
              },
              {
                "city": "自贡市",
                "areas": [
                  {
                    "area": "荣县"
                  },
                  {
                    "area": "富顺县"
                  },
                  {
                    "area": "自贡市"
                  }
                ]
              },
              {
                "city": "攀枝花市",
                "areas": [
                  {
                    "area": "米易县"
                  },
                  {
                    "area": "盐边县"
                  },
                  {
                    "area": "攀枝花市"
                  }
                ]
              },
              {
                "city": "泸州市",
                "areas": [
                  {
                    "area": "泸县"
                  },
                  {
                    "area": "合江县"
                  },
                  {
                    "area": "叙永县"
                  },
                  {
                    "area": "古蔺县"
                  },
                  {
                    "area": "泸州市"
                  }
                ]
              },
              {
                "city": "德阳市",
                "areas": [
                  {
                    "area": "中江县"
                  },
                  {
                    "area": "广汉市"
                  },
                  {
                    "area": "什邡市"
                  },
                  {
                    "area": "绵竹市"
                  },
                  {
                    "area": "德阳市"
                  }
                ]
              },
              {
                "city": "绵阳市",
                "areas": [
                  {
                    "area": "三台县"
                  },
                  {
                    "area": "盐亭县"
                  },
                  {
                    "area": "梓潼县"
                  },
                  {
                    "area": "北川羌族自治县"
                  },
                  {
                    "area": "平武县"
                  },
                  {
                    "area": "江油市"
                  },
                  {
                    "area": "绵阳市"
                  }
                ]
              },
              {
                "city": "广元市",
                "areas": [
                  {
                    "area": "旺苍县"
                  },
                  {
                    "area": "青川县"
                  },
                  {
                    "area": "剑阁县"
                  },
                  {
                    "area": "苍溪县"
                  },
                  {
                    "area": "广元市"
                  }
                ]
              },
              {
                "city": "遂宁市",
                "areas": [
                  {
                    "area": "蓬溪县"
                  },
                  {
                    "area": "大英县"
                  },
                  {
                    "area": "射洪市"
                  },
                  {
                    "area": "遂宁市"
                  }
                ]
              },
              {
                "city": "内江市",
                "areas": [
                  {
                    "area": "威远县"
                  },
                  {
                    "area": "资中县"
                  },
                  {
                    "area": "隆昌市"
                  },
                  {
                    "area": "内江市"
                  }
                ]
              },
              {
                "city": "乐山市",
                "areas": [
                  {
                    "area": "犍为县"
                  },
                  {
                    "area": "井研县"
                  },
                  {
                    "area": "夹江县"
                  },
                  {
                    "area": "沐川县"
                  },
                  {
                    "area": "峨边彝族自治县"
                  },
                  {
                    "area": "马边彝族自治县"
                  },
                  {
                    "area": "峨眉山市"
                  },
                  {
                    "area": "乐山市"
                  }
                ]
              },
              {
                "city": "南充市",
                "areas": [
                  {
                    "area": "南部县"
                  },
                  {
                    "area": "营山县"
                  },
                  {
                    "area": "蓬安县"
                  },
                  {
                    "area": "仪陇县"
                  },
                  {
                    "area": "西充县"
                  },
                  {
                    "area": "阆中市"
                  },
                  {
                    "area": "南充市"
                  }
                ]
              },
              {
                "city": "眉山市",
                "areas": [
                  {
                    "area": "仁寿县"
                  },
                  {
                    "area": "洪雅县"
                  },
                  {
                    "area": "丹棱县"
                  },
                  {
                    "area": "青神县"
                  },
                  {
                    "area": "眉山市"
                  }
                ]
              },
              {
                "city": "宜宾市",
                "areas": [
                  {
                    "area": "江安县"
                  },
                  {
                    "area": "长宁县"
                  },
                  {
                    "area": "高县"
                  },
                  {
                    "area": "珙县"
                  },
                  {
                    "area": "筠连县"
                  },
                  {
                    "area": "兴文县"
                  },
                  {
                    "area": "屏山县"
                  },
                  {
                    "area": "宜宾市"
                  }
                ]
              },
              {
                "city": "广安市",
                "areas": [
                  {
                    "area": "岳池县"
                  },
                  {
                    "area": "武胜县"
                  },
                  {
                    "area": "邻水县"
                  },
                  {
                    "area": "华蓥市"
                  },
                  {
                    "area": "广安市"
                  }
                ]
              },
              {
                "city": "达州市",
                "areas": [
                  {
                    "area": "宣汉县"
                  },
                  {
                    "area": "开江县"
                  },
                  {
                    "area": "大竹县"
                  },
                  {
                    "area": "渠县"
                  },
                  {
                    "area": "万源市"
                  },
                  {
                    "area": "达州市"
                  }
                ]
              },
              {
                "city": "雅安市",
                "areas": [
                  {
                    "area": "荥经县"
                  },
                  {
                    "area": "汉源县"
                  },
                  {
                    "area": "石棉县"
                  },
                  {
                    "area": "天全县"
                  },
                  {
                    "area": "芦山县"
                  },
                  {
                    "area": "宝兴县"
                  },
                  {
                    "area": "雅安市"
                  }
                ]
              },
              {
                "city": "巴中市",
                "areas": [
                  {
                    "area": "通江县"
                  },
                  {
                    "area": "南江县"
                  },
                  {
                    "area": "平昌县"
                  },
                  {
                    "area": "巴中市"
                  }
                ]
              },
              {
                "city": "资阳市",
                "areas": [
                  {
                    "area": "安岳县"
                  },
                  {
                    "area": "乐至县"
                  },
                  {
                    "area": "资阳市"
                  }
                ]
              },
              {
                "city": "阿坝藏族羌族自治州",
                "areas": [
                  {
                    "area": "马尔康市"
                  },
                  {
                    "area": "汶川县"
                  },
                  {
                    "area": "理县"
                  },
                  {
                    "area": "茂县"
                  },
                  {
                    "area": "松潘县"
                  },
                  {
                    "area": "九寨沟县"
                  },
                  {
                    "area": "金川县"
                  },
                  {
                    "area": "小金县"
                  },
                  {
                    "area": "黑水县"
                  },
                  {
                    "area": "壤塘县"
                  },
                  {
                    "area": "阿坝县"
                  },
                  {
                    "area": "若尔盖县"
                  },
                  {
                    "area": "红原县"
                  }
                ]
              },
              {
                "city": "甘孜藏族自治州",
                "areas": [
                  {
                    "area": "康定市"
                  },
                  {
                    "area": "泸定县"
                  },
                  {
                    "area": "丹巴县"
                  },
                  {
                    "area": "九龙县"
                  },
                  {
                    "area": "雅江县"
                  },
                  {
                    "area": "道孚县"
                  },
                  {
                    "area": "炉霍县"
                  },
                  {
                    "area": "甘孜县"
                  },
                  {
                    "area": "新龙县"
                  },
                  {
                    "area": "德格县"
                  },
                  {
                    "area": "白玉县"
                  },
                  {
                    "area": "石渠县"
                  },
                  {
                    "area": "色达县"
                  },
                  {
                    "area": "理塘县"
                  },
                  {
                    "area": "巴塘县"
                  },
                  {
                    "area": "乡城县"
                  },
                  {
                    "area": "稻城县"
                  },
                  {
                    "area": "得荣县"
                  }
                ]
              },
              {
                "city": "凉山彝族自治州",
                "areas": [
                  {
                    "area": "西昌市"
                  },
                  {
                    "area": "会理市"
                  },
                  {
                    "area": "木里藏族自治县"
                  },
                  {
                    "area": "盐源县"
                  },
                  {
                    "area": "德昌县"
                  },
                  {
                    "area": "会东县"
                  },
                  {
                    "area": "宁南县"
                  },
                  {
                    "area": "普格县"
                  },
                  {
                    "area": "布拖县"
                  },
                  {
                    "area": "金阳县"
                  },
                  {
                    "area": "昭觉县"
                  },
                  {
                    "area": "喜德县"
                  },
                  {
                    "area": "冕宁县"
                  },
                  {
                    "area": "越西县"
                  },
                  {
                    "area": "甘洛县"
                  },
                  {
                    "area": "美姑县"
                  },
                  {
                    "area": "雷波县"
                  }
                ]
              }
            ]
          },
          {
            "province": "贵州省",
            "citys": [
              {
                "city": "贵阳市",
                "areas": [
                  {
                    "area": "开阳县"
                  },
                  {
                    "area": "息烽县"
                  },
                  {
                    "area": "修文县"
                  },
                  {
                    "area": "清镇市"
                  },
                  {
                    "area": "贵阳市"
                  }
                ]
              },
              {
                "city": "六盘水市",
                "areas": [
                  {
                    "area": "盘州市"
                  },
                  {
                    "area": "六盘水市"
                  }
                ]
              },
              {
                "city": "遵义市",
                "areas": [
                  {
                    "area": "桐梓县"
                  },
                  {
                    "area": "绥阳县"
                  },
                  {
                    "area": "正安县"
                  },
                  {
                    "area": "道真仡佬族苗族自治县"
                  },
                  {
                    "area": "务川仡佬族苗族自治县"
                  },
                  {
                    "area": "凤冈县"
                  },
                  {
                    "area": "湄潭县"
                  },
                  {
                    "area": "余庆县"
                  },
                  {
                    "area": "习水县"
                  },
                  {
                    "area": "赤水市"
                  },
                  {
                    "area": "仁怀市"
                  },
                  {
                    "area": "遵义市"
                  }
                ]
              },
              {
                "city": "安顺市",
                "areas": [
                  {
                    "area": "普定县"
                  },
                  {
                    "area": "镇宁布依族苗族自治县"
                  },
                  {
                    "area": "关岭布依族苗族自治县"
                  },
                  {
                    "area": "紫云苗族布依族自治县"
                  },
                  {
                    "area": "安顺市"
                  }
                ]
              },
              {
                "city": "毕节市",
                "areas": [
                  {
                    "area": "大方县"
                  },
                  {
                    "area": "金沙县"
                  },
                  {
                    "area": "织金县"
                  },
                  {
                    "area": "纳雍县"
                  },
                  {
                    "area": "威宁彝族回族苗族自治县"
                  },
                  {
                    "area": "赫章县"
                  },
                  {
                    "area": "黔西市"
                  },
                  {
                    "area": "毕节市"
                  }
                ]
              },
              {
                "city": "铜仁市",
                "areas": [
                  {
                    "area": "江口县"
                  },
                  {
                    "area": "玉屏侗族自治县"
                  },
                  {
                    "area": "石阡县"
                  },
                  {
                    "area": "思南县"
                  },
                  {
                    "area": "印江土家族苗族自治县"
                  },
                  {
                    "area": "德江县"
                  },
                  {
                    "area": "沿河土家族自治县"
                  },
                  {
                    "area": "松桃苗族自治县"
                  },
                  {
                    "area": "铜仁市"
                  }
                ]
              },
              {
                "city": "黔西南布依族苗族自治州",
                "areas": [
                  {
                    "area": "兴义市"
                  },
                  {
                    "area": "兴仁市"
                  },
                  {
                    "area": "普安县"
                  },
                  {
                    "area": "晴隆县"
                  },
                  {
                    "area": "贞丰县"
                  },
                  {
                    "area": "望谟县"
                  },
                  {
                    "area": "册亨县"
                  },
                  {
                    "area": "安龙县"
                  }
                ]
              },
              {
                "city": "黔东南苗族侗族自治州",
                "areas": [
                  {
                    "area": "凯里市"
                  },
                  {
                    "area": "黄平县"
                  },
                  {
                    "area": "施秉县"
                  },
                  {
                    "area": "三穗县"
                  },
                  {
                    "area": "镇远县"
                  },
                  {
                    "area": "岑巩县"
                  },
                  {
                    "area": "天柱县"
                  },
                  {
                    "area": "锦屏县"
                  },
                  {
                    "area": "剑河县"
                  },
                  {
                    "area": "台江县"
                  },
                  {
                    "area": "黎平县"
                  },
                  {
                    "area": "榕江县"
                  },
                  {
                    "area": "从江县"
                  },
                  {
                    "area": "雷山县"
                  },
                  {
                    "area": "麻江县"
                  },
                  {
                    "area": "丹寨县"
                  }
                ]
              },
              {
                "city": "黔南布依族苗族自治州",
                "areas": [
                  {
                    "area": "都匀市"
                  },
                  {
                    "area": "福泉市"
                  },
                  {
                    "area": "荔波县"
                  },
                  {
                    "area": "贵定县"
                  },
                  {
                    "area": "瓮安县"
                  },
                  {
                    "area": "独山县"
                  },
                  {
                    "area": "平塘县"
                  },
                  {
                    "area": "罗甸县"
                  },
                  {
                    "area": "长顺县"
                  },
                  {
                    "area": "龙里县"
                  },
                  {
                    "area": "惠水县"
                  },
                  {
                    "area": "三都水族自治县"
                  }
                ]
              }
            ]
          },
          {
            "province": "云南省",
            "citys": [
              {
                "city": "昆明市",
                "areas": [
                  {
                    "area": "富民县"
                  },
                  {
                    "area": "宜良县"
                  },
                  {
                    "area": "石林彝族自治县"
                  },
                  {
                    "area": "嵩明县"
                  },
                  {
                    "area": "禄劝彝族苗族自治县"
                  },
                  {
                    "area": "寻甸回族彝族自治县"
                  },
                  {
                    "area": "安宁市"
                  },
                  {
                    "area": "昆明市"
                  }
                ]
              },
              {
                "city": "曲靖市",
                "areas": [
                  {
                    "area": "陆良县"
                  },
                  {
                    "area": "师宗县"
                  },
                  {
                    "area": "罗平县"
                  },
                  {
                    "area": "富源县"
                  },
                  {
                    "area": "会泽县"
                  },
                  {
                    "area": "宣威市"
                  },
                  {
                    "area": "曲靖市"
                  }
                ]
              },
              {
                "city": "玉溪市",
                "areas": [
                  {
                    "area": "通海县"
                  },
                  {
                    "area": "华宁县"
                  },
                  {
                    "area": "易门县"
                  },
                  {
                    "area": "峨山彝族自治县"
                  },
                  {
                    "area": "新平彝族傣族自治县"
                  },
                  {
                    "area": "元江哈尼族彝族傣族自治县"
                  },
                  {
                    "area": "澄江市"
                  },
                  {
                    "area": "玉溪市"
                  }
                ]
              },
              {
                "city": "保山市",
                "areas": [
                  {
                    "area": "施甸县"
                  },
                  {
                    "area": "龙陵县"
                  },
                  {
                    "area": "昌宁县"
                  },
                  {
                    "area": "腾冲市"
                  },
                  {
                    "area": "保山市"
                  }
                ]
              },
              {
                "city": "昭通市",
                "areas": [
                  {
                    "area": "鲁甸县"
                  },
                  {
                    "area": "巧家县"
                  },
                  {
                    "area": "盐津县"
                  },
                  {
                    "area": "大关县"
                  },
                  {
                    "area": "永善县"
                  },
                  {
                    "area": "绥江县"
                  },
                  {
                    "area": "镇雄县"
                  },
                  {
                    "area": "彝良县"
                  },
                  {
                    "area": "威信县"
                  },
                  {
                    "area": "水富市"
                  },
                  {
                    "area": "昭通市"
                  }
                ]
              },
              {
                "city": "丽江市",
                "areas": [
                  {
                    "area": "玉龙纳西族自治县"
                  },
                  {
                    "area": "永胜县"
                  },
                  {
                    "area": "华坪县"
                  },
                  {
                    "area": "宁蒗彝族自治县"
                  },
                  {
                    "area": "丽江市"
                  }
                ]
              },
              {
                "city": "普洱市",
                "areas": [
                  {
                    "area": "宁洱哈尼族彝族自治县"
                  },
                  {
                    "area": "墨江哈尼族自治县"
                  },
                  {
                    "area": "景东彝族自治县"
                  },
                  {
                    "area": "景谷傣族彝族自治县"
                  },
                  {
                    "area": "镇沅彝族哈尼族拉祜族自治县"
                  },
                  {
                    "area": "江城哈尼族彝族自治县"
                  },
                  {
                    "area": "孟连傣族拉祜族佤族自治县"
                  },
                  {
                    "area": "澜沧拉祜族自治县"
                  },
                  {
                    "area": "西盟佤族自治县"
                  },
                  {
                    "area": "普洱市"
                  }
                ]
              },
              {
                "city": "临沧市",
                "areas": [
                  {
                    "area": "凤庆县"
                  },
                  {
                    "area": "云县"
                  },
                  {
                    "area": "永德县"
                  },
                  {
                    "area": "镇康县"
                  },
                  {
                    "area": "双江拉祜族佤族布朗族傣族自治县"
                  },
                  {
                    "area": "耿马傣族佤族自治县"
                  },
                  {
                    "area": "沧源佤族自治县"
                  },
                  {
                    "area": "临沧市"
                  }
                ]
              },
              {
                "city": "楚雄彝族自治州",
                "areas": [
                  {
                    "area": "楚雄市"
                  },
                  {
                    "area": "禄丰市"
                  },
                  {
                    "area": "双柏县"
                  },
                  {
                    "area": "牟定县"
                  },
                  {
                    "area": "南华县"
                  },
                  {
                    "area": "姚安县"
                  },
                  {
                    "area": "大姚县"
                  },
                  {
                    "area": "永仁县"
                  },
                  {
                    "area": "元谋县"
                  },
                  {
                    "area": "武定县"
                  }
                ]
              },
              {
                "city": "红河哈尼族彝族自治州",
                "areas": [
                  {
                    "area": "个旧市"
                  },
                  {
                    "area": "开远市"
                  },
                  {
                    "area": "蒙自市"
                  },
                  {
                    "area": "弥勒市"
                  },
                  {
                    "area": "屏边苗族自治县"
                  },
                  {
                    "area": "建水县"
                  },
                  {
                    "area": "石屏县"
                  },
                  {
                    "area": "泸西县"
                  },
                  {
                    "area": "元阳县"
                  },
                  {
                    "area": "红河县"
                  },
                  {
                    "area": "金平苗族瑶族傣族自治县"
                  },
                  {
                    "area": "绿春县"
                  },
                  {
                    "area": "河口瑶族自治县"
                  }
                ]
              },
              {
                "city": "文山壮族苗族自治州",
                "areas": [
                  {
                    "area": "文山市"
                  },
                  {
                    "area": "砚山县"
                  },
                  {
                    "area": "西畴县"
                  },
                  {
                    "area": "麻栗坡县"
                  },
                  {
                    "area": "马关县"
                  },
                  {
                    "area": "丘北县"
                  },
                  {
                    "area": "广南县"
                  },
                  {
                    "area": "富宁县"
                  }
                ]
              },
              {
                "city": "西双版纳傣族自治州",
                "areas": [
                  {
                    "area": "景洪市"
                  },
                  {
                    "area": "勐海县"
                  },
                  {
                    "area": "勐腊县"
                  }
                ]
              },
              {
                "city": "大理白族自治州",
                "areas": [
                  {
                    "area": "大理市"
                  },
                  {
                    "area": "漾濞彝族自治县"
                  },
                  {
                    "area": "祥云县"
                  },
                  {
                    "area": "宾川县"
                  },
                  {
                    "area": "弥渡县"
                  },
                  {
                    "area": "南涧彝族自治县"
                  },
                  {
                    "area": "巍山彝族回族自治县"
                  },
                  {
                    "area": "永平县"
                  },
                  {
                    "area": "云龙县"
                  },
                  {
                    "area": "洱源县"
                  },
                  {
                    "area": "剑川县"
                  },
                  {
                    "area": "鹤庆县"
                  }
                ]
              },
              {
                "city": "德宏傣族景颇族自治州",
                "areas": [
                  {
                    "area": "瑞丽市"
                  },
                  {
                    "area": "芒市"
                  },
                  {
                    "area": "梁河县"
                  },
                  {
                    "area": "盈江县"
                  },
                  {
                    "area": "陇川县"
                  }
                ]
              },
              {
                "city": "怒江傈僳族自治州",
                "areas": [
                  {
                    "area": "泸水市"
                  },
                  {
                    "area": "福贡县"
                  },
                  {
                    "area": "贡山独龙族怒族自治县"
                  },
                  {
                    "area": "兰坪白族普米族自治县"
                  }
                ]
              },
              {
                "city": "迪庆藏族自治州",
                "areas": [
                  {
                    "area": "香格里拉市"
                  },
                  {
                    "area": "德钦县"
                  },
                  {
                    "area": "维西傈僳族自治县"
                  }
                ]
              }
            ]
          },
          {
            "province": "西藏自治区",
            "citys": [
              {
                "city": "拉萨市",
                "areas": [
                  {
                    "area": "林周县"
                  },
                  {
                    "area": "当雄县"
                  },
                  {
                    "area": "尼木县"
                  },
                  {
                    "area": "曲水县"
                  },
                  {
                    "area": "墨竹工卡县"
                  },
                  {
                    "area": "拉萨市"
                  }
                ]
              },
              {
                "city": "日喀则市",
                "areas": [
                  {
                    "area": "南木林县"
                  },
                  {
                    "area": "江孜县"
                  },
                  {
                    "area": "定日县"
                  },
                  {
                    "area": "萨迦县"
                  },
                  {
                    "area": "拉孜县"
                  },
                  {
                    "area": "昂仁县"
                  },
                  {
                    "area": "谢通门县"
                  },
                  {
                    "area": "白朗县"
                  },
                  {
                    "area": "仁布县"
                  },
                  {
                    "area": "康马县"
                  },
                  {
                    "area": "定结县"
                  },
                  {
                    "area": "仲巴县"
                  },
                  {
                    "area": "亚东县"
                  },
                  {
                    "area": "吉隆县"
                  },
                  {
                    "area": "聂拉木县"
                  },
                  {
                    "area": "萨嘎县"
                  },
                  {
                    "area": "岗巴县"
                  },
                  {
                    "area": "日喀则市"
                  }
                ]
              },
              {
                "city": "昌都市",
                "areas": [
                  {
                    "area": "江达县"
                  },
                  {
                    "area": "贡觉县"
                  },
                  {
                    "area": "类乌齐县"
                  },
                  {
                    "area": "丁青县"
                  },
                  {
                    "area": "察雅县"
                  },
                  {
                    "area": "八宿县"
                  },
                  {
                    "area": "左贡县"
                  },
                  {
                    "area": "芒康县"
                  },
                  {
                    "area": "洛隆县"
                  },
                  {
                    "area": "边坝县"
                  },
                  {
                    "area": "昌都市"
                  }
                ]
              },
              {
                "city": "林芝市",
                "areas": [
                  {
                    "area": "工布江达县"
                  },
                  {
                    "area": "米林县"
                  },
                  {
                    "area": "墨脱县"
                  },
                  {
                    "area": "波密县"
                  },
                  {
                    "area": "察隅县"
                  },
                  {
                    "area": "朗县"
                  },
                  {
                    "area": "林芝市"
                  }
                ]
              },
              {
                "city": "山南市",
                "areas": [
                  {
                    "area": "扎囊县"
                  },
                  {
                    "area": "贡嘎县"
                  },
                  {
                    "area": "桑日县"
                  },
                  {
                    "area": "琼结县"
                  },
                  {
                    "area": "曲松县"
                  },
                  {
                    "area": "措美县"
                  },
                  {
                    "area": "洛扎县"
                  },
                  {
                    "area": "加查县"
                  },
                  {
                    "area": "隆子县"
                  },
                  {
                    "area": "错那县"
                  },
                  {
                    "area": "浪卡子县"
                  },
                  {
                    "area": "山南市"
                  }
                ]
              },
              {
                "city": "那曲市",
                "areas": [
                  {
                    "area": "嘉黎县"
                  },
                  {
                    "area": "比如县"
                  },
                  {
                    "area": "聂荣县"
                  },
                  {
                    "area": "安多县"
                  },
                  {
                    "area": "申扎县"
                  },
                  {
                    "area": "索县"
                  },
                  {
                    "area": "班戈县"
                  },
                  {
                    "area": "巴青县"
                  },
                  {
                    "area": "尼玛县"
                  },
                  {
                    "area": "双湖县"
                  },
                  {
                    "area": "那曲市"
                  }
                ]
              },
              {
                "city": "阿里地区",
                "areas": [
                  {
                    "area": "普兰县"
                  },
                  {
                    "area": "札达县"
                  },
                  {
                    "area": "噶尔县"
                  },
                  {
                    "area": "日土县"
                  },
                  {
                    "area": "革吉县"
                  },
                  {
                    "area": "改则县"
                  },
                  {
                    "area": "措勤县"
                  }
                ]
              }
            ]
          },
          {
            "province": "陕西省",
            "citys": [
              {
                "city": "西安市",
                "areas": [
                  {
                    "area": "蓝田县"
                  },
                  {
                    "area": "周至县"
                  },
                  {
                    "area": "西安市"
                  }
                ]
              },
              {
                "city": "铜川市",
                "areas": [
                  {
                    "area": "宜君县"
                  },
                  {
                    "area": "铜川市"
                  }
                ]
              },
              {
                "city": "宝鸡市",
                "areas": [
                  {
                    "area": "岐山县"
                  },
                  {
                    "area": "扶风县"
                  },
                  {
                    "area": "眉县"
                  },
                  {
                    "area": "陇县"
                  },
                  {
                    "area": "千阳县"
                  },
                  {
                    "area": "麟游县"
                  },
                  {
                    "area": "凤县"
                  },
                  {
                    "area": "太白县"
                  },
                  {
                    "area": "宝鸡市"
                  }
                ]
              },
              {
                "city": "咸阳市",
                "areas": [
                  {
                    "area": "三原县"
                  },
                  {
                    "area": "泾阳县"
                  },
                  {
                    "area": "乾县"
                  },
                  {
                    "area": "礼泉县"
                  },
                  {
                    "area": "永寿县"
                  },
                  {
                    "area": "长武县"
                  },
                  {
                    "area": "旬邑县"
                  },
                  {
                    "area": "淳化县"
                  },
                  {
                    "area": "武功县"
                  },
                  {
                    "area": "兴平市"
                  },
                  {
                    "area": "彬州市"
                  },
                  {
                    "area": "咸阳市"
                  }
                ]
              },
              {
                "city": "渭南市",
                "areas": [
                  {
                    "area": "潼关县"
                  },
                  {
                    "area": "大荔县"
                  },
                  {
                    "area": "合阳县"
                  },
                  {
                    "area": "澄城县"
                  },
                  {
                    "area": "蒲城县"
                  },
                  {
                    "area": "白水县"
                  },
                  {
                    "area": "富平县"
                  },
                  {
                    "area": "韩城市"
                  },
                  {
                    "area": "华阴市"
                  },
                  {
                    "area": "渭南市"
                  }
                ]
              },
              {
                "city": "延安市",
                "areas": [
                  {
                    "area": "延长县"
                  },
                  {
                    "area": "延川县"
                  },
                  {
                    "area": "志丹县"
                  },
                  {
                    "area": "吴起县"
                  },
                  {
                    "area": "甘泉县"
                  },
                  {
                    "area": "富县"
                  },
                  {
                    "area": "洛川县"
                  },
                  {
                    "area": "宜川县"
                  },
                  {
                    "area": "黄龙县"
                  },
                  {
                    "area": "黄陵县"
                  },
                  {
                    "area": "子长市"
                  },
                  {
                    "area": "延安市"
                  }
                ]
              },
              {
                "city": "汉中市",
                "areas": [
                  {
                    "area": "城固县"
                  },
                  {
                    "area": "洋县"
                  },
                  {
                    "area": "西乡县"
                  },
                  {
                    "area": "勉县"
                  },
                  {
                    "area": "宁强县"
                  },
                  {
                    "area": "略阳县"
                  },
                  {
                    "area": "镇巴县"
                  },
                  {
                    "area": "留坝县"
                  },
                  {
                    "area": "佛坪县"
                  },
                  {
                    "area": "汉中市"
                  }
                ]
              },
              {
                "city": "榆林市",
                "areas": [
                  {
                    "area": "府谷县"
                  },
                  {
                    "area": "靖边县"
                  },
                  {
                    "area": "定边县"
                  },
                  {
                    "area": "绥德县"
                  },
                  {
                    "area": "米脂县"
                  },
                  {
                    "area": "佳县"
                  },
                  {
                    "area": "吴堡县"
                  },
                  {
                    "area": "清涧县"
                  },
                  {
                    "area": "子洲县"
                  },
                  {
                    "area": "神木市"
                  },
                  {
                    "area": "榆林市"
                  }
                ]
              },
              {
                "city": "安康市",
                "areas": [
                  {
                    "area": "汉阴县"
                  },
                  {
                    "area": "石泉县"
                  },
                  {
                    "area": "宁陕县"
                  },
                  {
                    "area": "紫阳县"
                  },
                  {
                    "area": "岚皋县"
                  },
                  {
                    "area": "平利县"
                  },
                  {
                    "area": "镇坪县"
                  },
                  {
                    "area": "白河县"
                  },
                  {
                    "area": "旬阳市"
                  },
                  {
                    "area": "安康市"
                  }
                ]
              },
              {
                "city": "商洛市",
                "areas": [
                  {
                    "area": "洛南县"
                  },
                  {
                    "area": "丹凤县"
                  },
                  {
                    "area": "商南县"
                  },
                  {
                    "area": "山阳县"
                  },
                  {
                    "area": "镇安县"
                  },
                  {
                    "area": "柞水县"
                  },
                  {
                    "area": "商洛市"
                  }
                ]
              }
            ]
          },
          {
            "province": "甘肃省",
            "citys": [
              {
                "city": "兰州市",
                "areas": [
                  {
                    "area": "永登县"
                  },
                  {
                    "area": "皋兰县"
                  },
                  {
                    "area": "榆中县"
                  },
                  {
                    "area": "兰州市"
                  }
                ]
              },
              {
                "city": "金昌市",
                "areas": [
                  {
                    "area": "永昌县"
                  },
                  {
                    "area": "金昌市"
                  }
                ]
              },
              {
                "city": "白银市",
                "areas": [
                  {
                    "area": "靖远县"
                  },
                  {
                    "area": "会宁县"
                  },
                  {
                    "area": "景泰县"
                  },
                  {
                    "area": "白银市"
                  }
                ]
              },
              {
                "city": "天水市",
                "areas": [
                  {
                    "area": "清水县"
                  },
                  {
                    "area": "秦安县"
                  },
                  {
                    "area": "甘谷县"
                  },
                  {
                    "area": "武山县"
                  },
                  {
                    "area": "张家川回族自治县"
                  },
                  {
                    "area": "天水市"
                  }
                ]
              },
              {
                "city": "武威市",
                "areas": [
                  {
                    "area": "民勤县"
                  },
                  {
                    "area": "古浪县"
                  },
                  {
                    "area": "天祝藏族自治县"
                  },
                  {
                    "area": "武威市"
                  }
                ]
              },
              {
                "city": "张掖市",
                "areas": [
                  {
                    "area": "肃南裕固族自治县"
                  },
                  {
                    "area": "民乐县"
                  },
                  {
                    "area": "临泽县"
                  },
                  {
                    "area": "高台县"
                  },
                  {
                    "area": "山丹县"
                  },
                  {
                    "area": "张掖市"
                  }
                ]
              },
              {
                "city": "平凉市",
                "areas": [
                  {
                    "area": "泾川县"
                  },
                  {
                    "area": "灵台县"
                  },
                  {
                    "area": "崇信县"
                  },
                  {
                    "area": "庄浪县"
                  },
                  {
                    "area": "静宁县"
                  },
                  {
                    "area": "华亭市"
                  },
                  {
                    "area": "平凉市"
                  }
                ]
              },
              {
                "city": "酒泉市",
                "areas": [
                  {
                    "area": "金塔县"
                  },
                  {
                    "area": "瓜州县"
                  },
                  {
                    "area": "肃北蒙古族自治县"
                  },
                  {
                    "area": "阿克塞哈萨克族自治县"
                  },
                  {
                    "area": "玉门市"
                  },
                  {
                    "area": "敦煌市"
                  },
                  {
                    "area": "酒泉市"
                  }
                ]
              },
              {
                "city": "庆阳市",
                "areas": [
                  {
                    "area": "庆城县"
                  },
                  {
                    "area": "环县"
                  },
                  {
                    "area": "华池县"
                  },
                  {
                    "area": "合水县"
                  },
                  {
                    "area": "正宁县"
                  },
                  {
                    "area": "宁县"
                  },
                  {
                    "area": "镇原县"
                  },
                  {
                    "area": "庆阳市"
                  }
                ]
              },
              {
                "city": "定西市",
                "areas": [
                  {
                    "area": "通渭县"
                  },
                  {
                    "area": "陇西县"
                  },
                  {
                    "area": "渭源县"
                  },
                  {
                    "area": "临洮县"
                  },
                  {
                    "area": "漳县"
                  },
                  {
                    "area": "岷县"
                  },
                  {
                    "area": "定西市"
                  }
                ]
              },
              {
                "city": "陇南市",
                "areas": [
                  {
                    "area": "成县"
                  },
                  {
                    "area": "文县"
                  },
                  {
                    "area": "宕昌县"
                  },
                  {
                    "area": "康县"
                  },
                  {
                    "area": "西和县"
                  },
                  {
                    "area": "礼县"
                  },
                  {
                    "area": "徽县"
                  },
                  {
                    "area": "两当县"
                  },
                  {
                    "area": "陇南市"
                  }
                ]
              },
              {
                "city": "临夏回族自治州",
                "areas": [
                  {
                    "area": "临夏市"
                  },
                  {
                    "area": "临夏县"
                  },
                  {
                    "area": "康乐县"
                  },
                  {
                    "area": "永靖县"
                  },
                  {
                    "area": "广河县"
                  },
                  {
                    "area": "和政县"
                  },
                  {
                    "area": "东乡族自治县"
                  },
                  {
                    "area": "积石山保安族东乡族撒拉族自治县"
                  }
                ]
              },
              {
                "city": "甘南藏族自治州",
                "areas": [
                  {
                    "area": "合作市"
                  },
                  {
                    "area": "临潭县"
                  },
                  {
                    "area": "卓尼县"
                  },
                  {
                    "area": "舟曲县"
                  },
                  {
                    "area": "迭部县"
                  },
                  {
                    "area": "玛曲县"
                  },
                  {
                    "area": "碌曲县"
                  },
                  {
                    "area": "夏河县"
                  }
                ]
              }
            ]
          },
          {
            "province": "青海省",
            "citys": [
              {
                "city": "西宁市",
                "areas": [
                  {
                    "area": "大通回族土族自治县"
                  },
                  {
                    "area": "湟源县"
                  },
                  {
                    "area": "西宁市"
                  }
                ]
              },
              {
                "city": "海东市",
                "areas": [
                  {
                    "area": "民和回族土族自治县"
                  },
                  {
                    "area": "互助土族自治县"
                  },
                  {
                    "area": "化隆回族自治县"
                  },
                  {
                    "area": "循化撒拉族自治县"
                  },
                  {
                    "area": "海东市"
                  }
                ]
              },
              {
                "city": "海北藏族自治州",
                "areas": [
                  {
                    "area": "门源回族自治县"
                  },
                  {
                    "area": "祁连县"
                  },
                  {
                    "area": "海晏县"
                  },
                  {
                    "area": "刚察县"
                  }
                ]
              },
              {
                "city": "黄南藏族自治州",
                "areas": [
                  {
                    "area": "同仁市"
                  },
                  {
                    "area": "尖扎县"
                  },
                  {
                    "area": "泽库县"
                  },
                  {
                    "area": "河南蒙古族自治县"
                  }
                ]
              },
              {
                "city": "海南藏族自治州",
                "areas": [
                  {
                    "area": "共和县"
                  },
                  {
                    "area": "同德县"
                  },
                  {
                    "area": "贵德县"
                  },
                  {
                    "area": "兴海县"
                  },
                  {
                    "area": "贵南县"
                  }
                ]
              },
              {
                "city": "果洛藏族自治州",
                "areas": [
                  {
                    "area": "玛沁县"
                  },
                  {
                    "area": "班玛县"
                  },
                  {
                    "area": "甘德县"
                  },
                  {
                    "area": "达日县"
                  },
                  {
                    "area": "久治县"
                  },
                  {
                    "area": "玛多县"
                  }
                ]
              },
              {
                "city": "玉树藏族自治州",
                "areas": [
                  {
                    "area": "玉树市"
                  },
                  {
                    "area": "杂多县"
                  },
                  {
                    "area": "称多县"
                  },
                  {
                    "area": "治多县"
                  },
                  {
                    "area": "囊谦县"
                  },
                  {
                    "area": "曲麻莱县"
                  }
                ]
              },
              {
                "city": "海西蒙古族藏族自治州",
                "areas": [
                  {
                    "area": "格尔木市"
                  },
                  {
                    "area": "德令哈市"
                  },
                  {
                    "area": "茫崖市"
                  },
                  {
                    "area": "乌兰县"
                  },
                  {
                    "area": "都兰县"
                  },
                  {
                    "area": "天峻县"
                  }
                ]
              }
            ]
          },
          {
            "province": "宁夏回族自治区",
            "citys": [
              {
                "city": "银川市",
                "areas": [
                  {
                    "area": "永宁县"
                  },
                  {
                    "area": "贺兰县"
                  },
                  {
                    "area": "灵武市"
                  },
                  {
                    "area": "银川市"
                  }
                ]
              },
              {
                "city": "石嘴山市",
                "areas": [
                  {
                    "area": "平罗县"
                  },
                  {
                    "area": "石嘴山市"
                  }
                ]
              },
              {
                "city": "吴忠市",
                "areas": [
                  {
                    "area": "盐池县"
                  },
                  {
                    "area": "同心县"
                  },
                  {
                    "area": "青铜峡市"
                  },
                  {
                    "area": "吴忠市"
                  }
                ]
              },
              {
                "city": "固原市",
                "areas": [
                  {
                    "area": "西吉县"
                  },
                  {
                    "area": "隆德县"
                  },
                  {
                    "area": "泾源县"
                  },
                  {
                    "area": "彭阳县"
                  },
                  {
                    "area": "固原市"
                  }
                ]
              },
              {
                "city": "中卫市",
                "areas": [
                  {
                    "area": "中宁县"
                  },
                  {
                    "area": "海原县"
                  },
                  {
                    "area": "中卫市"
                  }
                ]
              }
            ]
          },
          {
            "province": "新疆维吾尔自治区",
            "citys": [
              {
                "city": "乌鲁木齐市",
                "areas": [
                  {
                    "area": "乌鲁木齐县"
                  },
                  {
                    "area": "乌鲁木齐市"
                  }
                ]
              },
              {
                "city": "克拉玛依市",
                "areas": [
                  {
                    "area": "克拉玛依市"
                  }
                ]
              },
              {
                "city": "吐鲁番市",
                "areas": [
                  {
                    "area": "鄯善县"
                  },
                  {
                    "area": "托克逊县"
                  },
                  {
                    "area": "吐鲁番市"
                  }
                ]
              },
              {
                "city": "哈密市",
                "areas": [
                  {
                    "area": "巴里坤哈萨克自治县"
                  },
                  {
                    "area": "伊吾县"
                  },
                  {
                    "area": "哈密市"
                  }
                ]
              },
              {
                "city": "昌吉回族自治州",
                "areas": [
                  {
                    "area": "昌吉市"
                  },
                  {
                    "area": "阜康市"
                  },
                  {
                    "area": "呼图壁县"
                  },
                  {
                    "area": "玛纳斯县"
                  },
                  {
                    "area": "奇台县"
                  },
                  {
                    "area": "吉木萨尔县"
                  },
                  {
                    "area": "木垒哈萨克自治县"
                  }
                ]
              },
              {
                "city": "博尔塔拉蒙古自治州",
                "areas": [
                  {
                    "area": "博乐市"
                  },
                  {
                    "area": "阿拉山口市"
                  },
                  {
                    "area": "精河县"
                  },
                  {
                    "area": "温泉县"
                  }
                ]
              },
              {
                "city": "巴音郭楞蒙古自治州",
                "areas": [
                  {
                    "area": "库尔勒市"
                  },
                  {
                    "area": "轮台县"
                  },
                  {
                    "area": "尉犁县"
                  },
                  {
                    "area": "若羌县"
                  },
                  {
                    "area": "且末县"
                  },
                  {
                    "area": "焉耆回族自治县"
                  },
                  {
                    "area": "和静县"
                  },
                  {
                    "area": "和硕县"
                  },
                  {
                    "area": "博湖县"
                  }
                ]
              },
              {
                "city": "阿克苏地区",
                "areas": [
                  {
                    "area": "阿克苏市"
                  },
                  {
                    "area": "库车市"
                  },
                  {
                    "area": "温宿县"
                  },
                  {
                    "area": "沙雅县"
                  },
                  {
                    "area": "新和县"
                  },
                  {
                    "area": "拜城县"
                  },
                  {
                    "area": "乌什县"
                  },
                  {
                    "area": "阿瓦提县"
                  },
                  {
                    "area": "柯坪县"
                  }
                ]
              },
              {
                "city": "克孜勒苏柯尔克孜自治州",
                "areas": [
                  {
                    "area": "阿图什市"
                  },
                  {
                    "area": "阿克陶县"
                  },
                  {
                    "area": "阿合奇县"
                  },
                  {
                    "area": "乌恰县"
                  }
                ]
              },
              {
                "city": "喀什地区",
                "areas": [
                  {
                    "area": "喀什市"
                  },
                  {
                    "area": "疏附县"
                  },
                  {
                    "area": "疏勒县"
                  },
                  {
                    "area": "英吉沙县"
                  },
                  {
                    "area": "泽普县"
                  },
                  {
                    "area": "莎车县"
                  },
                  {
                    "area": "叶城县"
                  },
                  {
                    "area": "麦盖提县"
                  },
                  {
                    "area": "岳普湖县"
                  },
                  {
                    "area": "伽师县"
                  },
                  {
                    "area": "巴楚县"
                  },
                  {
                    "area": "塔什库尔干塔吉克自治县"
                  }
                ]
              },
              {
                "city": "和田地区",
                "areas": [
                  {
                    "area": "和田市"
                  },
                  {
                    "area": "和田县"
                  },
                  {
                    "area": "墨玉县"
                  },
                  {
                    "area": "皮山县"
                  },
                  {
                    "area": "洛浦县"
                  },
                  {
                    "area": "策勒县"
                  },
                  {
                    "area": "于田县"
                  },
                  {
                    "area": "民丰县"
                  }
                ]
              },
              {
                "city": "伊犁哈萨克自治州",
                "areas": [
                  {
                    "area": "伊宁市"
                  },
                  {
                    "area": "奎屯市"
                  },
                  {
                    "area": "霍尔果斯市"
                  },
                  {
                    "area": "伊宁县"
                  },
                  {
                    "area": "察布查尔锡伯自治县"
                  },
                  {
                    "area": "霍城县"
                  },
                  {
                    "area": "巩留县"
                  },
                  {
                    "area": "新源县"
                  },
                  {
                    "area": "昭苏县"
                  },
                  {
                    "area": "特克斯县"
                  },
                  {
                    "area": "尼勒克县"
                  }
                ]
              },
              {
                "city": "塔城地区",
                "areas": [
                  {
                    "area": "塔城市"
                  },
                  {
                    "area": "乌苏市"
                  },
                  {
                    "area": "沙湾市"
                  },
                  {
                    "area": "额敏县"
                  },
                  {
                    "area": "托里县"
                  },
                  {
                    "area": "裕民县"
                  },
                  {
                    "area": "和布克赛尔蒙古自治县"
                  }
                ]
              },
              {
                "city": "阿勒泰地区",
                "areas": [
                  {
                    "area": "阿勒泰市"
                  },
                  {
                    "area": "布尔津县"
                  },
                  {
                    "area": "富蕴县"
                  },
                  {
                    "area": "福海县"
                  },
                  {
                    "area": "哈巴河县"
                  },
                  {
                    "area": "青河县"
                  },
                  {
                    "area": "吉木乃县"
                  },
                  {
                    "area": "石河子市"
                  },
                  {
                    "area": "阿拉尔市"
                  },
                  {
                    "area": "图木舒克市"
                  },
                  {
                    "area": "五家渠市"
                  },
                  {
                    "area": "北屯市"
                  },
                  {
                    "area": "铁门关市"
                  },
                  {
                    "area": "双河市"
                  },
                  {
                    "area": "可克达拉市"
                  },
                  {
                    "area": "昆玉市"
                  },
                  {
                    "area": "胡杨河市"
                  },
                  {
                    "area": "新星市"
                  }
                ]
              }
            ]
          },
          {
            "province": "台湾省",
            "citys": [
              {
                "city": "台北市",
                "areas": [
                  {
                    "area": "台北市"
                  }
                ]
              },
              {
                "city": "新北市",
                "areas": [
                  {
                    "area": "新北市"
                  }
                ]
              },
              {
                "city": "桃园市",
                "areas": [
                  {
                    "area": "桃园市"
                  }
                ]
              },
              {
                "city": "台中市",
                "areas": [
                  {
                    "area": "台中市"
                  }
                ]
              },
              {
                "city": "台南市",
                "areas": [
                  {
                    "area": "台南市"
                  }
                ]
              },
              {
                "city": "高雄市",
                "areas": [
                  {
                    "area": "高雄市"
                  }
                ]
              },
              {
                "city": "基隆市",
                "areas": [
                  {
                    "area": "基隆市"
                  }
                ]
              },
              {
                "city": "新竹市",
                "areas": [
                  {
                    "area": "新竹市"
                  }
                ]
              },
              {
                "city": "嘉义市",
                "areas": [
                  {
                    "area": "嘉义市"
                  }
                ]
              },
              {
                "city": "新竹县",
                "areas": [
                  {
                    "area": "新竹县"
                  }
                ]
              },
              {
                "city": "苗栗县",
                "areas": [
                  {
                    "area": "苗栗县"
                  }
                ]
              },
              {
                "city": "彰化县",
                "areas": [
                  {
                    "area": "彰化县"
                  }
                ]
              },
              {
                "city": "南投县",
                "areas": [
                  {
                    "area": "南投县"
                  }
                ]
              },
              {
                "city": "云林县",
                "areas": [
                  {
                    "area": "云林县"
                  }
                ]
              },
              {
                "city": "嘉义县",
                "areas": [
                  {
                    "area": "嘉义县"
                  }
                ]
              },
              {
                "city": "屏东县",
                "areas": [
                  {
                    "area": "屏东县"
                  }
                ]
              },
              {
                "city": "宜兰县",
                "areas": [
                  {
                    "area": "宜兰县"
                  }
                ]
              },
              {
                "city": "花莲县",
                "areas": [
                  {
                    "area": "花莲县"
                  }
                ]
              },
              {
                "city": "台东县",
                "areas": [
                  {
                    "area": "台东县"
                  }
                ]
              },
              {
                "city": "澎湖县",
                "areas": [
                  {
                    "area": "澎湖县"
                  }
                ]
              },
              {
                "city": "连江县",
                "areas": [
                  {
                    "area": "连江县"
                  }
                ]
              }
            ]
          },
          {
            "province": "香港特别行政区",
            "citys": [
              {
                "city": "香港特别行政区",
                "areas": [
                  {
                    "area": "香港特别行政区"
                  }
                ]
              }
            ]
          },
          {
            "province": "澳门特别行政区",
            "citys": [{
                "city": "澳门特别行政区",
                "areas": [
                  {
                    "area": "澳门特别行政区"
                  }
                ]
              }]
          }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      value: ''
    }
  },
  created() {
    this.init();
  },
  methods: {
    getbydataUrl(url){
      if(url) {
        window.location.href = url; // 这里进行跳转
      } else {
        console.log('URL无效，请检查数据'); // 或者处理无效URL的逻辑
      }
    },
    handleOutsideClick(event) {
      if (this.showsignup==true){
        // 判断点击事件是否发生在弹窗组件之外
        if (!this.$refs.occPJOsignup.$el.contains(event.target)) {
          this.handleClosePopup();
        }
      }
    },
    handleClosePopup() {
      this.$confirm('此操作将关闭弹窗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '关闭!'
        });
        this.showsignup=false;
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    xianshi(){
      setTimeout(() => {
        this.showsignup=true;
      }, 1000);
    },
    getCityOptions(province) {
      if (!province || province === '全部') return [];

      const matchingProvinceData = this.provinces.find(p => p.province === province);
      return matchingProvinceData ? matchingProvinceData.citys : [];
    },

    getAreaOptions(city) {
      if (!city) return [];

      const matchingCityData = this.provinces.flatMap(item => item.citys).find(c => c.city === city);
      return matchingCityData ? matchingCityData.areas : [];
    },
    init(){
      let addrsName=null;
      if (this.selectedProvince=='全部'){
        addrsName=null;
      }else if (null!=this.selectedCity && this.selectedArea==''){
        addrsName=this.selectedCity;
      }else if (''!=this.selectedArea){
        addrsName=this.selectedArea;
      }
      Api.Area.getbynames({
        name:addrsName
      }).then(ress=>{
        Api.Occupationallevel.init({
          edu_areaID:ress.data.id,
          type:this.value
        }).then(res=>{
          let dataArrays=[];
          for (let i = 0; i < res.data.data.data.length; i++) {
            // 假设这是您给定的字符串
            let dataString = res.data.data.data[i].thresholdimages;

            // 使用split方法根据逗号分隔字符串，得到一个包含所有子字符串的数组
            let dataArray = dataString.split(',');

            dataArrays[i]=dataArray;

            this.Imagearraylist[i] = dataArrays;

          }

          this.dataList=res.data.data;
        })
      })
    },
    toggleImageGallery(images) {
      this.isGalleryOpen = !this.isGalleryOpen;
      this.currentGallery = images; // 设置当前画廊的图片列表
    },
    closeImageGallery() {
      this.isGalleryOpen = false;
    },
    setCurrentImage(index) {
      this.currentImageIndex = index; // 如果需要突出显示当前点击的图片，可以在这里处理
    },
    //切换分页
    handleCurrentChange(val) {
      let that = this;
      that.page = val;
      that.getDataListInit();
    }
  },
  mounted() {

  },
  watch: {}
}
</script>
<style scoped lang="less">

/* 假设弹出框容器的类名为 .popup-container */
.popup-container {
  overflow-y: auto; /* 自动显示垂直滚动条 */
  max-height: 500px; /* 设置一个最大高度，可以根据实际需求调整 */
  width: 80%; /* 或其他适合的宽度 */
  box-sizing: border-box;
  z-index: 99;
}

/* （可选）自定义滚动条样式 */
.popup-container::-webkit-scrollbar {
  width: 10px; /* 滚动条宽度 */
}

.popup-container::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 6px; /* 滚动条滑块圆角 */
}
/* scoped 让style样式 局部生效*/
.page{
  padding-top: 20px;
  background: #f7f8fa;
  min-height: calc(100vh - 300px);
}

.section-select{
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0 30px;
  margin-bottom: 15px;

  .select-ul{
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #666;
    line-height: 30px;
    display: flex;
    padding: 10px 0;
  }

  .select-ul:last-child{
    border-bottom: none;
  }

  .title{
    width: 100px;
    min-width: 100px;
  }

  .content{
    display: flex;
    flex-wrap: wrap;
  }

  .item{
    margin-right: 30px;
    text-decoration: none;
    color: #999999;
  }

  .item:hover,
  .item.active{
    color: #D11A2D;
  }
}

.select-cont{
  min-height: 380px;

  .item{
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    overflow: hidden;
  }

  .item .img-box{
    width: 180px;
    min-width: 180px;
    height: 150px;
    background: #eeeeee;


    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item .cont-box{
    flex: 1;
    padding-left: 20px;

    .title{
      position: relative;

      .tit{
        color: #333333;
        text-decoration: none;
        font-weight: bold;
        font-size: 18px;
      }

      .address{
        font-weight: normal;
        position: absolute;
        right: 0;
        font-size: 15px;
      }
    }

    .tag{
      margin: 15px 0;
      display: flex;

      .label{
        margin-right: 10px;
      }
    }

    .desc{
      margin-top: 10px;
      width: 100%;
      height: 55px;
      line-height: 25px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .desc:hover {
      color: darkblue; /* 鼠标悬停时，文本颜色变深 */
      text-decoration: underline; /* 保持下划线，或改为none去掉下划线，根据需要调整 */
    }
  }
}


// 分页
.page-block{
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background: #ffffff
}

:deep(.el-pager li:not(.disabled).active){
  background: #D11A2D!important;
}

:deep(.el-pager li:not(.active):hover){
  color: #D11A2D!important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
  max-width: 80%;
}

.gallery-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

// 立即报名
.wsc-poster{
  margin: 30px 0 0;
  display: block;
  cursor: pointer;

  .poster-img{
    width: 100%;
    height: auto;
  }
}

</style>