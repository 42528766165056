<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main class="page">
    <!-- 顶部导航栏 -->
    <div>
      <el-form :inline="true" class="demo-form-inline">
        <!-- 搜索框部分 -->
        <el-input placeholder="请输入院校名称" v-model="schollName" @blur="onSearch"></el-input>

        <!-- 筛选部分 -->
        <div class="filter-section">
          <!-- 院校所在地 -->
          <div class="filter-item">
            <div class="filter-content">
              <span class="filter-label">院校所在地:</span>
              <div class="options-group">
                <div
                    v-for="item in areaList"
                    :key="item.id"
                    class="custom-radio"
                    @click="handleAreaClick(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>

          <!-- 院校类别 -->
          <div class="filter-item">
            <div class="filter-content">
              <span class="filter-label">院校类别:</span>
              <div class="options-group">
                <div
                    v-for="item in schooltype1"
                    :key="item.id"
                    class="custom-radio"
                    @click="handleType1Click(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>

          <!-- 学历层次 -->
          <div class="filter-item">
            <div class="filter-content">
              <span class="filter-label">学历层次:</span>
              <div class="options-group">
                <div
                    v-for="item in schooltype2"
                    :key="item.value"
                    class="custom-radio"
                    @click="handleType2Click(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>

          <!-- 院校特性 -->
          <div class="filter-item">
            <div class="filter-content">
              <span class="filter-label">院校特性:</span>
              <div class="options-group">
                <div
                    v-for="item in schooltype3"
                    :key="item.id"
                    class="custom-radio"
                    @click="handleType3Click(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
      <div class="info-card" @click="handleImageClick(item.id)" v-for="item in dataList.data">
        <div class="icon">
          <img :src="`http://admin.zhongguojiaoyunet.com/`+item.images" alt="院校Logo" />
        </div>
        <div class="info">
          <h2 class="institution-name">{{ item.name }}</h2>
          <p class="location" v-for="type in item.typesList">
            <span class="el-icon-map-location"></span>
            {{ item.addrscName }} | 层次：{{ type.name }}
          </p>
        </div>
      </div>

    <el-pagination
        class="pagination"
        @size-change="handleSizeChanges"
        @current-change="handleCurrentChanges"
        :current-page="dataList.current_page"
        :page-sizes="[5, 10, 15,20]"
        :page-size="parseInt(dataList.per_page)"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataList.total">
    </el-pagination>
  </main>
</template>
<script>
import Api from "@/Api.js";
export default {
  data() {
    return {
      typesList: [],
      areaList: [],
      cityList: [],
      dataList: {
        data:null,
        per_page:8,
        current_page:1
      },
      page: 1,
      types: '',
      area_id: '',
      schooltype1:[],
      schooltype2:[],
      schooltype3:[],
      schooltype4:[],
      schollName:null,
      selectedArea: '',    // 院校所在地
      selectedType1: '',   // 院校类别
      selectedType2: '',   // 学历层次
      selectedType3: '',   // 院校特性
    }
  },
  methods: {
    // 院校所在地选择
    handleAreaClick(id) {
      this.selectedArea = id === this.selectedArea ? '' : id
      this.onSearch();
    },

    // 院校类别选择
    handleType1Click(id) {
      this.selectedType1 = id === this.selectedType1 ? '' : id
      this.onSearch();
    },

    // 学历层次选择
    handleType2Click(id) {
      this.selectedType2 = id === this.selectedType2 ? '' : id
      this.onSearch();
    },

    // 院校特性选择
    handleType3Click(id) {
      this.selectedType3 = id === this.selectedType3 ? '' : id
      this.onSearch();
    },
    onSearch(){
      Api.School.getSchoolBytypeList({
        schollName:this.schollName,
        selectedArea:this.selectedArea,
        selectedType1:this.selectedType1,
        selectedType2:this.selectedType2,
        selectedType3:this.selectedType3,
        per_page:this.dataList.per_page,
        current_page:this.dataList.current_page
      }).then(res=>{
        this.dataList=res.data.data;
      })
    },
    getshool(){
      Api.School.schoollist({
        name:this.schollName,
        per_page:this.dataList.per_page,
        current_page:this.dataList.current_page
      }).then(res=>{
        console.info(res);
      })
    },
    handleImageClick(id) {
      this.$router.push({ path: '/school', query: { id: id } });
    },
    //数据填充
    getInit(){
      let that = this;
      Api.School.getSchoolInit().then((res) => {
        if (res.data.code == 1) {
          that.typesList = res.data.data.types;
          that.areaList = res.data.data.province;
          that.dataList = res.data.data.dataList;
        }
        console.info(that.dataList);
        for (let i = 0; i <that.dataList.data.length ; i++) {
          Api.Area.getByidarea({
            areaID:that.dataList.data[i].addrsc
          }).then(res=>{
            that.dataList.data[i].addrscName=res.data.name;
            console.info(that.dataList.data[i]);
          })
        }
        for (let i = 0; i <that.typesList.length ; i++) {
          if (that.typesList[i].level==1){
            that.schooltype1.push( that.typesList[i]);
          }else if (that.typesList[i].level==2){
            that.schooltype2.push( that.typesList[i]);
          }else if (that.typesList[i].level==3){
            that.schooltype3.push( that.typesList[i]);
          }else if (that.typesList[i].level==4){
            that.schooltype4.push( that.typesList[i]);
          }
        }
      })
    },
    //筛选列表
    getDataListInit(){
      let that = this;
      that.types = !this.$route.query.types ? '' : this.$route.query.types ;
      that.area_id = !this.$route.query.area_id ? '' : this.$route.query.area_id ;
      Api.School.getSchoolList({
        page: that.page,
        types: that.types,
        addrsc: that.area_id,
      }).then((res) => {
        if (res.data.code == 1) {
          that.dataList = res.data.data;
        }
      })
    },

    //切换分页
    handleCurrentChange(val) {
      let that = this;
      that.page = val;
      that.getDataListInit();
    },
    handleSizeChanges(val) {
      this.dataList.per_page=val;
      this.onSearch();
    },
    handleCurrentChanges(val) {
      this.dataList.current_page=val;
      this.onSearch();
    },
  },
  mounted() {
    this.getInit();
  },
  watch: {
    '$route' () {
      this.getDataListInit();
    }
  }
}
</script>
<style scoped lang="less">
.filter-section {
  width: 100%;
  padding: 16px;
}

.filter-item {
  margin-bottom: 20px;
}

.filter-content {
  display: flex;
  align-items: flex-start;
}

.filter-label {
  flex-shrink: 0;
  width: 100px;
  color: #606266;
  line-height: 32px;
}

.options-group {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.custom-radio {
  padding: 6px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  color: #606266;
  transition: all 0.3s;
}

.custom-radio:hover {
  color: #409EFF;
  background-color: #f5f7fa;
}

.custom-radio.active {
  color: #409EFF;
  background-color: #ecf5ff;
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
  .filter-content {
    flex-direction: column;
  }

  .filter-label {
    margin-bottom: 8px;
  }

  .options-group {
    gap: 8px;
  }

  .custom-radio {
    padding: 4px 12px;
  }
}
//  院校介绍

.info-card {
  display: flex; /* 使用flexbox进行布局 */
  align-items: center; /* 垂直居中对齐 */
  padding: 16px;
  margin-bottom: 16px; /* 每个卡片之间的间距 */
  background-color: #f9f9f9;
}

.icon img {
  width: 60px; /* 根据需要调整图标大小 */
  height: 60px;
  margin-right: 16px; /* 图标和信息之间的间距 */
}

.info {
  flex-grow: 1; /* 使信息部分占满剩余空间 */
}

.institution-name {
  font-size: 1.5em;
  margin: 0;
}

.location {
  color: #666;
  font-size: 0.9em;
}

.pagination {
  margin-top: 16px; /* 分页组件与卡片之间的间距 */
  display: flex; /* 使用flexbox进行布局 */
  justify-content: center; /* 水平居中 */
}
</style>