<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main class="page">
    <UserBanner></UserBanner>

    <div class="layout cont-box">
      <el-row style="width: 100%;">
        <!-- 菜单 -->
        <userNavs></userNavs>

        <!-- 内容 -->
        <el-col :span="19">
          <div class="main">
<!--            <WelcomeModal  v-if="isFirstTime"></WelcomeModal>-->
            <span class="button-group">
              <el-button type="info" @click="openApplyDialog" plain>申请权益</el-button>
              <el-button type="info" @click="openApplyDialog" icon="el-icon-message" circle></el-button>
            </span>
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1" @click="getByuserprte">助学金卡</el-menu-item>
              <el-menu-item index="2" >会员卡</el-menu-item>
              <el-menu-item index="3" disabled>票</el-menu-item>
              <el-menu-item index="3" disabled>证件</el-menu-item>
            </el-menu>
            <div v-if="activeIndex==1" >
                <div class="coupon-list" v-if="flage">
                  <div v-for="(couponse, index) in couponse.data" :key="index" class="coupon-card" >
                    <div v-if="couponse.preferential.type==1 && couponse.preferential.specifictype==1">
                      <div class="logo-container">
                        <!-- 这里可以放置您的logo图片，使用img标签或者背景图片方式 -->
                        <img src="@/assets/images/logo1.jpg" alt="" class="logo-image" />
                      </div>
                      <div class="coupon-header">
                        <h3>{{ couponse.preferential.name}}</h3>
                        <span class="coupon-expire">有效期至 {{ couponse.preferential.outtime}}</span>
                      </div>
                      <div class="coupon-body">
                        <p>{{couponse.preferential.threshold}}</p>
                      </div>
                      <div class="coupon-footer">
                        <span class="coupon-discount">{{ couponse.preferential.reduction }} ￥</span>
                        <el-badge :value="`${couponse.countCa}`" :max="99" class="item">
                          <button @click="redeemCoupon(couponse)">立即使用</button>
                        </el-badge>
                      </div>
                    </div>
                    <div v-if="couponse.preferential.type==1 && couponse.preferential.specifictype==2">
                      <div class="logo-container">
                        <!-- 这里可以放置您的logo图片，使用img标签或者背景图片方式 -->
                        <img src="@/assets/images/logo1.jpg" alt="" class="logo-image" />
                      </div>
                      <div class="coupon-header">
                        <h3>{{ couponse.preferential.name}}</h3>
                        <span class="coupon-expire">有效期至 {{ couponse.preferential.outtime}}</span>
                      </div>
                      <div class="coupon-body">
                        <p>{{couponse.preferential.threshold}}</p>
                      </div>
                      <div class="coupon-footer">
                        <span class="coupon-discount">{{ couponse.preferential.reduction }}% OFF</span>
                        <el-badge :value="`${couponse.countCa}`" :max="99" class="item">
                          <button @click="redeemCoupon(couponse)">立即使用</button>
                        </el-badge>
                      </div>
                    </div>
                  </div>
                </div>
              <el-pagination
                  @size-change="handleSizeChanges"
                  @current-change="handleCurrentChanges"
                  :current-page="couponse.current_page"
                  :page-sizes="[2, 5, 10, 20]"
                  :page-size="couponse.per_page"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="couponse.total">
              </el-pagination>
            </div>
            <div v-if="activeIndex==2" >
              <div class="membership-cards">
                <div
                    v-for="card in cardData.data"
                    :key="card.id"
                    class="membership-card"
                >
                  <img :src="`https://admin.zhongguojiaoyunet.com/${card.packaimage}`" :title="card.package_name" :alt="card.package_name" class="membership-card-image" />
                </div>
              </div>
              <el-pagination
                  @size-change="handleSizeChangescardData"
                  @current-change="handleCurrentChangescardData"
                  :current-page="cardData.current_page"
                  :page-sizes="[2, 5, 10, 20]"
                  :page-size="cardData.per_page"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="cardData.total">
              </el-pagination>
            </div>
            <!-- 申请权益的弹窗 -->
            <el-dialog
                title="申请权益"
                :visible.sync="applyDialogVisible"
                width="95%"
                :before-close="handleClose">
              <el-form ref="applyForm" :model="applyForm" label-width="120px">
                <el-form-item label="申请人签名">
                  <el-input v-model="applyForm.sign" placeholder="请输入申请人签名"></el-input>
                </el-form-item>
                <el-form-item label="申请人填表日期">
                  <el-date-picker v-model="applyForm.date" type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="姓名">
                  <el-input v-model="applyForm.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                  <el-select v-model="applyForm.gender" placeholder="请选择性别">
                    <el-option label="男" value="男"></el-option>
                    <el-option label="女" value="女"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="民族">
                  <el-input v-model="applyForm.ethnicity" placeholder="请输入民族"></el-input>
                </el-form-item>
                <el-form-item label="出生日期">
                  <el-date-picker v-model="applyForm.birthDate" type="date" placeholder="选择出生日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="毕业院校">
                  <el-input v-model="applyForm.graduationInstitution" placeholder="请输入毕业院校"></el-input>
                </el-form-item>
                <el-form-item label="政治面貌">
                  <el-input v-model="applyForm.politicalStatus" placeholder="请输入政治面貌"></el-input>
                </el-form-item>
                <el-form-item label="报考院校">
                  <el-input v-model="applyForm.applyingInstitution" placeholder="请输入报考院校"></el-input>
                </el-form-item>
                <el-form-item label="专业">
                  <el-input v-model="applyForm.major" placeholder="请输入专业"></el-input>
                </el-form-item>
                <el-form-item label="学制">
                  <el-input v-model="applyForm.academicSystem" placeholder="请输入学制"></el-input>
                </el-form-item>
                <el-form-item label="生源地">
                  <el-input v-model="applyForm.origin" placeholder="请输入生源地"></el-input>
                </el-form-item>
                <el-form-item label="开户行">
                  <el-input v-model="applyForm.bankAccountNumber" placeholder="请输入开户行"></el-input>
                </el-form-item>
                <el-form-item label="助学金申请卡号">
                  <el-input v-model="applyForm.scholarshipApplicationNumber" placeholder="请输入助学金申请卡号"></el-input>
                </el-form-item>
                <el-form-item label="身份证号码">
                  <el-input v-model="applyForm.idNumber" placeholder="请输入身份证号码"></el-input>
                </el-form-item>
                <el-form-item label="身份证地址">
                  <el-input v-model="applyForm.idAddress" placeholder="请输入身份证地址"></el-input>
                </el-form-item>
                <el-form-item label="家庭成员情况">
                  <el-button type="primary" @click="handleAddFamilyMember">添加家庭成员</el-button>
                  <el-table :data="applyForm.familyMembers" style="width: 100%; margin-top: 10px;">
                    <el-table-column prop="name" label="姓名" width="180">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.name" placeholder="请输入姓名"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="relationship" label="学员关系" width="180">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.relationship" placeholder="请输入关系"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="contact" label="联系方式" width="180">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.contact" placeholder="请输入联系方式"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="workUnit" label="工作或学习单位" width="180">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.workUnit" placeholder="请输入工作或学习单位"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                      <template slot-scope="scope">
                        <el-button type="text" @click="handleClearFamilyMember(scope.$index)">清空</el-button>
                        <el-button type="text" @click="handleRemoveFamilyMember(scope.$index)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                <el-form-item label="户籍性质">
                  <el-select v-model="applyForm.householdType" placeholder="请选择户籍性质">
                    <el-option label="农业" value="农业"></el-option>
                    <el-option label="非农业" value="非农业"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="主要收入来源">
                  <el-input v-model="applyForm.mainIncomeSource" placeholder="请输入主要收入来源"></el-input>
                </el-form-item>
                <el-form-item label="家庭住址">
                  <el-input v-model="applyForm.familyAddress" placeholder="请输入家庭住址"></el-input>
                </el-form-item>
                <el-form-item label="邮编">
                  <el-input v-model="applyForm.postalCode" placeholder="请输入邮编"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input v-model="applyForm.contactPhone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="家庭人口总数">
                  <el-input v-model="applyForm.totalFamilyMembers" placeholder="请输入家庭人口总数"></el-input>
                </el-form-item>
                <el-form-item label="家庭年收入">
                  <el-input v-model="applyForm.annualFamilyIncome" placeholder="请输入家庭年收入"></el-input>
                </el-form-item>
                <el-form-item label="人均年收入">
                  <el-input v-model="applyForm.averageAnnualIncome" placeholder="请输入人均年收入"></el-input>
                </el-form-item>
                <el-form-item label="申请助学金事由">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入申请理由"
                      v-model="applyForm.reason">
                  </el-input>
                </el-form-item>
                <el-form-item label="申请人签字">
                  <el-input v-model="applyForm.sign" placeholder="请输入申请人签字"></el-input>
                </el-form-item>
                <el-form-item label="招生老师意见">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入招生老师意见"
                      v-model="applyForm.admissionTeacherOpinion">
                  </el-input>
                </el-form-item>
                <el-form-item label="招生老师签字">
                  <el-input v-model="applyForm.admissionTeacherSignature" placeholder="请输入招生老师签字"></el-input>
                </el-form-item>
                <el-form-item label="设奖单位评审意见">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入设奖单位评审意见"
                      v-model="applyForm.awardingUnitOpinion">
                  </el-input>
                </el-form-item>
                <el-form-item label="设奖单位负责人签字">
                  <el-input v-model="applyForm.awardingUnitSignature" placeholder="请输入设奖单位负责人签字"></el-input>
                </el-form-item>
                <el-form-item label="经办人审核">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入经办人审核意见"
                      v-model="applyForm.processingPersonOpinion">
                  </el-input>
                </el-form-item>
                <el-form-item label="评审单位意见">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入评审单位意见"
                      v-model="applyForm.reviewingUnitOpinion">
                  </el-input>
                </el-form-item>
                <el-form-item label="评审单位负责人签字">
                  <el-input v-model="applyForm.reviewingUnitSignature" placeholder="请输入评审单位负责人签字"></el-input>
                </el-form-item>
                <el-form-item label="照片">
                  <el-upload
                      ref="upload"
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                      :on-change="handleChange">
                      <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-button type="primary" @click="submitUpload">上传照片</el-button>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="applyDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitApply">提 交</el-button>
              </span>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
    </div>
  </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
import WelcomeModal from '@/components/user/WelcomeModal.vue';
export default {
  components: {
    UserBanner,
    userNavs,
    WelcomeModal
  },
  data() {
    return {
      activeIndex: '0',
      isFirstTime: true,
      couponse: {
        per_page: 2,
        current_page: 1,
        data: []
      },
      flage:false,
      cardData: {
        data:[],
        per_page: 2,
        current_page: 1,
      },
      applyDialogVisible: false,
      applyForm: {
        sign: '',
        date: '',
        name: '',
        gender: '',
        ethnicity: '',
        birthDate: '',
        graduationInstitution: '',
        politicalStatus: '',
        applyingInstitution: '',
        major: '',
        academicSystem: '',
        origin: '',
        bankAccountNumber: '',
        scholarshipApplicationNumber: '',
        idNumber: '',
        idAddress: '',
        familyMembers: [
          {
            name: '',
            relationship: '',
            contact: '',
            workUnit: '',
            status:0,
          }
        ],
        householdType: '',
        mainIncomeSource: '',
        familyAddress: '',
        postalCode: '',
        contactPhone: '',
        totalFamilyMembers: '',
        annualFamilyIncome: '',
        averageAnnualIncome: '',
        reason: '',
        admissionTeacherOpinion: '',
        admissionTeacherSignature: '',
        awardingUnitOpinion: '',
        awardingUnitSignature: '',
        processingPersonOpinion: '',
        reviewingUnitOpinion: '',
        reviewingUnitSignature: '',
        status:0,
      },
      selectedFile:null,
    }
  },
  methods: {
    handleSizeChanges(val){
      this.couponse.per_page=val;
      this.getByuserprte();
    },
    handleCurrentChanges(val){
      this.couponse.current_page=val;
      this.getByuserprte();
    },
    handleSizeChangescardData(val){
      this.cardData.per_page=val;
      this.getcardpackage();
    },
    handleCurrentChangescardData(val){
      this.cardData.current_page=val;
      this.getcardpackage();
    },
    getcardpackage(){
      Api.Cardpackage.getCardpackage({
        userID:this.$store.state.user_info.id,
        per_page:this.cardData.per_page,
        current_page:this.cardData.current_page,
      }).then((res)=>{
        this.cardData=res.data.data;
      })
    },
    // Inside your methods:
    getByuserprte() {
      let that = this;
      Api.Userprefe.Realpolicy({
        userName: that.$store.state.user_info.nickname,
        per_page: that.couponse.per_page,
        current_page: that.couponse.current_page,
      }).then(res => {
        // Directly assign new data to trigger reactivity
        that.couponse = {
          ...res.data.data, // Spread operator to create a new object reference
          per_page: that.couponse.per_page,
          current_page: that.couponse.current_page,
        };

        let promises = [];
        for (let i = 0; i < that.couponse.data.length; i++) {
          // Temporarily nullify preferential to indicate loading
          that.$set(that.couponse.data, i, {
            ...that.couponse.data[i],
            preferential: null
          }); // Use $set for Vue to track changes in array elements

          promises.push(
              Api.Preferential.Realpolicy({ id: that.couponse.data[i].preferentialid }).then(res => {
                // Ensure Vue knows about the nested property change
                that.$set(that.couponse.data[i], 'preferential', res.data);
              })
          );
        }

        Promise.all(promises).then(() => {
          // Force a re-render by toggling a data property
          that.flage = !that.flage;
        });
      });
    },
    handleSelect(key, keyPath) {
      this.activeIndex=key;
    },
    redeemCoupon(coupon) {
      this.$confirm('是否使用'+coupon.preferential.name+'优惠卷', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // let count=null;
        // let statevv=null;
        // if ((coupon.countCa-1)==0){
        //   count=0;
        //   statevv=3;
        // }else {
        //   count=(coupon.countCa-1);
        //   statevv=0;
        // }
        // Api.Userprefe.updateRealpolicy({
        //   id:coupon.id,
        //   countCa:count,
        //   state:statevv,
        // }).then(res=>{
        //   this.getByuserprte();
        // })
        // this.$message({
        //   type: 'success',
        //   message: '已使用!'
        // });
        if (coupon.preferential.type==1){
          if (coupon.preferential.specifictype==2){
              this.$router.push({ path: '/school', query: { id: coupon.preferential.specifictid } });
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    openApplyDialog() {
      this.applyDialogVisible = true;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    submitApply() {
      console.info(this.applyForm);
      this.$message.success("申请已提交，我们会尽快与您联系！");
      this.applyDialogVisible = false;
      this.applyForm = {};
    },
    handleAddFamilyMember() {
      this.applyForm.familyMembers.push({
        name: '',
        relationship: '',
        contact: '',
        workUnit: '',
        status:0,
      });
    },
    handleRemoveFamilyMember(index) {
      this.applyForm.familyMembers.splice(index, 1);
    },
    handleChange(file, fileList) {
      console.info(file);
      this.selectedFile = file; // 存储选择的文件
    },
    submitUpload() {
      if (!this.selectedFile) {
        this.$message.error('请先选择一个文件');
        return;
      }
      // 在这里执行上传逻辑，例如使用 axios 发送请求
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      Api.Scholarshipapplication.prupload(formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 必须设置
          'Authorization': 'Bearer your-token' // 如果需要身份验证
        }
      }).then(response => {
        console.log(response);
        this.$message.success('照片上传成功');
        // 清空已选择的文件
        this.selectedFile = null;
        // 可以在这里更新 applyForm.photoUrl 为服务器返回的照片 URL
        // 例如：this.applyForm.photoUrl = response.data.url;
      }).catch(error => {
        console.error(error);
        this.$message.error('照片上传失败');
      });
    }
  },
  mounted() {
    this.isFirstTime = true;
    this.getcardpackage();
  },
  created() {
    Api.Scholarshipapplication.getinIDgetALL({
      id:1,
    }).then(res=>{
      console.info(res);
    })
  }

}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/

.page{
  // padding: 20px 0;
  background: #f7f8fa;
  min-height: calc(100vh - 300px);
}

.cont-box{
  padding: 50px 0;
  display: flex;
}

.main{
  margin-left: 20px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 30px 20px;
}

//权益申请样式
.button-group {
  display: flex;
  align-items: center;
}

.button-group .el-button {
  margin-left: -15px; /* 设置负内边距 */
}

.coupon-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.coupon-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
}

.coupon-card:hover {
  transform: translateY(-5px);
}

.coupon-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.coupon-expire {
  float: right;
  color: #999;
}

.coupon-body {
  padding: 20px;
}

.coupon-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #fff;
}

.coupon-discount {
  color: #d4235c;
  font-weight: bold;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.container {
  position: relative; /* 使内部的定位元素（如logo）能够相对于此容器定位 */
}

.logo-container {
  position: absolute;
  top: 10px; /* 调整logo与顶部的距离 */
  right: 20px; /* 调整logo与右侧的距离 */
  z-index: 1; /* 确保logo在列表卡片之上 */
}

.logo-image {
  width: 50px; /* 根据实际logo大小调整 */
  height: auto;
  border-radius:50%
}
//会员卡展示页面
.membership-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 20px;
  padding: 20px;
}

.membership-card {
  width: 300px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.membership-card-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

</style>