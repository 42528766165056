<template>
  <div class="video-conference">
    <!-- 顶部状态栏 -->
    <div class="top-bar">
      <div class="left">
        <span class="el-icon-more">会议详情&nbsp;&nbsp;</span>
        <span class="time">
          <span class="room-id">
            房间号: {{ meetings.id }}
          </span>
          <!-- 分享按钮 -->
          <button class="share-room-button" @click="shareRoom">
            <i class="el-icon-share"></i> 分享
          </button>
          <!-- 分享成功提示 -->
          <span class="share-success" v-if="shareSuccess">
            房间号已复制，可分享给他人
          </span>
          &nbsp;&nbsp;
          {{ currentTime }}
        </span>
      </div>
      <div class="right">
        <!-- 可以根据需要添加其他控制按钮 -->
      </div>
    </div>

    <!-- 中间会议内容区域 -->
    <div class="content-area">
      <div class="current-speaker">
        <span>正在讲话:</span>
      </div>
      <div class="meeting-content">
        <!-- 展示本地视频 -->
        <div class="local-video-container">
          <video ref="localVideo" autoplay muted></video>
          <!-- 本地视频占位图标 -->
          <div class="local-video-placeholder" v-if="!localStream">
            <svg xmlns="http://www.w3.org/2000/svg" width="80%" height="60%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M22 8.06l-6.34-3.17a2 2 0 0 0-2.62 0L2 8.06V16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-3.94a2 2 0 0 0-.58-1.42l-6.34 3.17a2 2 0 0 0 0 3.44l6.88 3.44a2 2 0 0 0 2.22 0l4-2a2 2 0 0 0 0-3.44l-6.88-3.44zM8 14l2 2 4-4"></path>
            </svg>
          </div>
        </div>

        <!-- 展示远程视频 -->
        <div class="remote-videos">
          <div v-for="(stream, userId) in remoteStreams" :key="userId" class="remote-video-item">
            <video :ref="'remoteVideo' + userId" autoplay></video>
            <span>{{ userId }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部功能按钮栏 -->
    <div class="bottom-bar">
      <div class="left">
        <input type="text" placeholder="说点什么..." />
        <button class="emoji-button">😊</button>
      </div>
      <div class="right">
        <button class="video-button" @click="toggleVideo">开启/关闭视频</button>
        <button class="share-button">共享屏幕</button>
        <button class="invite-button">邀请<span class="el-icon-circle-plus"></span></button>
        <button class="members-button">成员({{quantity}})</button>
        <button class="chat-button">聊天</button>
        <button class="record-button">录制</button>
        <button class="end-button" @click="endMeeting">结束会议</button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import io from "socket.io-client";

export default {
  name: "VideoConference",
  data() {
    return {
      currentTime: "", // 用于存储当前时间
      hostjojo: null,
      quantity: 0,
      meetings: null,
      localStream: null, // 本地视频流
      peerConnections: {}, // 存储与每个参会者的对等连接
      remoteStreams: {}, // 存储远程用户的视频流
      socket: null, // WebSocket连接
      videoEnabled: true, // 控制视频开关状态
      shareSuccess: false, // 分享成功提示状态
    };
  },
  methods: {
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString(); // 获取当前时间并格式化
    },
    // 获取本地媒体流
    async getMediaStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 640 },
            height: { ideal: 480 }
          },
          audio: true
        });
        this.localStream = stream;
        this.setupLocalVideo(stream); // 设置本地视频
        return stream;
      } catch (err) {
        console.error("获取媒体设备失败:", err);
        if (err.name === "PermissionDeniedError") {
          this.$message.error("您拒绝了摄像头和麦克风的权限，请允许权限后重试");
        } else {
          this.$message.error("无法获取摄像头和麦克风权限，请检查设置");
        }
      }
    },
    // 设置本地视频
    setupLocalVideo(stream) {
      const videoElement = this.$refs.localVideo;
      videoElement.srcObject = stream;
      videoElement.play();
    },
    // 创建对等连接
    createPeerConnection(userId) {
      const pc = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }] // 使用Google的STUN服务器
      });

      // 添加本地流到对等连接
      this.localStream.getTracks().forEach(track => {
        pc.addTrack(track, this.localStream);
      });

      // 监听ICE候选者并发送给对方
      pc.onicecandidate = event => {
        if (event.candidate) {
          this.sendIceCandidate(userId, event.candidate);
        }
      };

      // 当接收到远程流时，展示远程视频
      pc.ontrack = event => {
        this.setupRemoteVideo(userId, event.streams[0]);
      };

      this.peerConnections[userId] = pc;
      return pc;
    },
    // 创建offer并发送给对方
    async createOffer(userId) {
      const pc = this.createPeerConnection(userId);
      try {
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);

        // 将offer发送给对方，通过信令服务器
        this.socket.emit("offer", {
          to: userId,
          meetingId: this.$route.query.id,
          offer: offer
        });
      } catch (err) {
        console.error("创建offer失败:", err);
      }
    },
    // 处理收到的offer并创建answer
    async handleOffer(data) {
      const pc = this.createPeerConnection(data.from);
      try {
        await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);

        // 将answer发送回对方
        this.socket.emit("answer", {
          to: data.from,
          meetingId: this.$route.query.id,
          answer: answer
        });
      } catch (err) {
        console.error("处理offer失败:", err);
      }
    },
    // 处理收到的answer
    async handleAnswer(data) {
      const pc = this.peerConnections[data.from];
      if (pc) {
        try {
          await pc.setRemoteDescription(new RTCSessionDescription(data.answer));
        } catch (err) {
          console.error("处理answer失败:", err);
        }
      }
    },
    // 添加ICE候选者
    addIceCandidate(data) {
      const pc = this.peerConnections[data.from];
      if (pc) {
        try {
          pc.addIceCandidate(new RTCIceCandidate(data.candidate));
        } catch (err) {
          console.error("添加ICE候选者失败:", err);
        }
      }
    },
    // 发送ICE候选者
    sendIceCandidate(userId, candidate) {
      this.socket.emit("iceCandidate", {
        to: userId,
        meetingId: this.$route.query.id,
        candidate: candidate
      });
    },
    // 设置远程视频
    setupRemoteVideo(userId, stream) {
      this.remoteStreams[userId] = stream;
      const videoElement = this.$refs['remoteVideo' + userId];
      if (videoElement) {
        videoElement.srcObject = stream;
      }
    },
    // 切换视频开关
    toggleVideo() {
      this.videoEnabled = !this.videoEnabled;
      if (this.localStream) {
        this.localStream.getVideoTracks().forEach(track => {
          track.enabled = this.videoEnabled;
        });
      }
    },
    // 结束会议
    endMeeting() {
      this.socket.emit("endMeeting", {
        meetingId: this.$route.query.id
      });
      this.$router.push("/"); // 返回首页
    },
    // 分享房间
    shareRoom() {
      // 复制房间号到剪贴板
      const meetingId = this.meetings.id;
      navigator.clipboard.writeText(meetingId).then(() => {
        this.shareSuccess = true;
        // 2秒后隐藏提示
        setTimeout(() => {
          this.shareSuccess = false;
        }, 2000);
      }).catch(err => {
        console.error("复制房间号失败:", err);
        this.$message.error("复制房间号失败，请手动复制房间号分享");
      });
    }
  },
  mounted() {
    this.updateTime(); // 初始化时间
    setInterval(this.updateTime, 1000); // 每秒更新一次时间
  },
  created() {
    let that = this;
    // 获取会议信息
    Api.Meetings.getbyid({
      id: that.$route.query.id
    }).then(res => {
      that.meetings = res.data.result;
    });

    // 获取本地媒体流
    this.getMediaStream();

    // 连接信令服务器
    this.socket = io("http://your-signaling-server-url");
    this.socket.on("connect", () => {
      console.log("已连接到信令服务器");
      // 发送加入会议的消息
      this.socket.emit("join", {
        meetingId: this.$route.query.id,
        userId: this.$store.state.user_info.id
      });
    });

    // 处理收到的消息
    this.socket.on("offer", data => {
      this.handleOffer(data);
    });

    this.socket.on("answer", data => {
      this.handleAnswer(data);
    });

    this.socket.on("iceCandidate", data => {
      this.addIceCandidate(data);
    });

    this.socket.on("userJoined", data => {
      // 当有新用户加入时，向新用户发送offer
      this.createOffer(data.userId);
      this.quantity++; // 更新成员数量
    });

    this.socket.on("userLeft", data => {
      // 当有用户离开时，移除相关的连接和视频流
      if (this.peerConnections[data.userId]) {
        this.peerConnections[data.userId].close();
        delete this.peerConnections[data.userId];
      }
      if (this.remoteStreams[data.userId]) {
        delete this.remoteStreams[data.userId];
      }
      this.quantity--; // 更新成员数量
    });

    this.socket.on("endMeeting", () => {
      this.$router.push("/"); // 返回首页
    });
  },
  beforeDestroy() {
    clearInterval(this.updateTime); // 在组件销毁时清除定时器
    if (this.socket) {
      this.socket.disconnect(); // 断开信令服务器连接
    }
    // 释放媒体资源
    if (this.localStream) {
      this.localStream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }
};
</script>

<style scoped>
.video-conference {
  width: 83%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.content-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.current-speaker {
  background-color: #e9f5ff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}

.meeting-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.local-video-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 150px;
  height: 120px;
  z-index: 10;
  background-color: #000;
  border-radius: 4px;
  overflow: hidden;
}

.local-video-container video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 确保视频完全显示 */
}

/* 本地视频占位图标样式 */
.local-video-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: white;
  font-size: 12px;
}

.local-video-placeholder svg {
  width: 50px;
  height: 50px;
  stroke: white;
}

.remote-videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.remote-video-item {
  margin: 10px;
  width: calc(33.33% - 20px);
  min-width: 200px;
  height: calc(33.33% - 20px);
  background-color: #000;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.remote-video-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remote-video-item span {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
}

button {
  padding: 10px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #f0f2f5;
}

.video-button {
  background-color: #2196f3;
  color: white;
}

.share-button {
  background-color: #4caf50;
  color: white;
}

.invite-button {
  background-color: #ff9800;
  color: white;
}

.members-button {
  background-color: #9c27b0;
  color: white;
}

.chat-button {
  background-color: #00bcd4;
  color: white;
}

.record-button {
  background-color: #795548;
  color: white;
}

.end-button {
  background-color: #f44336;
  color: white;
}

/* 分享按钮样式 */
.share-room-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 12px;
}

.share-room-button:hover {
  background-color: #388e3c;
}

/* 分享成功提示样式 */
.share-success {
  color: #4caf50;
  font-size: 12px;
  margin-left: 10px;
}

/* 调整右边元素向左移动 */
.right {
  display: flex;
  align-items: self-start;
  white-space: nowrap;
}

/* 在你的 CSS 文件中或者<style>标签中 */
.room-id {
  font-size: 18px;
  font-weight: bold;
  color: #FF5733; /* 橙红色 */
  background-color: #FFDAB9; /* 淡橙色背景 */
  padding: 2px 5px;
  border-radius: 3px;
}
</style>