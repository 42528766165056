<!-- eslint-disable vue/multi-word-component-names -->
<template>
   <div class="layout-navs">
        <div class="layout navs-box">
            <router-link class="item active" to="/">首页</router-link>
            <a class="item" target="_blank" href="http://wx.zhongguojiaoyunet.com">网课</a>
            <a class="item" target="_blank" href="https://gaokao.chsi.com.cn/zyck/zy/">志愿填报</a>
            <router-link class="item" to="#">点播课程</router-link>
            <router-link class="item" to="/schools">院校信息</router-link>
            <router-link class="item"  to="/occupationallevelIndex">职业资格</router-link>
<!--          <a class="item" href="#"  @click="handleLoginClick1">万课联盟平台</a>-->
            <a class="item" href="#"  @click="handleLoginClick">勾正教育平台</a>
        </div>
   </div>
</template>
<script>
import Api from "@/Api.js";
import CryptoJS from 'crypto-js';
export default {
    data() {
        return {
        }
    },
    methods: {
      handleLoginClick(event) {
        event.preventDefault(); // 阻止默认行为
        const secretKey = 'your-256-bit-secret-key';

        // 获取用户信息
        Api.Config.indexwx({
          areaid: this.$store.state.LocationPCity[0],
        }).then((res) => {
          if (res.data.code == 1) {
            const user = this.encryptMessage(res.data.data.userinfo, secretKey);
            //构建查询字符串
            const queryParams = new URLSearchParams();
            queryParams.append('user', user);
            // 设置重定向的URL
            const redirectUrl = `https://www.womaster.cn/gzedu/#/blank?${queryParams.toString()}`;

            // 执行页面跳转
            window.location.href = redirectUrl;
          }
        });
      },
      // handleLoginClick1(event) {
      //   event.preventDefault(); // 阻止默认行为
      //   const secretKey = 'your-256-bit-secret-key';
      //
      //   // 获取用户信息
      //   Api.Config.indexwx({
      //     areaid: this.$store.state.LocationPCity[0],
      //   }).then((res) => {
      //     if (res.data.code == 1) {
      //       const user = this.encryptMessage(res.data.data.userinfo, secretKey);
      //       //构建查询字符串
      //       const queryParams = new URLSearchParams();
      //       queryParams.append('user', user);
      //       // 设置重定向的URL
      //       const redirectUrl = `https://code.iai.show/h5/h5_/index?${queryParams.toString()}`;
      //
      //       // 执行页面跳转
      //       window.location.href = redirectUrl;
      //     }
      //   });
      // },
      encryptMessage(message, secretKey) {
        // 将对象转换为 JSON 字符串
        const messageString = JSON.stringify(message);

        // 加密 JSON 字符串
        const ciphertext = CryptoJS.AES.encrypt(messageString, secretKey).toString();
        console.info(ciphertext);
        return ciphertext;
      },
      // 解密函数
      decryptMessage(ciphertext, secretKey) {
        // 使用CryptoJS解析加密的字符串
        const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
        // 将解密后的WordArray对象转换为UTF-8字符串
        const decryptedMessage = bytes.toString(CryptoJS.enc.Utf8);

        // 尝试将字符串转换为JSON对象
        try {
          const messageObject = JSON.parse(decryptedMessage);
          return messageObject;
        } catch (error) {
          // 如果解析失败，打印错误信息
          console.error('Decryption error:', error);
          return null;
        }
      },
    },
}
</script>
 
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.layout-navs {
    width: 100%;
    height: 50px;
    position: relative;
    z-index: 10;
    background: #D11A2D;

    .navs-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navs-box .item{
        flex: 1;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        height: 50px;
        line-height: 50px;
    }

    .navs-box .item:hover{
        background: #c0130d;
    }
}

</style>