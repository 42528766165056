<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div id="header">
        <div class="layout header">
          <router-link to="/" class="logo">
              <img src="../assets/icon/logo1.jpg" class="logo-img" alt="">
          </router-link>
          <div class="search-box">
            <el-badge :value="numberofmessages" class="item">
              <span  class="el-icon-chat-dot-round" style="font-size: 24px;"></span>
            </el-badge>
          </div>
          <div class="container">
            <el-dropdown>
                    <span class="el-dropdownsaa">
                          <span class="Nikenames">
                          <span @click="getByperson()" >
                            <img src="../assets/images/65920ed48acd9_260.jpg" class="avatarframe" alt="">
                            <img :src="UserInfo.avatar" class="avatar" alt="">
                          </span>
                          <span @click="getByperson()" >{{UserInfo.nickname}}</span>
<!--                          <span class="el-icon-switch-button" ></span>-->
                        </span>
                    </span>
              <div class="pastions">
                <span class="pastionss1" @mouseover="expandCascader">
                  <img width="18px" class="el-icon-location-information"  @click="herfAddRs()"   src="../assets/icon/position-ico.png" alt="">
                </span>
                <el-cascader
                    ref="myCascader"
                    placeholder="选择地址"
                    :options="options"
                    @change="handleLocation1"
                    filterable class="cas"
                >
                </el-cascader>
                <span>
                {{LocationPCity}}
              </span>

                <img class="el-icon-refresh" width="15px" @click="GetCurrentAddress1()" src="../assets/icon/position-qh.png" alt="">
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="code-box">
                    <div class="img-box">
                      <img class="img1" :src="wx_code" alt="">
                      <span>官方公众号</span>
                    </div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </div>

    </div>
</template>
<script>
import Api from "@/Api.js";
import {loadBMap} from "@/map";
export default {
    data() {
        return {
            UserInfo:{},
          LocationPCity: "正在进行定位",
          wx_code: '',
          options:[],
          numberofmessages:12,
        }
    },
    methods: {
      handleLocation1(area){

        function findLabels(options, values, labels = [], index = 0) {
          if (index < values.length && options) {
            const option = options.find(opt => opt.value === values[index]);
            if (option) {
              labels.push(option.label);
              return findLabels(option.children || [], values, labels, index + 1);
            }
          }
          return labels;
        }
        // 使用此函数找到并打印出所有选择项的标签
        const selectedLabels = findLabels(this.options, area);
        console.info(selectedLabels);
        let jutund=null;
        if (selectedLabels[2].includes('市')) {
          jutund = "市";
        }
        area[4]=jutund;
        this.$store.commit("setLocationPCity",area);
        this.LocationPCity=selectedLabels[0]+' '+selectedLabels[1]+' '+selectedLabels[2];
        this.GetInitData();
      },
      herfAddRs(){
        this.$router.push("/Selectgaode");
      },
      // 第一次查询地址
      GetCurrentAddress(){
        const geolocation = new BMap.Geolocation();
        let _this = this;
        geolocation.getCurrentPosition(function getinfo(position){
          let country = "中国"; // 获取国家信息
          let city = position.address.city;             //获取城市信息
          let province = position.address.province;     //
          let district=position.address.district;
          this.location=position;
          _this.getbynamesuuArea(position.address.district);
          _this.LocationPCity =country+' '+province + ' '  + city+' '+district;
          let area=[];
          Api.Area.getbyname({
            country:country,
            province:province,
            city:city,
            district:district
          }).then(res=>{
            area=res.data.data.map(person => person.id);
            if (city.includes('市')) {
              area[4] = "市";
            }
            let tiao=[];
            tiao[0]=area[2];
            tiao[1]=area[0];
            tiao[2]=area[1];
            tiao[3]=area[3];
            tiao[4]=area[4];
            _this.$store.commit("setLocationPCity",tiao);
            _this.GetInitData();
          })
        }, function(e) {
          _this.LocationPCity = "定位失败"
        }, {provider: 'baidu'});
      },
      // 重新查
      GetCurrentAddress1(){
        const geolocation = new BMap.Geolocation();
        let _this = this
        _this.LocationPCity='重新定位中'
        geolocation.getCurrentPosition(function getinfo(position){
          let country = "中国"; // 获取国家信息
          let city = position.address.city;             //获取城市信息
          let province = position.address.province;     //获取省份信息
          let district=position.address.district;
          _this.LocationPCity =country+' '+province + ' '  + city+' '+district;
          let area=[];
          Api.Area.getbyname({
            country:country,
            province:province,
            city:city,
            district:district
          }).then(res=>{
            area=res.data.data.map(person => person.id);
            if (city.includes('市')) {
              area[4] = "市";
            }
            let tiao=[];
            tiao[0]=area[2];
            tiao[1]=area[0];
            tiao[2]=area[1];
            tiao[3]=area[3];
            tiao[4]=area[4];
            _this.$store.commit("setLocationPCity",tiao);
            _this.GetInitData();
          })
        }, function(e) {
          _this.LocationPCity = "定位失败"
        }, {provider: 'baidu'});
      },
      getbynamesuuArea(areaName){
        Api.Area.getbynames({
          name: areaName
        }).then((res) => {
          this.Areas=res.data;
        })
      },
      GetInitData() {
        let that = this;
        if (that.$store.state.LocationPCity!=null){
          Api.Config.indexwx({
            areaid:this.$store.state.LocationPCity[1],
          }).then((res) => {
            if (res.data.code == 1) {
              that.UserInfo = res.data.data.userinfo;
              that.$store.state.user_info=that.UserInfo;
              that.wx_code = res.data.data.wx_code;
            }
          })
          Api.Area.areaOptions().then(res=>{
            this.options=res.data.data;
          })
        }

      },
      getByperson(){
        this.$router.push("/person");
      },
      expandCascader(){
        // 尝试通过模拟点击事件来展开Cascader
        this.$nextTick(() => {
          const cascaderInput = this.$refs.myCascader.$el.querySelector('.el-input__inner');
          if (cascaderInput) {
            // 触发输入框的点击事件以尝试展开Cascader
            cascaderInput.click();
          }
        });
      },
    },

  created() {

  },
  mounted() {
        this.keyword = this.$route.query.keywords;
        loadBMap("N35PGYUGCn36HZmndjVAunquFH69o5Mg").then(()=>{
          this.GetCurrentAddress();
        })
    },
    watch: {
        '$route' () {
            this.keyword = this.$route.query.keywords;
        }
    }
}
</script>
 
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
#header{
    width: 100%;
    position: relative;
    z-index: 10;
    background: #ffffff;
}
.header {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-box{
        display: flex;
        align-items: center;
    }

  .logo-img{
    width: 290px;
    height: 200px;
  }
}

.Nikenames{
  font-size: 22px;
  display: flex;
  align-items: center; /* 使垂直居中 */
  justify-content: flex-start; /* 或 'center' 根据需要水平居中 */
}

.img1{
  width: 50px;
  height: 50px;
}

/* 确保外部容器（比如一个包裹这两个元素的div）相对定位或绝对定位，以便内部的绝对定位生效 */
.container {
  position: relative;
}

/* 调整.el-dropdown使其包含.dropdown-menu时高度适应内容，确保.dropdown-menu显示在正确的位置 */
.el-dropdownsaa {
  display: inline-block; /* 或者其他适合的display值，保证高度能自适应 */
}

/* 设置.pastions为绝对定位，并相对于其最近的定位祖先（这里假设是.container）定位 */
.pastions {
  position: absolute;
  left: -30px; /* 根据需要调整水平位置，这里设置为左对齐 */
  top: 80px;
  transform: translateY(-100%); /* 微调，如果有需要，向上微调以完美对齐 */
}

.pastionss1{
  position: absolute;
  left: -20px; /* 根据需要调整水平位置，这里设置为左对齐 */
  top: 65px;
  transform: translateY(-100%); /* 微调，如果有需要，向上微调以完美对齐 */
}

.cas{
  top: 120px;
  transform: translateY(-100%); /* 微调，如果有需要，向上微调以完美对齐 */
}

//头像框
.avatarframe {
    position: absolute;
    top: 35%;
    left: 16%;
    width: 50px; /* 与头像框的宽度相同 */
    height: 60px; /* 与头像框的高度相同 */
    border-radius: 50%;
    transform: translate(-50%, -50%); /* 中心对齐 */
    z-index: 2; /* 确保用户头像在头像框前面 */
    opacity: 0.5; /* 确保用户头像完全可见 */
}

.avatar{
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-bottom: 10px;
  z-index: 2; /* 增加用户头像的z-index */
  opacity: 0.8; /* 设置透视效果 */
}

.el-icon-chat-dot-round:hover {
  cursor: grab; /* 鼠标悬浮时变为抓取状态 */
}
</style>