<template>
  <transition>
    <div class="popup-container">
      <button class="close-button" @click="closePopup">&times;</button>
      <el-form :model="formData" ref="form">
        <el-form-item
            label="姓名"
            prop="name"
            :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }"
        >
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <el-form-item
            label="地区"
            prop="edu_areaName"
            :rules="{ required: true, message: '请选择地区', trigger: 'blur' }"
        >
          <el-cascader
              :options="eduarea"
              @change="handleTeacherSelectChangeeduarea"
          ></el-cascader>
        </el-form-item>

        <el-form-item
            label="意向项目"
            prop="project"
            :rules="{
            required: true,
            message: '请输入意向项目',
            trigger: ['blur', 'change'],
          }"
        >
          <el-input v-model="formData.project" placeholder="如：自考本科"></el-input>
        </el-form-item>

        <el-form-item
            label="性别"
            prop="sex"
            :rules="{
            required: true,
            message: '请输入性别',
            trigger: ['blur', 'change'],
          }"
        >
          <el-input v-model="formData.sex" placeholder="如：男"></el-input>
        </el-form-item>

        <el-form-item
            label="年龄"
            prop="age"
            :rules="{
            required: true,
            message: '请输入您的年龄',
            trigger: ['blur', 'change'],
          }"
        >
          <el-input v-model="formData.age" placeholder="如：18"></el-input>
        </el-form-item>

        <el-form-item
            label="民族"
            prop="nation"
            :rules="{
            required: true,
            message: '请输入您的民族',
            trigger: ['blur', 'change'],
          }"
        >
          <el-input v-model="formData.nation" placeholder="如：汉"></el-input>
        </el-form-item>

        <el-form-item
            label="手机号"
            prop="phone"
            :rules="{
            required: true,
            pattern: /^1[3-9]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: ['blur', 'change'],
          }"
        >
          <el-input v-model="formData.phone" placeholder="如：13812345678">
            <template #append>
              <el-button
                  :disabled="!isPhoneValid || isCounting"
                  @click="sendVerificationCode"
              >
                {{ countdownText }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>

        <!-- 验证码输入 -->
        <el-form-item
            label="验证码"
            prop="code"
            :rules="{
            required: true,
            validator: validateCode,
            trigger: ['blur'],
          }"
        >
          <el-input
              v-model="formData.code"
              placeholder="请输入6位验证码"
              maxlength="6"
              @blur="handleCodeBlur"
          ></el-input>
        </el-form-item>

        <el-form-item label="可用的优惠卷">
          <div class="coupon-list" v-if="flage">
            <div
                v-for="(couponse, index) in couponse.data.filter(
                (coupon) => coupon.preferential.specifictid === this.formData.edu_schoolID
              )"
                :key="index"
                class="coupon-card"
            >
              <div class="logo-container">
                <!-- 这里可以放置您的logo图片，使用img标签或者背景图片方式 -->
                <img
                    src="@/assets/images/logo1.jpg"
                    alt="Your Logo"
                    class="logo-image"
                />
              </div>
              <div class="coupon-header">
                <h3>{{ couponse.preferential.name }}</h3>
                <span class="coupon-expire">有效期至 {{ couponse.preferential.outtime }}</span>
              </div>
              <div class="coupon-body">
                <p>{{ couponse.preferential.threshold }}</p>
              </div>
              <div class="coupon-footer">
                <span class="coupon-discount">{{
                    couponse.preferential.reduction
                  }}% OFF</span>
                <el-badge
                    :value="`${couponse.countCa}`"
                    :max="99"
                    class="item"
                >
                  <button
                      v-if="!formData.preferentials.split(',').includes(couponse.preferentialid.toString())"
                      @click.prevent="subsss(couponse)"
                  >
                    使用该优惠卷
                  </button>
                  <button
                      v-if="formData.preferentials.split(',').includes(couponse.preferentialid.toString())"
                      @click.prevent="exitOdEr(couponse)"
                  >
                    取消使用
                  </button>
                </el-badge>
              </div>
            </div>
            <br />
            <el-pagination
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChanges"
                :current-page="couponse.current_page"
                :page-sizes="[2, 5, 10, 20]"
                :page-size="couponse.per_page"
                layout="total, sizes, prev, pager, next, jumper"
                :total="couponse.total"
            ></el-pagination>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm">提交报名</el-button>
        </el-form-item>
      </el-form>
    </div>
  </transition>
</template>

<script>
import Api from "@/Api";

export default {
  name: "signup",
  data() {
    return {
      activeIndex: "1",
      formData: {
        preferentials: "",
      },
      formRules: {},
      couponse: {
        per_page: 2,
        current_page: 1,
        data: [],
      },
      flage: false,
      eduarea: [],
      countdown: 0,
      isCounting: false,
      correctCode: "",
    };
  },
  computed: {
    isPhoneValid() {
      return /^1[3-9]\d{9}$/.test(this.formData.phone);
    },
    countdownText() {
      return this.isCounting ? `${this.countdown}秒后重发` : "获取验证码";
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getByuserprte();
    Api.Area.areaOptions().then((res) => {
      this.eduarea = res.data.data;
    });
  },
  methods: {
    subsss(coupon) {
      if (this.formData.preferentials === "") {
        this.formData.preferentials = coupon.preferentialid;
      } else {
        this.formData.preferentials += "," + coupon.preferentialid;
      }
      console.info(this.formData.preferentials);
    },
    exitOdEr(coupon) {
      let preferentialIds = this.formData.preferentials
          ? this.formData.preferentials.split(",")
          : [];

      const index = preferentialIds.indexOf(coupon.preferentialid.toString());

      if (index !== -1) {
        preferentialIds.splice(index, 1);
        preferentialIds = preferentialIds.map((id) => id.trim()).filter(Boolean);
        this.formData.preferentials = preferentialIds.join(",");
      }
    },
    submitForm() {
      // 验证整个表单
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 验证通过，执行添加操作
          this.formData.state = 3;
          this.formData.createID = this.$store.state.user_info.id;
          const now = new Date();
          const year = now.getFullYear();
          const month = now.getMonth() + 1;
          const day = now.getDate();
          const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
              day < 10 ? "0" + day : day
          }`;
          this.formData.createtiem = formattedDate;
          Api.Applicants.InsterEntity({
            project: this.formData.project,
            name: this.formData.name,
            sex: this.formData.sex,
            age: this.formData.age,
            nation: this.formData.nation,
            phone: this.formData.phone,
            preferentials: this.formData.preferentials,
            createlD: this.formData.createID,
            createtiem: formattedDate,
            updatelD: this.formData.createID,
            updatetiem: formattedDate,
            state: this.formData.state,
            country: this.formData.country,
            economize: this.formData.economize,
            market: this.formData.market,
            distinguish: this.formData.distinguish,
          }).then((res) => {
            this.$message({
              message: "成功录入,请等待通知",
              type: "success",
            });
          });
        } else {
          // 验证失败，提示用户
          this.$message.error("验证码输入错误");
          return false;
        }
      });
    },
    handleSelect(key, keyPath) {
      this.formData = {};
    },
    handleTeacherSelectChangeeduarea(area) {
      this.formData.country = area[0];
      this.formData.economize = area[1];
      this.formData.market = area[2];
      this.formData.distinguish = area[3];
    },
    closePopup() {
      this.$emit("close");
    },
    handleSizeChanges(val) {
      this.couponse.per_page = val;
      this.getByuserprte();
    },
    handleCurrentChanges(val) {
      this.couponse.current_page = val;
      this.getByuserprte();
    },
    getByuserprte() {
      let that = this;
      Api.Userprefe.Realpolicy({
        userName: that.$store.state.user_info.nickname,
        per_page: that.couponse.per_page,
        current_page: that.couponse.current_page,
      }).then((res) => {
        that.couponse = {
          ...res.data.data,
          per_page: that.couponse.per_page,
          current_page: that.couponse.current_page,
        };

        let promises = [];
        for (let i = 0; i < that.couponse.data.length; i++) {
          that.$set(that.couponse.data, i, {
            ...that.couponse.data[i],
            preferential: null,
          });

          promises.push(
              Api.Preferential.Realpolicy({
                id: that.couponse.data[i].preferentialid,
              }).then((res) => {
                that.$set(that.couponse.data[i], "preferential", res.data);
              })
          );
        }

        Promise.all(promises).then(() => {
          that.flage = !that.flage;
        });
      });
    },
    sendVerificationCode() {
      this.correctCode = Math.floor(10000 + Math.random() * 90000).toString();
      let data = {
        to: this.formData.phone,
        signature: "勋鸿文化",
        templateId: "pub_verif_register_ttl",
        templateData: { code: this.correctCode, ttl: "1" },
      };
      $.ajax({
        url: "/sms",
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        success: (response) => {
          this.startCountdown();
        },
        error: (error) => {
          this.$message.error(`发送失败：${error.statusText}`);
        },
      });
    },
    startCountdown() {
      this.countdown = 60;
      this.isCounting = true;
      const timer = setInterval(() => {
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.isCounting = false;
          return;
        }
        this.countdown--;
      }, 1000);
    },
    handleCodeBlur() {
      this.$refs.form.validateField("code");
    },
    validateCode(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入验证码"));
      }
      if (value.toString() !== this.correctCode.toString()) {
        return callback(new Error("验证码不正确"));
      }
      callback();
    },
  },
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  padding: 1rem;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.coupon-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.coupon-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
}

.coupon-card:hover {
  transform: translateY(-5px);
}

.coupon-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.coupon-expire {
  float: right;
  color: #999;
}

.coupon-body {
  padding: 20px;
}

.coupon-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #fff;
}

.coupon-discount {
  color: #d4235c;
  font-weight: bold;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.container {
  position: relative;
}

.logo-container {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
}

.logo-image {
  width: 50px;
  height: auto;
  border-radius: 50%;
}
</style>