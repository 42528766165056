<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <!-- 顶部导航栏 -->
    <div class="top-nav">
      <ul>
        <li><a href="/about">关于我们</a></li>
        <li><a href="/contact">联系我们</a></li>
        <li><a href="/help">帮助中心</a></li>
        <li><a href="/terms">服务条款</a></li>
        <li><a href="/privacy">隐私政策</a></li>
      </ul>
    </div>

    <!-- 页脚 -->
    <div class="footer">
      <div class="text-center">
        <div class="state">版权所有 未经允许不得转载</div>
        <div class="miit">
          <a href="https://beian.miit.gov.cn" target="_blank" class="miit1">琼ICP备2024044783号-2</a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502002811" target="_blank" class="miit2">服务许可号：琼ICP备2024044783号-2</a>
        </div>
        <div class="txt">© Copyright 2021-现在   中宪教育（海南）集团 版权所有 zhongguojiaoyunet.com</div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api.js";
export default {
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

<style scoped lang="less">
/* 顶部导航栏样式 */
.top-nav {
  background: #292a32;
  padding: 10px 0;

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 15px;
    }

    a {
      font-size: 14px;
      color: #939393;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

/* 页脚样式 */
.footer {
  height: 120px;
  background: #292a32;
  padding: 30px 0;

  .copy {
    font-size: 14px;
    line-height: 40px;
    color: #ffffff;
  }

  .miit {
    width: 100%;
  }

  .miit1 {
    font-size: 13px;
    text-decoration: none;
    color: #939393;
  }

  .miit2 {
    margin-left: 20px;
    font-size: 13px;
    text-decoration: none;
    color: #939393;
    background: url(../assets/icon/icp-logo.png) no-repeat left center;
    padding-left: 22px;
  }

  .state {
    font-size: 14px;
    line-height: 40px;
    color: #ffffff;
  }

  .txt {
    font-size: 12px;
    color: #939393;
    line-height: 30px;
  }
}
</style>