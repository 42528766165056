<template>
  <main class="page" v-loading="loading">
    <meta name="keywords" content="教育,生活,政策,人生规划,院校信息">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <!--轮播图-->
    <el-row>
      <el-col :span="24">
        <div class="layout clearfix" v-if="BannerList.length > 0" style="margin-bottom: 20px;" >
          <div class="banner-box">
            <el-carousel :interval="5000">
              <el-carousel-item v-for="(item, index) in BannerList" :key="index">
                <el-image :src="'https://admin.zhongguojiaoyunet.com/' + item.url" :style="{width:'100%', height: 'auto'}"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 左右两列资讯 -->
    <div class="layout flexs" v-if="cateArticles && cateArticles.length > 0">
      <div class="flex-item" v-for="list in cateArticles">
        <div class="flex-title">
          {{list.name}}
          <span @click="getbycateArticles" class="flex-more">查看更多>></span>
        </div>
        <ul class="content">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="list.name === '政策文件' ? 'educationaladvancement?id=6'  : '/article?id=' + item.id " :title="item.title">{{item.title}}</router-link>
            <span>{{item.date}}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 左右第二列两列资讯 -->
    <div class="layout flexs" v-if="proDemo && proDemo.length > 0">
      <div class="flex-item" v-for="list in proDemo">
        <div class="flex-title">
          {{list.name}}
          <span @click="getbyproDemo" class="flex-more">查看更多>></span>
        </div>
        <ul class="content" v-if="shouldDisplayContent(list.name)">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="'educationaladvancement?id=6'" :title="item.Name">{{item.Name}}</router-link>
            <span>{{item.yourdates}}</span>
          </li>
        </ul>
        <ul class="content" v-if="shouldDisplayContent1(list.name)">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="'educationaladvancement?id=6'" :title="item.Name">{{item.Name}}</router-link>
            <span>{{item.yourdates}}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 左右第三列两列资讯 -->
    <div class="layout flexs" v-if="proDemos && proDemos.length > 0">
      <div class="flex-item" v-for="list in proDemos">
        <div class="flex-title">
          {{list.name}}
          <span @click="getbyproDemos" class="flex-more">查看更多>></span>
        </div>
        <ul class="content" v-if="shouldDisplayContent2(list.name)">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="'educationaladvancement?id=6'" :title="item.title">{{item.title}}</router-link>
            <span>{{item.releasetime}}</span>
          </li>
        </ul>
        <ul class="content" v-if="shouldDisplayContent3(list.name)">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="'educationaladvancement?id=6'" :title="item.name">{{item.industry}}   {{item.name}}</router-link>
            <span>{{item.create_time}}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 左右第四列两列资讯 -->
    <div class="layout flexs" v-if="proDemos2 && proDemos2.length > 0">
      <div class="flex-item" v-for="list in proDemos2">
        <div class="flex-title">
          {{list.name}}
          <span @click="getbyproDemos2" class="flex-more">查看更多>></span>
        </div>
        <ul class="content" v-if="shouldDisplayContent4(list.name)">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="'educationaladvancement?id=6'" :title="item.link_text">{{item.link_text}}</router-link>
            <span>{{item.createtime}}</span>
          </li>
        </ul>
        <ul class="content" v-if="shouldDisplayContent5(list.name)">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="'educationaladvancement?id=6'" :title="item.Name">{{item.Name}}</router-link>
            <span>{{item.yourdates}}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 左右第四列两列资讯 -->
    <div class="layout flexs" v-if="proDemos3 && proDemos3.length > 0">
      <div class="flex-item" v-for="list in proDemos3">
        <div class="flex-title">
          {{list.name}}
          <span @click="getbyproDemos3" class="flex-more">查看更多>></span>
        </div>
        <ul class="content" v-if="shouldDisplayContent6(list.name)">
          <li class="list" v-for="(item, index) in list.articles" :key="index">
            <router-link :to="'educationaladvancement?id='+item.id" :title="item.title">{{item.title}}</router-link>
            <span>{{item.releasetime}}</span>
          </li>
        </ul>
      </div>
    </div>

    <!--立即报名-->
    <div class="layout" v-if="srcimgs1 && srcimgs1.length > 0">
      <a class="wsc-poster" style="width: 100%;height: auto">
        <el-carousel :interval="5000" arrow="always" class="custom-carousel">
          <el-carousel-item v-for="(item, index)  in srcimgs1" :key="index">
            <el-image :src="item.image" :alt="item.alts"></el-image>
          </el-carousel-item>
        </el-carousel>
      </a>
    </div>

    <!-- 友情链接 -->
    <div class="layout friends">
      <div class="layout-title">
        常用链接
      </div>
      <div class="content">
        <a :href="item.url" target="_blank" class="item slate5-shadow" v-for="(item, index) in FriendsList" :key="index">
          <img class="logo" :src="item.logo" alt="">
          <span class="txt">{{item.name}}</span>
        </a>
      </div>
    </div>
  </main>
</template>
<script type="text/javascript">
import Api from "@/Api.js";
export default {
  data() {
    return {
      srcimgs1: [
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/7ddcad5337137c0cafa71ad427980cf9.jpg", alts: "Banner1"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/3ffb4ab95a818a07a78f39849cfc0f5d.jpg", alts: "Banner2"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/4cff7335ea361c1941c20d9183143ba9.jpg", alts: "Banner3"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/e7e67c2deee1b0996d88f1cbd80a97db.jpg", alts: "Banner4"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/5ddb27931efba66e5a7937f44242ed15.jpg", alts: "Banner5"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/e2f06f2038b495ec373756736971baf9.jpg", alts: "Banner6"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/fd3725fba07194b725eda21794664929.jpg", alts: "Banner7"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/47e9c1a140d176a05e9e5c99db547b8d.jpg", alts: "Banner8"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/df5833691ac492a76cb859d5edbecde7.jpg", alts: "Banner9"},
        {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/0ac3941ec6d3613b408bbfa2ae1f1753.jpg", alts: "Banner10"}
      ],
      drawers: null,
      loading: true,
      proDemo: [
        {id: 2, name: "就业资讯", pid: 0, status: "normal", hot: 1, createtime: 1702020824, updatetime: 1702285583, articles: []},
        {id: 1, name: "培训资讯", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484, articles: []}
      ],
      proDemos: [
        {id: 2, name: "创业孵化", pid: 0, status: "normal", hot: 1, createtime: 1702020824, updatetime: 1702285583, articles: []},
        {id: 1, name: "人才智库", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484, articles: []}
      ],
      proDemos2: [
        {id: 1, name: "技能竞赛", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484, articles: []},
        {id: 1, name: "人事考服", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484, articles: []}
      ],
      proDemos3: [
        {id: 1, name: "学历提升", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484, articles: []}
      ],
      BannerList: [],
      FriendsList: [],
      cateArticles: [],
      Nationalpolicy: [],
      Temploymentindividual: [],
      Ttrainingandemployment: [],
      Skillscompetition: [],
      entrepreneurshippojo: [],
      talentthinktank: [],
      personnelexaminationservice: [],
      educationaladvancement: [],
      Article: [],
    }
  },
  computed: {
    myState: {
      get() {
        return this.$store.getters.myState;
      }
    }
  },
  watch: {
    myState(newValue) {
      this.renew(newValue[1], newValue[2], newValue[4]);
    }
  },
  methods: {
    shouldDisplayContent(name) {
      return name === '就业资讯';
    },
    shouldDisplayContent1(name) {
      return name === '培训资讯';
    },
    shouldDisplayContent2(name) {
      return name === '创业孵化';
    },
    shouldDisplayContent3(name) {
      return name === '人才智库';
    },
    shouldDisplayContent4(name) {
      return name === '技能竞赛';
    },
    shouldDisplayContent5(name) {
      return name === '人事考服';
    },
    shouldDisplayContent6(name) {
      return name === '学历提升';
    },
    renew(newValue, newcity, judge) {
      // 并行发送两个 API 请求

      const promise1 = Api.Config.GetInit({
        areaid: newValue,
        city: newcity,
        judge: judge,
      });

      const promise2 = Api.Nationalpolicy.Realpolicy({
        provinceareaids: this.$store.state.LocationPCity[1],
        areaid: this.$store.state.LocationPCity[2],
      });

      // 使用 Promise.all 并行处理两个请求
      Promise.all([promise1, promise2])
          .then(([res1, res2]) => {
            // 处理第一个 API 的响应
            this.BannerList = res1.data.data.banner;
            this.FriendsList = res1.data.data.friends;
            this.cateArticles = res1.data.data.cate_articles;

            // 处理第二个 API 的响应
            this.Nationalpolicy = res2.data.Ndata.data;
            this.cateArticles[0].name = "政策文件";
            this.cateArticles[0].articles = this.Nationalpolicy;
            this.Article = res2.data.Adata.data;
            this.cateArticles[1].articles = this.Article;
            this.Temploymentindividual = res2.data.T1data.data;
            this.proDemo[0].articles = this.Temploymentindividual;
            this.Ttrainingandemployment = res2.data.T2data.data;
            this.proDemo[1].articles = this.Ttrainingandemployment;
            this.entrepreneurshippojo = res2.data.entrepreneurshippojos.data;
            this.proDemos[0].articles = this.entrepreneurshippojo;
            this.talentthinktank = res2.data.talentthinktanks.data;
            this.proDemos[1].articles = this.talentthinktank;
            this.Skillscompetition = res2.data.Sdata.data;
            this.proDemos2[0].articles = this.Skillscompetition;
            this.personnelexaminationservice = res2.data.personnelexaminationservices.data;
            this.proDemos2[1].articles = this.personnelexaminationservice;
            this.educationaladvancement = res2.data.educationaladvancements.data;
            this.proDemos3[0].articles = this.educationaladvancement;

            // 数据加载完成
            this.loading = false;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // 处理错误，比如显示错误信息或使用默认数据
            this.loading = false;
          });
    },
    getbycateArticles() {
      let that = this;
      Api.Nationalpolicy.Realpolicy1({
        areaid: this.$store.state.LocationPCity[1],
      }).then((res) => {
        that.Nationalpolicy = res.data.data.data;
        that.cateArticles[0].name = "政策文件";
        that.cateArticles[0].articles = that.Nationalpolicy;
      })
      Api.Article.Realpolicy1({
        areaid: this.$store.state.LocationPCity[1],
      }).then((res) => {
        this.Article = res.data.data.data;
        this.cateArticles[1].articles = this.Article;
      })
    },
    getbyproDemo() {
      let that = this;
      Api.Temploymentindividual.Realpolicy1({
        areaid: this.$store.state.LocationPCity[1],
      }).then((res) => {
        that.Temploymentindividual = res.data.data.data;
        that.proDemo[0].articles = that.Temploymentindividual;
      })
      Api.Ttrainingandemployment.Realpolicy1({
        areaid: this.$store.state.LocationPCity[1],
      }).then((res) => {
        that.Ttrainingandemployment = res.data.data.data;
        that.proDemo[1].articles = that.Ttrainingandemployment;
      })
    },
    getbyproDemos2() {},
    getbyproDemos3() {
      let that = this;
      Api.Skillscompetition.Realpolicy1({
        areaid: this.$store.state.LocationPCity[1],
      }).then((res) => {
        that.Skillscompetition = res.data.data.data;
        that.proDemos2[0].articles = that.Skillscompetition;
      })
    },
    getbyproDemos() {},
  },
  mounted() {
    if (this.$store.state.LocationPCity[0] != null) {
      this.renew(this.$store.state.LocationPCity[1], this.$store.state.LocationPCity[2], this.$store.state.LocationPCity[4]);
    }
  },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/

.page {
  padding: 10px 0;
}

.banner-box {
  width: 100%;
  height: 425px;
}

:deep(.el-carousel__container) {
  height: 425px !important;
}

.articles {
  padding: 35px 0;

  .content {
    border: 1px solid #CDCDCD;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 0;
    background: #ffffff;
  }

  .list {
    box-sizing: border-box;
    width: 50%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 14px;
      min-width: 60px;
      text-align: right;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      color: #333333;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .list:hover span,
  .list:hover a {
    color: #D11A2D;
  }
}

.wsc-poster {
  margin: 30px 0 0;
  display: block;
  cursor: pointer;

  .poster-img {
    width: 100%;
    height: auto;
  }
}

.edu-attrs {
  padding: 35px 0;

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item {
    width: 282px;
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .cont {
    width: 142px;
    text-align: left;

    .title {
      font-size: 20px;
      line-height: 40px;
      margin: 0;
    }

    .desc {
      margin: 0;
      height: 50px;
      font-size: 16px;
      color: #999999;
    }
  }

  .ico {
    margin-left: 10px;
    width: 90px;
    height: 90px;
  }
}

.teachers {
  padding: 30px 0;

  .content {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .item {
    width: 342px;
    border-radius: 5px;
    background: #252930;
    border: 1px solid #CDCDCD;
    padding: 30px 20px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: 10px;
  }

  .title {
    color: #999999;
    margin: 0;
    line-height: 30px;
  }

  .name {
    color: #ffffff;
    margin: 0;
    line-height: 30px;
  }

  .intro {
    height: 90px;
    color: #999999;
    font-size: 14px;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .link {
    margin-top: 15px;
    display: inline-block;
    width: 120px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 5px;
    background: #191c21;
    color: #dddddd;
    transition: all 0.2s;
    text-decoration: none;
  }

  .link:hover {
    color: #191c21;
    background: #dddddd;
  }
}

.videos {
  padding: 30px 0;

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    width: 282px;
    display: block;
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    text-decoration: none;
    margin-bottom: 20px;
    border: 1px solid #eeeeee;
  }

  .replay-box {
    width: 282px;
    height: 170px;
    position: relative;
  }

  .replay-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    opacity: 0;
    transition: all .25s;
  }

  .replay-cover:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 55%;
    margin: -30px 0 0 -30px;
    width: 60px;
    height: 60px;
    transition: all .25s;
    background-image: url(../assets/icon/replay.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .item:hover .replay-cover {
    opacity: 1
  }

  .item:hover .replay-cover:before {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  .img {
    width: 282px;
    height: 170px;
    object-fit: cover;
  }

  .title {
    padding: 0 10px;
    text-decoration: none;
    color: #333333;
    font-size: 16px;
    line-height: 40px;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .title:hover {
    color: #D11A2D;
  }
}

.flexs {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 40px 0;

  .flex-item {
    width: 50%;
    border-radius: 5px;
    border: 1px solid #eeeeee;
  }

  .flex-title {
    padding: 15px 20px;
    font-size: 20px;
    color: #D11A2D;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f8fa;
  }

  .flex-more {
    color: #999999;
    font-size: 14px;
    text-decoration: none;
    font-weight: normal;
  }

  .flex-more:hover {
    color: #666666;
  }

  .content {
    margin: 0;
    padding: 10px 0;
    list-style-type: none;
    justify-content: space-between;
    box-sizing: border-box;
    background: #ffffff;
  }

  .list {
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 14px;
      min-width: 60px;
      text-align: right;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      color: #333333;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .list:hover span,
  .list:hover a {
    color: #D11A2D;
  }
}

.friends {
  padding: 30px 0;

  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    box-sizing: border-box;
    width: 25%;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .logo {
    width: 60px;
    margin-right: 20px;
  }

  .txt {
    font-size: 16px;
    color: #333;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.custom-carousel {
  height: 128px;
  overflow: hidden;
}

.custom-carousel .el-carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  /* 确保所有元素宽度不超过屏幕宽度 */
  * {
    max-width: 100% !important;
    box-sizing: border-box !important;
  }

  /* 确保页面宽度适应屏幕 */
  body {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
  }

  .page {
    max-width: 100% !important;
    margin: 0 auto !important;
    padding: 0 10px !important;
  }

  /* 调整轮播图宽度和高度 */
  .banner-box {
    width: 100% !important;
    height: 120px !important;
  }
  :deep(.el-carousel__container) {
    width: 100% !important;
    height: 120px !important;
  }

  /* 调整两列布局为单列 */
  .flexs {
    flex-direction: column !important;
  }
  .flex-item {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  /* 调整资讯列表的样式 */
  .list {
    width: 100% !important;
    padding: 8px 12px !important;
  }
  .list a {
    font-size: 12px !important;
  }
  .list span {
    font-size: 11px !important;
    min-width: 45px !important;
  }

  /* 调整友情链接的样式 */
  .friends .content .item {
    width: 50% !important;
  }
  .friends .content .logo {
    width: 35px !important;
    margin-right: 8px !important;
  }
  .friends .content .txt {
    font-size: 13px !important;
  }

  /* 调整立即报名轮播图的高度 */
  .custom-carousel {
    height: 80px !important;
  }

  /* 确保所有容器和内容自适应 */
  .el-row, .el-col, .layout, .content {
    width: 100% !important;
    max-width: 100% !important;
  }

  .el-image, img {
    width: 100% !important;
    height: auto !important;
  }

  /* 针对页头和页尾的调整 */
  .header, .footer {
    width: 100% !important;
    max-width: 100% !important;
    padding: 8px 10px !important;
  }

  /* 进一步缩小字体大小 */
  .title {
    font-size: 14px !important;
  }

  .desc, .intro {
    font-size: 12px !important;
  }
}
</style>