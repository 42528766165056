<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main class="page" @click="handleOutsideClick">
    <div class="layout">
      <el-row>
        <!-- 详情 -->
        <el-col :span="18">
          <div class="content-box">
            <h2 class="title">{{ detailData.title }}</h2>
            <div class="data">{{ detailData.releasetime }}</div>
            <el-divider></el-divider>
            <div class="content">
              {{detailData.content}}
            </div>
           <div class="subheading">
             一、实施部门
           </div>
            <div class="source">
              {{ detailData.source }}
            </div>
            <div class="subheading">
              二、项目特色
            </div>
            <div class="pojocharacteristics">
              {{detailData.pojocharacteristics}}
            </div>
            <div class="subheading">
              三、考试安排
            </div>
            <div class="examarrangements">
              {{detailData.examarrangements}}
            </div>
            <div class="subheading">
              四、成功案例
            </div>
            <div class="coisidftp">
              <el-carousel
                  :autoplay="false"
                  indicator-position="none"
                  style="width: 100%;"
              >
                <!-- 遍历 certificateList 数组 -->
                <el-carousel-item
                    v-for="(url, index) in certificateList"
                    :key="index"
                >
                  <!-- 使用 el-image 显示图片 -->
                  <el-image
                      :src="url"
                      alt="证书图片"
                      fit="contain"
                      style="width: 100%; height: 100px; border-radius: 8px;"
                      :preview-src-list="certificateList"
                  >
                    <div slot="placeholder" class="image-slot">
                      加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="subheading">
              五、介绍视频
            </div>
            <!-- 视频封面轮播图 -->
            <div class="video-carousel">
              <el-carousel
                  :autoplay="false"
                  indicator-position="none"
                  style="width: 100%;"
              >
                <!-- 遍历视频封面列表 -->
                <el-carousel-item
                    v-for="(cover, index) in videoCovers"
                    :key="index"
                    @click.native="openVideoDialog(index)"
                >
                <el-image
                    :src="cover"
                    alt="视频封面"
                    fit="cover"
                    style="width: auto; height: auto;"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
                </el-carousel-item>
              </el-carousel>
            </div>

            <!-- 视频弹窗 -->
            <el-dialog
                :visible.sync="videoDialogVisible"
                title="视频播放"
                width="60%"
                :before-close="closeVideoDialog"
            >
              <!-- 视频播放器 -->
              <video :src="playerOptionssrc" controls width="100%"></video>
            </el-dialog>
          </div>
        </el-col>

        <!-- 推荐 -->
        <el-col :span="6">
          <div class="list-box">
            <div class="title">相关推荐</div>
            <div class="content">
              <div class="item" v-for="item in recommendList" :key="item.id" v-if="item.id !== 1">
                <span class="txt" @click="herfBusinn(item.id)" >{{ item.title }}</span>
              </div>
            </div>
          </div>
        </el-col>

          <el-row>
            <el-col :span="10">
              <h2>报名信息<span class="el-icon-edit-outline"></span></h2>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-button
                  type="success"
                  icon="el-icon-thumb"
                  round
                  @click="handleOnlineSignup"
              >
                线上报名
              </el-button>
              <el-button
                  type="danger"
                  icon="el-icon-thumb"
                  round
                  @click="handleOfflineSignup"
              >
                线下报名
              </el-button>
            </el-col>
          </el-row>
        <signup  ref="signup"   class="popup-container"  v-if="showsignup" :visible="showsignup" @close="showsignup = false"></signup>
      </el-row>
    </div>
  </main>
</template>
<script>
import Api from "@/Api.js";
import signup from "@/views/ModuleS/signupdemos.vue";
export default {
  components: {signup},
  data() {
    return {
      videoDialogVisible: false, // 控制弹窗显示
      showsignup: false,
      id: '',
      recommendList: [],
      detailData: {
        admin: {
          'id': '',
          'nickname': '',
          'avatar': '',
        }
      },
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 是否自动播放
        muted: false, // 是否静音
        loop: false, // 是否循环播放
        preload: 'auto', // 预加载
        language: 'zh-CN',
        aspectRatio: '16:9', // 宽高比
        fluid: true, // 流式布局
        sources: [
          {
            type: "video/mp4",
            src: ""// 视频路径，稍后在方法中动态绑定
          }
        ],
        poster: "", // 封面路径，稍后在方法中动态绑定
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 不支持的提示
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      certificateList:null,
      playerOptionssrc: null,
      videoCovers: [], // 视频封面列表
      videoSources: [] // 视频路径列表
    }
  },
  methods: {
    //数据填充
    getDetail() {
      let that = this;
      Api.Educationaladvancement.GetEducationaladvancementDetail({
        id: that.id,
      }).then((res) => {
        that.detailData = res.data;
        if (that.detailData.coisidftp!=null){
          that.certificateList=that.detailData.coisidftp.split(",");
        }
        if (that.detailData.Introductionvideo!=null){
          that.videoSources=that.detailData.Introductionvideo.split(",");
        }
        if (that.detailData.Introductionvideocover!=null){
          that.videoCovers=that.detailData.Introductionvideocover.split(",");
        }
        Api.Educationaladvancement.GetEducationaladvancementlist({
          economize:res.data.economize
        }).then(res1 => {
          that.recommendList = res1.data.data.data;
        })
      })
    },
    handleOnlineSignup(){
      setTimeout(() => {
        this.showsignup=true;
      }, 1000);
    },
    handleOutsideClick(event) {
      if (this.showsignup==true){
        // 判断点击事件是否发生在弹窗组件之外
        if (!this.$refs.signup.$el.contains(event.target)) {
          this.handleClosePopup();
        }
      }
    },
    handleOfflineSignup(){

    },
    handleClosePopup() {
      this.$confirm('此操作将关闭弹窗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '关闭!'
        });
        this.showsignup=false;
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    herfBusinn(id) {
      this.id = id;
      this.getDetail();
    },
    openVideoDialog(index) {
      console.info(index);
      this.playerOptionssrc = this.videoSources[index]; // 设置视频路径
      this.videoDialogVisible = true; // 打开弹窗
    },
    closeVideoDialog() {
      this.videoDialogVisible = false; // 关闭弹窗
      this.$refs.videoPlayer.player.pause(); // 暂停视频
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  mounted() {

  }
}
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  padding: 20px 0;
  background: #f7f8fa;
}

.content-box{
  .title{
    font-size: 20px;
    text-align: center;
  }

  .data{
    text-align: center;
  }

  .content{
    font-size: 16px;
    text-indent: 2em;
  }

  .subheading{
    font-size: 16px;
    font-weight: bolder;
    text-indent: 2em;
  }

  .source{
    text-indent: 2em;
    font-size: 16px;
  }

  /* 弹窗样式 */
  .video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .el-table .image-slot {
    text-align: center;
    color: #999;
  }

  .video-popup-content        {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    width: 80%;
    max-width: 600px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .Introductionvideocover {
    text-indent: 2em;
  }
}

.list-box{
  width: 100%;
  margin-left: 20px;
  background: #ffffff;

  .title{
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #eeeeee;
  }

  .content{
    padding: 10px 0;
  }

  .item{
    display: block;
    width: 100%;
    padding: 10px 20px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .txt{
    margin-left: 5px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    font-size: 16px;
    color: #333333;
    text-decoration: none;
  }

  .item:hover .txt{
    color: #D11A2D;
  }

  .item::before{
    content: '';
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #D11A2D;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item:nth-child(1)::before{
    content: '1'!important;
  }
  .item:nth-child(2)::before{
    content: '2'!important;
  }
  .item:nth-child(3)::before{
    content: '3'!important;
  }
  .item:nth-child(4)::before{
    content: '4'!important;
  }
  .item:nth-child(5)::before{
    content: '5'!important;
  }
  .item:nth-child(6)::before{
    content: '6'!important;
  }
  .item:nth-child(7)::before{
    content: '7'!important;
  }
  .item:nth-child(8)::before{
    content: '8'!important;
  }
  .item:nth-child(9)::before{
    content: '9'!important;
  }
  .item:nth-child(10)::before{
    content: '10'!important;
  }

}

//报名框
.popup-container {
  overflow-y: auto; /* 自动显示垂直滚动条 */
  max-height: 500px; /* 设置一个最大高度，可以根据实际需求调整 */
  width: 80%; /* 或其他适合的宽度 */
  box-sizing: border-box;

}

/* （可选）自定义滚动条样式 */
.popup-container::-webkit-scrollbar {
  width: 10px; /* 滚动条宽度 */
}

.popup-container::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 6px; /* 滚动条滑块圆角 */
}
</style>