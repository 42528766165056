<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main @click="handleOutsideClick" class="page">
    <el-divider></el-divider>
      <el-breadcrumb class="fixed-home" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="el-icon-s-home" :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: 'schools' }">校区列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">校区详情</el-breadcrumb-item>
      </el-breadcrumb>
    <el-divider></el-divider>
    <div class="fixed-home">
      <el-row :gutter="20">
        <el-col :span="4">
          <div >
            <!-- 这里可以放置院徽的图片 -->
            <img class="logo" :src="`https://admin.zhongguojiaoyunet.com/${detailData.logo_images}`" alt="郑州白求恩医学专修学院" />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="school-name">
            {{detailData.name}}
            <br />
            {{detailData.name_en}}
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="buttons">
            <el-button>{{areaName}}</el-button>
            <el-button v-for="item in detailData.typesList">
              {{item.name}}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="address">
            校址：{{detailData.round}}
          </div>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home">
      <el-row :gutter="20">
        <el-col :span="12">
          <h2>院校简介</h2>
          <p>{{detailData.intro}}</p>
        </el-col>
        <el-col :span="12">
          <el-carousel :interval="4000" type="card" height="300px">
            <el-carousel-item v-for="(image, index) in detailData.imagesList" :key="index">
              <el-image
                  style="width: 100%; height: 100%;"
                  :src="image"
                  fit="cover"
                  @click="openDialog(index)"
              />
            </el-carousel-item>
          </el-carousel>
          <el-dialog :visible.sync="dialogVisibleImagefalg" title="">
            <el-image
                style="width: 100%; height: auto;"
                :src="currentImage"
                fit="contain"
            />
          </el-dialog>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home">
      <el-row>
        <el-col :span="5">
          <h2>师资力量</h2>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" v-for="(teacher, index) in imagesList.data" :key="index">
          <el-card class="teacher-card">
            <div slot="header">
              <i class="el-icon-user-solid"></i>
              <el-avatar :size="50" :src="`http://admin.zhongguojiaoyunet.com/${teacher.avatar}`" class="teacher-avatar"></el-avatar>
            </div>
            <div  class="teacher-info">
              <p>职称：{{ teacher.duties }}</p>
              <p class="experience-wrapper">经历：{{ teacher.intro }}</p>
              <p>系：{{ teacher.department }}</p>
            </div>
            <div class="teacher-name">
              <el-tag type="danger">{{ teacher.name }}</el-tag>
            </div>
            <div class="teacher-address">
              地址：{{ teacher.Officelocation }}
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-pagination
          layout="prev, pager, next"
          :total="20"
          :current-page="1"
          @current-change="handlePageChange"
      />
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home">
      <el-row>
        <el-col :span="5">
          <h2>学院风采</h2>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item, index) in videoData" :key="index">
          <el-card :body-style="{ padding: '0px' }">
            <el-image
                @click="openVideo(index)"
                class="college-feature-image"
                :src="item.image"
                fit="cover"
            />
            <div class="college-feature-caption">{{ item.name }}</div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home">
      <el-row>
        <el-col :span="10">
          <h2>开设专业<span class="el-icon-set-up"></span></h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table :data="this.Collegedetails.data" style="width: 100%" class="custom-table">
            <el-table-column prop="name" label="专业名称" width="180"></el-table-column>
            <el-table-column prop="scodate" label="学制" width="180"></el-table-column>
            <el-table-column prop="score" label="分数线"></el-table-column>
            <el-table-column prop="money" label="学费"></el-table-column>
          </el-table>
          <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="this.Collegedetails.currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="this.Collegedetails.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.Collegedetails.total">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home" v-if="mybRRR">
      <el-row>
        <el-col :span="10">
          <h2>专业介绍视频<span class="el-icon-video-play"></span></h2>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4" v-for="(item, index) in Provideo.data" :key="index" v-if="item.file_pathImage">
          <el-card :class="`card-${item.class}`" shadow="hover">
            <el-image
                :src="`http://admin.zhongguojiaoyunet.com/${item.file_pathImage}`"
                :alt="item.file_title"
                fit="cover"
                @click="openVideo1(item)"
            ></el-image>
            <div style="padding: 14px;">
              <span>{{ item.file_title }}</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-dialog
          :visible.sync="videoVisible"
          width="800px"
          :title="currentVideo1.file_title"
          @close="videoVisible=false"
      >
        <video :src="`http://admin.zhongguojiaoyunet.com/${currentVideo1.file_path}`" controls width="100%"></video>
      </el-dialog>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home" v-if="mybRRR">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="recruitment">
            <h2 style="text-align: left">招生宣传</h2>
            <el-card class="video-card">
              <el-carousel :interval="5000" arrow="always">
                <el-carousel-item v-for="(item, index) in Enrollmentpromotion">
                  <el-image :src="`http://admin.zhongguojiaoyunet.com/`+item.img" @click="openVideo2(item)" fit="cover" class="college-image"></el-image>
                </el-carousel-item>
              </el-carousel>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="college-image-section">
            <h2 style="text-align: left">院校图片</h2>
            <el-card class="introduction">
              <div class="college-name">
                <i class="el-icon-school"></i> {{detailData.name}}
              </div>
              <div class="college-profile">
                <div >
                  <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="rounds in detailData.roundsList">
                      <el-image :src="rounds" fit="cover" class="college-image"></el-image>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home" v-if="mybRRR">
      <div class="recruitment-brochure">
        <h1>招生简章</h1>
        <div class="brochure-list">
          <el-card v-for="studentbrochures in studentbrochure" :key="year" class="brochure-card">
            <div slot="header" class="clearfix">
              <span>{{ studentbrochures.registrationtime }}年招生简章</span>
            </div>
            <div class="brochure-content">
              <h2>{{ studentbrochures.name }}</h2>
              <el-button type="primary" icon="el-icon-arrow-right" @click="viewBrochure(studentbrochures)">查看详情</el-button>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home" v-if="mybRRR">
      <el-row>
        <el-col :span="10">
          <h2>招生信息<span class="el-icon-s-operation"></span></h2>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="wsc-f7f8fa  round-box">
      <div class="layout">
        <div class="layout-title">学校周边介绍</div>
        <div class="content">
          <div class="intro">
            {{ detailData.round }}
          </div>
          <div class="thumb">
            <moxInLogaode ref="moxInLogaode"  />
          </div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="fixed-home">
      <el-row>
        <el-col :span="10">
          <h2>报名信息<span class="el-icon-edit-outline"></span></h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-button
              type="success"
              icon="el-icon-thumb"
              round
              @click="handleOnlineSignup"
          >
            线上报名
          </el-button>
          <el-button
              type="danger"
              icon="el-icon-thumb"
              round
              @click="handleOfflineSignup"
          >
            线下报名
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <el-dialog
        title="视频播放"
        :visible.sync="showVideoPlayer"
        width="80%"
        center
    >
      <video controls @ended="closeVideo" :src="currentVideo.url"></video>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeVideo">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="视频播放"
        :visible.sync="showVideoPlayer1"
        width="80%"
        center
    >
      <video controls  :src="`http://admin.zhongguojiaoyunet.com/`+currentVideo2.file_path"></video>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showVideoPlayer1=false">关闭</el-button>
      </span>
    </el-dialog>

    <signup  ref="signup"   class="popup-container"  v-if="showsignup" :visible="showsignup" @close="showsignup = false">
    </signup>

  </main>
</template>
<script>
import Api from "@/Api.js";
import moxInLogaode from "@/components/views/moxInLogaode.vue";
import signup from "@/views/ModuleS/signup.vue";

export default {
  components: {moxInLogaode,signup},
  data() {
    return {
      Enrollmentpromotion: [],
      mybRRR: false,
      showsignup: false,
      schooName: null,
      id: '',
      schoolName: '',
      page: 1,
      detailData: '',
      url: '',
      imagesList: {
        per_page: 5,
        current_page: 1,
        data: []
      },
      roundsList: [],
      videoData: '',
      dialogVisible: false,
      video_name: '',
      video_desc: '',
      video_url:'',
      Collegedetails: null,
      filters: {}, // 存储当前筛选条件
      jian: "detailsData",
      // 年限筛选项
      yearFilters: [
        {text: new Date().getFullYear() - 5, value: new Date().getFullYear() - 5, checked: false},
        {text: new Date().getFullYear() - 4, value: new Date().getFullYear() - 4, checked: false},
        {text: new Date().getFullYear() - 3, value: new Date().getFullYear() - 3, checked: false},
        {text: new Date().getFullYear() - 2, value: new Date().getFullYear() - 2, checked: false},
        {text: new Date().getFullYear() - 1, value: new Date().getFullYear() - 1, checked: false},
        {text: '全部', value: 'all'},
      ],
      studentbrochure: {
        per_page: 5,
        current_page: 1,
        data: []
      },
      schoolenames: null,
      imagesarrylis:null,
      admissionslist:{
        per_page: 5,
        current_page: 1,
        data: []
      },
      areaName:null,
      dialogVisibleImagefalg:false,
      currentImage: null,
      showVideoPlayer: false,
      currentVideo: {},
      Provideo:{
        data:[]
      },
      currentVideo1: {},
      videoVisible:false,
      showVideoPlayer1: false,
      currentVideo2:{},
    }
  },
  computed: {

  },
  methods: {
    hrefsor(){
      this.$router.push("/Selectgaode");
    },
    handleSizeChanges2(val) {
      this.admissionslist.per_page=val;
      this.getbyadmissionslist();
    },
    handleCurrentChanges2(val) {
      this.admissionslist.current_page=val;
      this.getbyadmissionslist();
    },
    handleSizeChanges1(val) {
      this.studentbrochure.per_page=val;
      this.getbyStudentbrochure();
    },
    handleCurrentChanges1(val) {
      this.studentbrochure.current_page=val;
      this.getbyStudentbrochure();
    },
    handleSizeChanges(val) {
      this.imagesList.per_page=val;
      this.getbytc();
    },
    handleCurrentChanges(val) {
      this.imagesList.current_page=val;
      this.getbytc();
    },
    xianshi(){
      setTimeout(() => {
        this.showsignup=true;
      }, 1000);
    },
    handleOutsideClick(event) {
      if (this.showsignup==true){
        // 判断点击事件是否发生在弹窗组件之外
        if (!this.$refs.signup.$el.contains(event.target)) {
          this.handleClosePopup();
        }
      }
    },
    handleClosePopup() {
      this.$confirm('此操作将关闭弹窗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '关闭!'
        });
        this.showsignup=false;
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleFilterChange(filters) {
      this.filters = JSON.stringify(filters);
      const openBracketIndex =  this.filters.indexOf('[');
      const result = this.filters.slice(openBracketIndex + 1, openBracketIndex + 5);
      this.sunInit(result);
    },
    //切换分页
    handleCurrentChange(val) {
      let that = this;
      that.page = val;
      that.getDetail();
    },
    formatDate(row, column, cellValue) {
      return cellValue.split('-')[0];
    },
    // 点击地图后得到位置信息
    updateLocation(Addr) {
      console.log(Addr)
    },
    getbytc(){
      Api.Teacher.getBySchoolID({
        schoolID:this.schoolenames,
        per_page:this.imagesList.per_page,
        current_page:this.imagesList.current_page
      }).then(res=>{
        this.imagesList = res.data;
      })
    },
    getbyStudentbrochure(){
      Api.Studentbrochure.getByschoolid({
        schoolid:this.detailData.studentbrochure,
      }).then(res=>{
        this.studentbrochure=res.data.data;
        console.info(this.studentbrochure);
      })
    },
    //数据填充
    getbyadmissionslist(){
      Api.Article.getbyname({
        source:this.schoolenames,
        current_page:this.admissionslist.current_page,
        per_page:this.admissionslist.per_page,
      }).then(res=>{
        this.admissionslist=res.data.data;
      })
    },
    getDetail(){
      let that = this;
      that.id = this.$route.query.id;
      Api.School.GetSchoolDetail({
        id: that.id,
      }).then((res) => {
        that.schoolenames=res.data.data.detail.name;
        that.imagesarrylis=res.data.data.detail.round_images.split(',').map(url => 'https://admin.zhongguojiaoyunet.com' + url);
        Api.Enrollmentpromotion.init({
          schoolID:that.schoolenames,
        }).then(resEnrollmentpromotion=>{
          that.Enrollmentpromotion=resEnrollmentpromotion.data.data.data;
        })
        this.getbytc();
        if (res.data.code == 1) {
          that.detailData = res.data.data.detail;
          that.getbyStudentbrochure();
          Api.Area.getByidarea({
            areaID: that.detailData.area_id,
          }).then((ressss) => {
            that.areaName = ressss.data.data[0].label;
          })
          that.schooName=res.data.data.detail.round;
          that.updateChildData();
          that.roundsList = res.data.data.detail.roundsList;
          Api.School.getByvid({
            name: that.schoolenames,
          }).then((res) => {
            that.videoData = res.data.data.videoData.data;
          })
          that.getbyadmissionslist();
          Api.Collegedetails.getAll({
            edu_areaID: res.data.data.detail.area_id,
            edu_schoolID:that.schoolenames
          }).then((res) => {
            that.Collegedetails=res.data.data;
            for (let i = 0; i < that.Collegedetails.data.length; i++) {
              Api.Provideo.getAll({
                collegedetailsID: that.Collegedetails.data[i].ID
              }).then((res) => {
                if (null!=res.data){
                  if (undefined!=res.data.data){
                    let datas=res.data.data;
                    for (let i = 0; i <datas.length; i++) {
                      that.Provideo.data.push(datas[i])
                    }
                  }
                }
              })
            }
            this.mybRRR=true;
          })
        }
      })
    },
    viewBrochure(s) {
      window.open(s.contenturl, '_blank');
    },
    updateChildData() {
      this.$store.dispatch('updateSchooName',this.schooName);
    },
    sunInit(data){
      Api.Collegedetails.getAll({
        edu_areaID: this.detailData.area_id,
        edu_schoolID:this.detailData.id,
        edu_College_detailsData:data,
      }).then((res) => {
        this.Collegedetails=res.data.data;
        for (let i = 0; i < this.Collegedetails.data.length; i++) {
          Api.Provideo.getAll({
            collegedetailsID: this.Collegedetails.data[i].ID
          }).then((res) => {
            if (null!=res.data){
              if (undefined!=res.data.data){
                this.Provideo.data[i]=res.data.data;
                this.mybRRR=true;
              }
            }
          })
        }
      })
    },
    //播放视频
    replay(item){
      this.dialogVisible = true;
      this.video_name = item.name;
      this.video_desc = item.desc;
      this.video_url  = item.url;
    },
    replay1(item){
      this.dialogVisible = true;
      this.video_name = item.file_title;
      this.video_desc = "";
      this.video_url  =item.file_path;
    },
    replay2(item){
      this.dialogVisible = true;
      this.video_name = item.name;
      this.video_desc = item.title;
      this.video_url  = "http://oss.zhongguojiaoyunet.com/"+item.file_path;
    },
    onScoreRangeChange(scores) {
      // 当分数线区间发生变化时触发的事件
      this.scoreRange = scores;
      // 在此处可以进一步处理分数区间变化，如发送请求获取对应区间内的数据等
    },
    handleClose(done) {
      this.$confirm('确认关闭预览吗?')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    showPreview() {
      this.dialogVisible = true;
    },
    onPlayerPlay() {
      console.log('开始播放');
    },
    onPlayerPause() {
      console.log('暂停播放');
    },
    openDialog(index) {
      this.currentImage = this.detailData.imagesList[index];
      this.dialogVisibleImagefalg = true;
    },
    handlePageChange(page){
      this.imagesList.current_page=page;
      this.getbytc();
    },
    openVideo(index) {
      this.currentVideo = this.videoData[index];
      this.showVideoPlayer = true;
    },
    closeVideo() {
      this.showVideoPlayer = false;
    },
    handleSizeChange1(val) {
      this.pageSize = val;
      // 这里可以调用获取数据的方法，根据新的 pageSize 加载数据
    },
    handleCurrentChange1(val) {
      this.currentPage = val;
      // 这里可以调用获取数据的方法，根据新的 currentPage 加载数据
    },
    openVideo1(item) {
      this.currentVideo1= item;
      this.videoVisible = true;
    },
    openVideo2(item) {
      this.currentVideo2= item;
      console.info(this.currentVideo2);
      this.showVideoPlayer1 = true;
    },
    handleOnlineSignup(){
      setTimeout(() => {
        this.showsignup=true;
      }, 1000);
    },
    handleOfflineSignup(){

    },
  },
  created() {
    this.getDetail();

  },
  mounted() {
  },
  watch: {
    '$route' () {
      this.getDataListInit();
    },
  }
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  min-height: calc(100vh - 300px);
}
//公共页面固定展示
.fixed-home{
  width: 1188px;
  margin: 0 auto;
  clear: both!important;
  position: relative;
}
//院校logo展示区域
.logo {
  text-align: left;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.school-name {
  text-align: right;
  font-size: 24px;
  font-weight: bold;
}

.buttons {
  text-align: center;
  margin-top: 20px;
}

.address {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}
//院校介绍
.el-header {
  text-align: center;
  color: #333;
}

.el-main {
  text-align: left;
  color: #333;
}

.el-image {
  margin-bottom: 10px;
}
//师资力量
.teacher-card {
  margin-bottom: 20px;
}
.teacher-avatar {
  object-fit: cover;
  margin-left: 10px; /* 调整头像与图标的间距 */
  vertical-align: middle; /* 垂直对齐 */
}
.teacher-info p {
  margin: 0;
}

.teacher-name {
  margin-top: 10px;
}

.teacher-address {
  margin-top: 10px;
  color: #999;
}
.teacher-info .experience-wrapper {
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: height 0.3s ease;
}
//学院风采
.college-feature-header {
  margin-bottom: 20px;
}
.video-player {
  display: none; /* 默认隐藏视频播放器 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
.video-player video {
  width: 80%;
  height: auto;
  margin: 50px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  cursor: pointer;
}
//开设专业
.custom-table {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.custom-table th {
  background-color: #f5f7fa;
  color: #909399;
  font-weight: bold;
}

.custom-table td {
  color: #606266;
}

.custom-table .el-table__row--striped td {
  background-color: #f2f6fc;
}

.custom-table .el-table__row:hover td {
  background-color: #f0f9eb;
}

.custom-table .el-table__header-wrapper th {
  padding: 12px 0;
}

.custom-table .el-table__body-wrapper td {
  padding: 10px 0;
}

.custom-table .el-table__footer-wrapper td {
  padding: 12px 0;
}
//招生宣传,院校图片
.recruitment h2,
.college-image-section h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.video-card {
  margin-bottom: 10px;
}

.video-thumbnail {
  width: 100%;
  height: 200px;
}

.college-image {
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
}

.introduction {
  font-size: 14px;
}

.college-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.college-profile h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.college-profile p {
  margin-bottom: 10px;
}
//招生简章
.recruitment-brochure {
  text-align: center;
}

.brochure-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.brochure-card {
  width: 300px;
  margin: 10px;
}

.brochure-content {
  padding: 10px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
//报名框
.popup-container {
  overflow-y: auto; /* 自动显示垂直滚动条 */
  max-height: 500px; /* 设置一个最大高度，可以根据实际需求调整 */
  width: 80%; /* 或其他适合的宽度 */
  box-sizing: border-box;

}

/* （可选）自定义滚动条样式 */
.popup-container::-webkit-scrollbar {
  width: 10px; /* 滚动条宽度 */
}

.popup-container::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 6px; /* 滚动条滑块圆角 */
}


</style>

